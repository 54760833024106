import React, { useContext, useEffect, useRef, useState } from 'react';
import { SettingsContext } from '../../../../context/settings-context';
import { MyThemeContext } from '../../../../context/theme-context';

import { green, red } from '@mui/material/colors';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import FormatedNumber from '../../../Common/Formater/FormatedNumber';
import FormatedNumberDigit from '../../../Common/Formater/FormatedNumberDigit';
import FormatedPercentage from '../../../Common/Formater/FormatedPercentage';
import FormatedPrice from '../../../Common/Formater/FormatedPrice';
import TokenInfos from '../../../Common/TokenInfos';
import TokenNews from '../../../Common/TokenNews';

function TokensTableBody({ token, chains, owned }) {
  const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [openAmount, setOpenAmount] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [openNews, setOpenNews] = useState(false);
  const anchorRefAmount = useRef(null);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  const handleTokenClick = (marketplaceLink) => {
    window.open(marketplaceLink, '_blank');
  };
  const handleMouseEnterAmount = () => {
    const id = setTimeout(() => {
      setOpenAmount(true);
    }, 1000);
    setTimerId(id);
  };
  const handleMouseLeaveAmount = () => {
    if (timerId) {
      clearTimeout(timerId);
      setTimerId(null);
    }
    setOpenAmount(false);
  };
  useEffect(() => {
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  if (!token) return <></>;
  const tokenPool = token.chain.filter((chain) => (chain.id === 'pool' ? { chain: chain, pool: token.pool } : false));
  // if (tokenPool) console.log("tokenPool", tokenPool);
  // console.log("TokensTableBody", token.chain);
  let annualYield = null;
  let rentedUnits = null;
  let tokenPrice = null;
  // let annualYield = null;
  if (Array.isArray(token.update7)) {
    // console.log("7", token.shortName, token.update7);
    token.update7.forEach((t) => {
      if (t.key === 'annualPercentageYield') {
        annualYield = t;
      }
      if (t.key === 'rentedUnits') {
        rentedUnits = t;
      }
      // console.log("7", token.shortName, t);
    });
  } else {
    console.warn('token.update7 is not an array');
  }

  if (!annualYield)
    if (Array.isArray(token.update30)) {
      token.update30.forEach((t) => {
        if (t.key === 'annualPercentageYield') {
          annualYield = t;
        }
        if (t.key === 'rentedUnits') {
          rentedUnits = t;
        }
        // console.log("30", token.shortName, t);
      });
    } else {
      console.warn('token.update30 is not an array');
    }

  if (!tokenPrice)
    if (Array.isArray(token.update30)) {
      token.update30.forEach((t) => {
        if (t.key === 'tokenPrice') {
          tokenPrice = t;
        }
        if (t.key === 'rentedUnits') {
          rentedUnits = t;
        }
        // console.log("30", token.shortName, t);
      });
    } else {
      console.warn('token.update30 is not an array');
    }

  if (tokenPrice) {
    console.log('tokenPrice:', tokenPrice);
    // console.log("new_value:", annualYield.new_value, "parsed:", parseFloat(annualYield.new_value));
    // console.log("old_value:", annualYield.old_value, "parsed:", parseFloat(annualYield.old_value));
  }

  return (
    <>
      {openInfo && <TokenInfos open={openInfo} setOpen={setOpenInfo} token={token} />}
      {openNews && <TokenNews open={openNews} setOpen={setOpenNews} token={token} chains={chains} />}
      <TableRow key={token.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        {/* {openAmount && ( */}
        <Popper open={openAmount} anchorEl={anchorRefAmount.current} placement='top'>
          <Box sx={{ ml: 0, p: 0, border: 3, borderRadius: 1, bgcolor: 'white' }}>
            <Paper elevation={23} sx={{ mt: 0, width: 1, overflow: 'hidden' }}>
              <TableContainer sx={{ mt: 0 }}>
                <Table sx={{ width: 1, background: 'background.paper' }} aria-label='sticky table'>
                  <TableHead>
                    <TableRow key={token.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
                        Wallet
                      </TableCell>
                      <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
                        Chain
                      </TableCell>
                      <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
                        Owned
                      </TableCell>
                      <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
                        Yield
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ p: 0 }}>
                    {token.chain.map((chain) => (
                      <TableRow key={chain.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
                          <Typography variant='body2'>0x...{chain.wallet.slice(-4)}</Typography>
                        </TableCell>
                        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
                          <Typography variant='body2'>{chain.id}</Typography>
                        </TableCell>
                        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
                          <Typography variant='body2'>{chain.amount}</Typography>
                        </TableCell>
                        {chain.id === 'pool' && (
                          <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
                            <FormatedPercentage
                              value={
                                (chain.amount *
                                  (1 + token.pool.realtRatio / token.pool.holderRatio) *
                                  token.annualPercentageYield *
                                  token.tokenPrice) /
                                (2 * chain.amount * token.tokenPrice * 100)
                              }
                              color={
                                (chain.amount *
                                  (1 + token.pool.realtRatio / token.pool.holderRatio) *
                                  token.annualPercentageYield *
                                  token.tokenPrice) /
                                  (2 * chain.amount * token.tokenPrice * 100) >
                                token.annualPercentageYield / 100
                                  ? green[500]
                                  : red[700]
                              }
                              fontWeight='bold'
                            />
                          </TableCell>
                        )}
                        {chain.id !== 'pool' && (
                          <TableCell align='left' sx={{ pl: 1, pr: 1, py: 1 }}>
                            <FormatedPercentage value={token.annualPercentageYield / 100} color={'secondary.main'} fontWeight='bold' />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Popper>

        <TableCell
          align='center'
          sx={{ pl: 0, pr: 0, py: 0.5, '&:hover': { cursor: 'pointer' } }}
          component='th'
          scope='row'
          onClick={() => handleTokenClick(token.marketplaceLink)}
          // onClick={() => setOpenInfo(true)}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <OpenInNewIcon color={'primary'} />
          </Box>
        </TableCell>

        <TableCell
          align='center'
          sx={{ pl: 0, pr: 0, py: 0, '&:hover': { cursor: 'pointer' } }}
          component='th'
          scope='row'
          // onClick={() => handleTokenClick(token.marketplaceLink)}
          onClick={() => setOpenInfo(true)}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <AddchartOutlinedIcon color={'primary'} />
          </Box>
        </TableCell>

        <TableCell
          align='left'
          sx={{ pl: 0, pr: 0, py: 0, '&:hover': { cursor: token.update7.length > 0 || token.update30.length > 0 ? 'pointer' : 'cursor' } }}
          component='th'
          scope='row'
          onClick={() => setOpenNews(true)}
        >
          {token.update7.length > 0 && (
            <Tooltip title={'realTokens updated since 7 days'}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <AnnouncementIcon color='primary' />
              </Box>
            </Tooltip>
          )}
          {!token.update7.length > 0 && token.update30.length > 0 && (
            <Tooltip title={'realTokens updated since 30 days'}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <AnnouncementIcon color='secondary' />
              </Box>
            </Tooltip>
          )}
        </TableCell>

        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1 }}>
          <Tooltip title={token.fullName}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant='body2'>{token.shortName}</Typography>
            </Box>
          </Tooltip>
        </TableCell>

        {owned && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <Box ref={anchorRefAmount} onMouseEnter={handleMouseEnterAmount} onMouseLeave={handleMouseLeaveAmount}>
              <FormatedNumber value={token.amount} color={'primary.main'} />
            </Box>
          </TableCell>
        )}

        <Tooltip title={`Owned value: ${(token.amount * token.tokenPrice).toFixed(2)} $`}>
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}>
              {tokenPrice && (
                <Box sx={{ ml: 0 }}>
                  {parseFloat(tokenPrice.new_value) > parseFloat(tokenPrice.old_value) ? (
                    <FormatedPercentage value={tokenPrice.new_value / tokenPrice.old_value - 1} color={green[500]} />
                  ) : (
                    <FormatedPercentage value={tokenPrice.new_value / tokenPrice.old_value - 1} color={red[500]} />
                  )}
                </Box>
              )}
              {tokenPrice && (
                <Tooltip title={`from ${parseFloat(tokenPrice.old_value).toFixed(2)}$ to ${parseFloat(tokenPrice.new_value).toFixed(2)}$`}>
                  {parseFloat(tokenPrice.new_value) > parseFloat(tokenPrice.old_value) ? (
                    <ArrowUpwardIcon sx={{ color: green[500] }} />
                  ) : (
                    <ArrowDownwardIcon sx={{ color: red[500] }} />
                  )}
                </Tooltip>
              )}
              <FormatedPrice value={token.tokenPrice} color={'primary.main'} />
            </Box>
          </TableCell>
        </Tooltip>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}>
            {annualYield && (
              <Tooltip title={`from ${parseFloat(annualYield.old_value).toFixed(2)}% to ${parseFloat(annualYield.new_value).toFixed(2)}%`}>
                {parseFloat(annualYield.new_value) > parseFloat(annualYield.old_value) ? (
                  <ArrowUpwardIcon sx={{ color: green[500] }} />
                ) : (
                  <ArrowDownwardIcon sx={{ color: red[500] }} />
                )}
              </Tooltip>
            )}
            <FormatedPercentage value={token.annualPercentageYield / 100} color={'secondary.main'} />
          </Box>
        </TableCell>

        {chains.update30 && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', alignItems: 'center' }}>
              {annualYield ? <FormatedPercentage value={Number(annualYield.old_value) / 100} color={'secondary.main'} digit={2} /> : '-'}
            </Box>
          </TableCell>
        )}

        {owned && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <FormatedPrice value={token.rentReceive} color={'primary.main'} />
          </TableCell>
        )}

        {!isMatch && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <Typography variant='body2'>{DEFAULT_PROPERTY_TYPE[token.propertyType].label}</Typography>
          </TableCell>
        )}

        {!isMatch && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <FormatedNumber value={token.totalTokens} color={'primary.main'} digit={0} />
          </TableCell>
        )}

        {!isMatch && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
            <FormatedPrice value={token.totalInvestment} color={'primary.main'} />
          </TableCell>
        )}

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'right', color: 'primary.main' }}>
            {rentedUnits && (
              <Tooltip
                title={`from ${parseFloat(rentedUnits.old_value).toFixed(0)} to ${parseFloat(rentedUnits.new_value).toFixed(0)} unit(s) rented`}
              >
                {parseFloat(rentedUnits.new_value) > parseFloat(rentedUnits.old_value) ? (
                  <ArrowUpwardIcon sx={{ color: green[500] }} />
                ) : (
                  <ArrowDownwardIcon sx={{ color: red[500] }} />
                )}
              </Tooltip>
            )}
            <FormatedNumberDigit value={token.rentedUnits} color={'primary.main'} digit={0} />/
            <FormatedNumberDigit value={token.totalUnits} color={'primary.main'} digit={0} />
          </Box>
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1 }}>
          <FormatedPercentage value={token.rentedUnits / token.totalUnits} color={'secondary.main'} />
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1 }}>
          <Typography variant='body2'>{token.strRentStartDate}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

export default TokensTableBody;
