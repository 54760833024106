import React from 'react';
import { Typography } from '@mui/material';

const FormatedPercentage = ({ value, color = 'inherit', variant = 'body2', fontWeight = 'normal' }) => {
  const userLocale = navigator.language; // const userLocales = navigator.languages;
  // console.log("userLocale, Percentage", userLocale, value); // console.log(userLocales[0]);

  const formatedPercentage =
    value !== null
      ? !isNaN(value)
        ? value.toLocaleString(userLocale, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
          })
        : 'NaN'
      : 'N/A';

  return (
    <Typography variant={variant} color={color} style={{ fontWeight: fontWeight }}>
      {formatedPercentage}
    </Typography>
  );
};

export default FormatedPercentage;
