import React, { useContext, useState, useEffect, useRef } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { Box, Typography, Paper, CircularProgress, TextField, Tooltip } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import { green, red } from '@mui/material/colors';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import FormatedPrice from '../../Common/Formater/FormatedPrice';

function WidgetRentCollected() {
	const {
		settingsWallet,
		settingsDashboard, setSettingsDashboard,
		realTokens,
		getNextRonday,
		getLastRondayNow,
		holderWallets,
		balanceWallets,
		rentsWallets,
		setRentsWallets,
	} = useContext(SettingsContext);
	const [rents, setRents] = useState(null);

	// Constante pour la durée de validité du cache (6h)
	const CACHE_DURATION = 6 * 60 * 60 * 1000;


	// Fonction pour vérifier si les données en cache sont valides
	const isCacheValid = (walletAddress) => {
		if (!rentsWallets || !rentsWallets[walletAddress]) return false;

		const cachedData = rentsWallets[walletAddress];
		const now = Date.now();
		return cachedData.timestamp && (now - cachedData.timestamp) < CACHE_DURATION;
	};

	const get_APIURL_RENT_HOLDER = async () => {
		let rentsAllWallets = [];
		let newRentsWallets = { ...rentsWallets }; // Copie du cache existant

		// D'abord, ajouter toutes les données en cache à rentsAllWallets
		holderWallets
			.filter(holderWallet => holderWallet.checked)
			.forEach(holderWallet => {
				if (rentsWallets[holderWallet.address] && isCacheValid(holderWallet.address)) {
					rentsWallets[holderWallet.address].cumulativeRents.forEach(entry => {
						const existingEntry = rentsAllWallets.find(a => a.date === entry.date);
						if (existingEntry) {
							existingEntry.rent = parseFloat((existingEntry.rent + entry.rent).toFixed(2));
						} else {
							rentsAllWallets.push({
								date: entry.date,
								rent: entry.rent
							});
						}
					});
				}
			});

		// Ensuite, mettre à jour les wallets qui en ont besoin
		const walletsToUpdate = holderWallets
			.filter(holderWallet => holderWallet.checked)
			.filter(holderWallet => !isCacheValid(holderWallet.address));

		if (walletsToUpdate.length > 0) {
			for (const holderWallet of walletsToUpdate) {
				console.log(`Mise à jour des rents pour le wallet ${holderWallet.address}`);
				const APIURL_WHITELIST = "https://ehpst.duckdns.org/realt_rent_tracker/api/rent_holder/" + holderWallet.address;

				try {
					const response = await fetch(APIURL_WHITELIST, {
						method: 'GET',
						headers: {
							'Accept': 'application/json',
						},
						mode: 'cors',
						cache: 'no-cache',
					});

					if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
					const body = await response.json();

					// Calcul du cumul pour ce wallet
					let cumulative = 0;
					const cumulativeRents = body.map(entry => {
						cumulative += entry.rent;
						return {
							date: entry.date,
							rent: entry.rent,
							cumulativeRent: parseFloat(cumulative.toFixed(2))
						};
					});

					// Mise à jour du cache pour ce wallet
					newRentsWallets[holderWallet.address] = {
						timestamp: Date.now(),
						cumulativeRents: cumulativeRents
					};

					// Ajout à la liste globale
					body.forEach((entry) => {
						const existingEntry = rentsAllWallets.find(a => a.date === entry.date);
						if (existingEntry) {
							existingEntry.rent = parseFloat((existingEntry.rent + entry.rent).toFixed(2));
						} else {
							rentsAllWallets.push({
								date: entry.date,
								rent: entry.rent
							});
						}
					});

				} catch (error) {
					console.log(`Erreur lors de la mise à jour des rents pour le wallet ${holderWallet.address}:`, error);
					// Utiliser les données en cache si disponibles
					if (rentsWallets[holderWallet.address]) {
						console.log(`Utilisation du cache pour le wallet ${holderWallet.address}`);
						newRentsWallets[holderWallet.address] = rentsWallets[holderWallet.address];

						// Ajouter les données en cache à rentsAllWallets
						rentsWallets[holderWallet.address].cumulativeRents.forEach(entry => {
							const existingEntry = rentsAllWallets.find(a => a.date === entry.date);
							if (existingEntry) {
								existingEntry.rent = parseFloat((existingEntry.rent + entry.rent).toFixed(2));
							} else {
								rentsAllWallets.push({
									date: entry.date,
									rent: entry.rent
								});
							}
						});
					}
				}
			}
		}

		try {
			// Ne mettre à jour que si nous avons des données
			if (rentsAllWallets.length > 0) {
				// Trier et calculer le cumul global
				rentsAllWallets.sort((a, b) => new Date(a.date) - new Date(b.date));
				let cumulativeGlobal = 0;
				const result = rentsAllWallets.map((entry) => {
					cumulativeGlobal += entry.rent;
					return {
						...entry,
						cumulativeRent: parseFloat(cumulativeGlobal.toFixed(2)),
					};
				});

				// Mise à jour des states
				setRentsWallets(newRentsWallets);
				setRents(result);
			}
		} catch (error) {
			console.log('Erreur lors du calcul des cumuls globaux:', error);
		}
	};

	// Modification du useEffect pour utiliser la nouvelle logique de cache

	const TIMEOUT_REFRESH = 30 * 60 * 1000;        // 30 minutes
	const TIMEOUT_REFRESH_FAST = 60 * 1000;       //  60 secondes
	const [state, setState] = useState({ num: 0 });
	const counter = useRef(1);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (holderWallets) {
					// Vérifier si un wallet est coché/décoché ou si les données n'existent pas
					const checkedWallets = holderWallets.filter(w => w.checked);
					const needsUpdate = checkedWallets.some(wallet => !isCacheValid(wallet.address)) ||
						!rents ||
						// Comparer avec les données actuelles pour détecter un changement de sélection
						(rentsWallets && Object.keys(rentsWallets).length !== checkedWallets.length);

					if (needsUpdate) {
						await get_APIURL_RENT_HOLDER();
					}
				}
			} catch (error) {
				console.error('Erreur lors de la récupération des rents:', error);
			}
		};

		fetchData();

		const timer = setTimeout(
			() => setState({ num: counter.current + 1 }),
			rents ? TIMEOUT_REFRESH : TIMEOUT_REFRESH_FAST
		);
		return () => clearTimeout(timer);
	}, [
		holderWallets,
		rents,
		rentsWallets,
		// Ajouter une dépendance qui change quand les wallets cochés changent
		holderWallets?.filter(w => w.checked).map(w => w.address).join(',')
	]);

	// -------------------------------------------------------

	useEffect(() => {
		if (settingsDashboard) {
			if (!settingsDashboard.weekInvest) setSettingsDashboardChange("weekInvest", 50);
			if (!settingsDashboard.reInvestRatio) setSettingsDashboardChange("reInvestRatio", 1);
			if (!settingsDashboard.nbMonth) setSettingsDashboardChange("nbMonth", 120);
			if (settingsDashboard.YieldProgress === null) setSettingsDashboardChange("YieldProgress", 3);
		}
	}, []);

	const setSettingsDashboardChange = (key, newValue) => {
		// console.log("setSettingsStatsChange:", key, newValue);
		setSettingsDashboard((prevSettings) => ({
			...prevSettings,
			[key]: newValue,
		}));
	};

	// Condition pour vérifier si les données sont disponibles

	if (!settingsDashboard.isExpandedInsight) return <></>;
	if (!realTokens) return <></>;
	if (!holderWallets || holderWallets.length === 0) return <></>;
	if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
	if (settingsDashboard.selectedRentType === null) return <></>;
	const selectedRentType = settingsDashboard.selectedRentType;
	let tokens = [
		...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
	];

	let nbSelectedWallets = holderWallets.filter((w) => w.checked).length;
	if (nbSelectedWallets === 0) return <></>;
	// if (!rents) return <></>;

	// Generation des interets composé pour les 10 prochaine années
	let tokensBalance = [
		{
			label: 'Ethereum',
			chain: 'eth',
			count: 0,
			rent: 0,
			total: 0,
			nbToken: 0,
			yield: 0,
			totalBuy: 0,
			rentBuy: 0,
			nbTokenBuy: 0,
			yieldBuy: 0,
			tokens: []
		},
		{
			label: 'Gnosis',
			chain: 'xdai',
			count: 0,
			rent: 0,
			total: 0,
			nbToken: 0,
			yield: 0,
			totalBuy: 0,
			rentBuy: 0,
			nbTokenBuy: 0,
			yieldBuy: 0,
			tokens: []
		},
		{
			label: 'RMM',
			chain: 'rmm',
			count: 0,
			rent: 0,
			total: 0,
			yield: 0,
			nbToken: 0,
			totalBuy: 0,
			rentBuy: 0,
			nbTokenBuy: 0,
			yieldBuy: 0,
			tokens: []
		},
		{
			label: 'RMM v3',
			chain: 'rmmv3',
			count: 0,
			rent: 0,
			total: 0,
			yield: 0,
			nbToken: 0,
			totalBuy: 0,
			rentBuy: 0,
			nbTokenBuy: 0,
			yieldBuy: 0,
			tokens: []
		},
		{
			label: 'Levinswap',
			chain: 'pool',
			count: 0,
			rent: 0,
			total: 0,
			nbToken: 0,
			yield: 0,
			totalBuy: 0,
			rentBuy: 0,
			nbTokenBuy: 0,
			yieldBuy: 0,
			tokens: []
		},
		{
			label: 'Total',
			chain: 'total',
			count: 0,
			rent: 0,
			total: 0,
			nbToken: 0,
			yield: 0,
			totalBuy: 0,
			rentBuy: 0,
			nbTokenBuy: 0,
			yieldBuy: 0,
			tokens: []
		},
	];

	if (balanceWallets) {
		tokensBalance.forEach((tokenBalance) => {
			holderWallets
				.filter((holderWallet) => holderWallet.checked)
				.forEach((holderWallet) => {
					for (const wallet of Object.keys(balanceWallets)) {
						if (holderWallet.address === wallet) {
							for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
								if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
									if (chain === 'eth') {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
												if (realToken)
													if (
														Date.now() > getNextRonday(realToken).getTime() &&
														(realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
													) {
														if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === 'Week')
															tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === 'Month')
															tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.nbToken += parseFloat(token.amount);
														tokenBalance.yield = parseFloat(tokenBalance.total ? tokenBalance.rent / tokenBalance.total : 0);
													} else {
														if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === 'Week') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === 'Month') tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rentBuy += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.totalBuy += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.nbTokenBuy += parseFloat(token.amount);
														tokenBalance.yieldBuy = parseFloat(tokenBalance.totalBuy ? tokenBalance.rentBuy / tokenBalance.totalBuy : 0);
													}
											});
									}
								}

								if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
									if (chain === 'xdai') {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
												if (realToken)
													if (
														Date.now() > getNextRonday(realToken).getTime() &&
														(realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
													) {
														if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === 'Week')
															tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === 'Month')
															tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.nbToken += parseFloat(token.amount);
														tokenBalance.yield = parseFloat(tokenBalance.total ? tokenBalance.rent / tokenBalance.total : 0);
													} else {
														if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
														if (selectedRentType === 'Week') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
														if (selectedRentType === 'Month') tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
														if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.rentBuy += parseFloat(token.amount * realToken.netRentYearPerToken);
														tokenBalance.totalBuy += parseFloat(token.amount * realToken.tokenPrice);
														tokenBalance.nbTokenBuy += parseFloat(token.amount);
														tokenBalance.yieldBuy = parseFloat(tokenBalance.totalBuy ? tokenBalance.rentBuy / tokenBalance.totalBuy : 0);
													}
											});
									}
								}

								if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
									if (chain === 'rmm') {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
												if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
													const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
													if (realToken)
														if (
															Date.now() > getNextRonday(realToken).getTime() &&
															(realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
														) {
															if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
															if (selectedRentType === 'Week')
																tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
															if (selectedRentType === 'Month')
																tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
															if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
															tokenBalance.nbToken += parseFloat(rmmBalance);
															tokenBalance.yield = parseFloat(tokenBalance.total ? tokenBalance.rent / tokenBalance.total : 0);
														} else {
															if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
															if (selectedRentType === 'Week') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
															if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
															if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.rentBuy += parseFloat(rmmBalance * realToken.netRentYearPerToken);
															tokenBalance.totalBuy += parseFloat(rmmBalance * realToken.tokenPrice);
															tokenBalance.nbTokenBuy += parseFloat(rmmBalance);
															tokenBalance.yieldBuy = parseFloat(tokenBalance.totalBuy ? tokenBalance.rentBuy / tokenBalance.totalBuy : 0);
														}
												}
											});
									}
								}

								if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
									if (chain === 'rmmv3') {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
												const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
												if (realToken)
													if (
														Date.now() > getNextRonday(realToken).getTime() &&
														(realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
													) {
														if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
														if (selectedRentType === 'Week')
															tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
														if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
														if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
														tokenBalance.nbToken += parseFloat(rmmBalance);
														tokenBalance.yield = parseFloat(tokenBalance.total ? tokenBalance.rent / tokenBalance.total : 0);
													} else {
														if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
														if (selectedRentType === 'Week') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
														if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
														if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.rentBuy += parseFloat(rmmBalance * realToken.netRentYearPerToken);
														tokenBalance.totalBuy += parseFloat(rmmBalance * realToken.tokenPrice);
														tokenBalance.nbTokenBuy += parseFloat(rmmBalance);
														tokenBalance.yieldBuy = parseFloat(tokenBalance.totalBuy ? tokenBalance.rentBuy / tokenBalance.totalBuy : 0);
													}
											});
									}
								}

								if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
									if (chain === 'pool') {
										const tokensList = balanceWallets[wallet].tokens[chain].tokens;
										if (tokensList)
											tokensList.forEach((token) => {
												const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
												const totalSupply = parseFloat(token.pair.totalSupply);
												if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
													if (realToken) {
														if (realToken.pool.coinId) {
															const poolBalance =
																token.pair.token0.liquidity > 1000
																	? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
																	: (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = realtNbToken * (poolBalance / holderNbToken);
															if (
																Date.now() > getNextRonday(realToken).getTime() &&
																(realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
															) {
																if (selectedRentType === 'Day')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
																if (selectedRentType === 'Week')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
																if (selectedRentType === 'Month')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
																if (selectedRentType === 'Year')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
																tokenBalance.nbToken += parseFloat(poolBalance);
																tokenBalance.yield = parseFloat(tokenBalance.total ? tokenBalance.rent / tokenBalance.total : 0);
															} else {
																if (selectedRentType === 'Day')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
																if (selectedRentType === 'Week')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
																if (selectedRentType === 'Month')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
																if (selectedRentType === 'Year')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.rentBuy += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.totalBuy += parseFloat(poolBalance * realToken.tokenPrice * 2);
																tokenBalance.nbTokenBuy += parseFloat(poolBalance);
																tokenBalance.yieldBuy = parseFloat(tokenBalance.totalBuy ? tokenBalance.rentBuy / tokenBalance.totalBuy : 0);
															}
														}
													}
												}
												if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
													const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
													if (realToken) {
														if (realToken.pool.coinId) {
															const poolBalance =
																token.pair.token1.liquidity > 1000
																	? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
																	: (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
															const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
															const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
															const bonusToken = realtNbToken * (poolBalance / holderNbToken);
															if (
																Date.now() > getNextRonday(realToken).getTime() &&
																(realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
															) {
																if (selectedRentType === 'Day')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
																if (selectedRentType === 'Week')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
																if (selectedRentType === 'Month')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
																if (selectedRentType === 'Year')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
																tokenBalance.nbToken += parseFloat(poolBalance);
																tokenBalance.yield = parseFloat(tokenBalance.total ? tokenBalance.rent / tokenBalance.total : 0);
															} else {
																if (selectedRentType === 'Day')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
																if (selectedRentType === 'Week')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
																if (selectedRentType === 'Month')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
																if (selectedRentType === 'Year')
																	tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.rentBuy += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
																tokenBalance.totalBuy += parseFloat(poolBalance * realToken.tokenPrice * 2);
																tokenBalance.nbTokenBuy += parseFloat(poolBalance);
																tokenBalance.yieldBuy = parseFloat(tokenBalance.totalBuy ? tokenBalance.rentBuy / tokenBalance.totalBuy : 0);
															}
														}
													}
												}
											});
									}
								}
							}
						}
					}
				});
		});
	}
	let lastRonday = getLastRondayNow();
	const endDate = new Date(lastRonday);
	const nbYear = parseInt(settingsDashboard.nbMonth / 12); // Nombre de mois à ajouter, par exemple 12 mois
	const nbMonth = settingsDashboard.nbMonth - nbYear * 12; // Nombre de mois à ajouter, par exemple 12 mois
	endDate.setFullYear(endDate.getFullYear() + nbYear);
	endDate.setMonth(endDate.getMonth() + nbMonth);
	// endDate.setDate( new Date(nextRonday) + settingsDashboard.nbMonth*30);

	// console.log("---------------------------------------");
	// console.log("Date today:", today.toLocaleDateString().slice(0, 10));
	// console.log("Date nextRonday :", lastRonday.toLocaleDateString().slice(0, 10));
	// console.log("Date après ajout de", endDate.getFullYear(), nbYear, nbMonth, "Year/Month :", endDate.toLocaleDateString().slice(0, 10));

	function generateComposedInterest(startDate, endDate) {
		const dateList = [];
		const holderInvest = tokensBalance[5];
		let currentDate = new Date(startDate);
		// console.log("holderInvest", holderInvest);

		// Paramètres
		let ratioRe = settingsDashboard.reInvestRatio;
		let weekInvest = settingsDashboard.weekInvest;

		// Initialisation des capitaux
		let buyingReserve = holderInvest.totalBuy || 0; // Réserve d'achat initiale
		let capital = holderInvest.total + buyingReserve;
		let capitalR = capital;  // Capital initial = total + totalBuy
		let capitalWR = capital;  // Capital initial = total + totalBuy

		// Initialisation des rentes
		let rentsW = holderInvest.count;
		let rentsY = holderInvest.rent;
		let rentsC = 0;

		if (rents) {
			capitalWR -= rents.slice(-1)[0].cumulativeRent * ratioRe;
			rentsW = rents.slice(-1)[0].rent;
			rentsC = rents.slice(-1)[0].cumulativeRent;
			capital -= ratioRe * rentsC;
		}

		let yieldRatio = (365 / 7 * rentsW) / capitalR;
		let yieldCI = rentsY / capitalWR;

		while (currentDate <= endDate) {

			// Si la réserve est épuisée, on utilise l'investissement hebdomadaire normal
			let investmentFromNewMoney = buyingReserve <= 0 ? weekInvest : buyingReserve <= weekInvest ? weekInvest - buyingReserve : 0;

			// Calcul de l'investissement hebdomadaire effectif
			// let effectiveWeekInvest = Math.min(weekInvest, buyingReserve);

			// Mise à jour de la réserve
			buyingReserve = Math.max(0, buyingReserve - weekInvest);

			dateList.push({
				date: new Date(currentDate),
				capitalR: parseFloat(capitalR.toFixed(2)),
				capital: parseFloat(capital.toFixed(2)),
				capitalWR: parseFloat(capitalWR.toFixed(2)),
				rentsC: parseFloat(rentsC.toFixed(2)),
				rentsW: parseFloat(rentsW.toFixed(2)),
				rentsY: parseFloat(rentsY.toFixed(2)),
				yield: parseFloat(yieldRatio.toFixed(4)),
				yieldCI: parseFloat(yieldCI.toFixed(4)),
				yieldRatio: parseFloat(yieldRatio.toFixed(4)),
				buyingReserve: parseFloat(buyingReserve.toFixed(2)),
			});

			// Mise à jour des valeurs pour la semaine suivante
			currentDate.setDate(currentDate.getDate() + 7);

			// Le capital n'augmente que si on utilise de l'argent frais (réserve épuisée)
			capitalR += rentsW * ratioRe + investmentFromNewMoney;
			capital += investmentFromNewMoney;

			yieldRatio = yieldRatio * (1 + ((settingsDashboard.YieldProgress ?? 3) / 100) / (365 / 7))
			rentsC += rentsW;
			rentsY = capitalR * yieldRatio;
			rentsW = (capitalR * yieldRatio) / 52;
			capitalWR += rentsW * ratioRe + investmentFromNewMoney;
		}

		return dateList;
	}

	const composedInterest = generateComposedInterest(lastRonday, endDate);
	// console.log("composedInterest");
	// console.table(composedInterest);

	let configRentCollectedChart = null;
	if (rents) {
		configRentCollectedChart = rents.map((item) => ({
			date: item.date,
			cumulativeRent: parseFloat(item.cumulativeRent),
			rent: parseFloat(item.rent),
		}));
	}

	const configReInvestChart = composedInterest.map((item) => ({
		date: item.date,
		capitalR: parseFloat(item.capitalR),
		capital: parseFloat(item.capital),
		rentsC: parseFloat(item.rentsC),
		rentsW: parseFloat(item.rentsW),
	}));

	const width_size_TextField = 145;
	const size_width = 500;
	const size_height = 325;
	const size_width_reinvest = 1000;

	// Exemple d'utilisation des données par wallet dans le rendu
	const renderWalletData = (walletAddress) => {
		if (!rentsWallets[walletAddress]) return null;

		const walletData = rentsWallets[walletAddress];
		const lastRent = walletData.cumulativeRents[walletData.cumulativeRents.length - 1];

		return (
			<Typography variant="body2">
				Wallet {walletAddress.slice(0, 6)}...{walletAddress.slice(-4)}: {lastRent?.cumulativeRent || 0} USD
			</Typography>
		);
	};

	return (
		<>
			{!rents ? (
				<Paper
					elevation={3}
					sx={{ m: 1, width: size_width, minHeight: size_height, border: 1, borderColor: 'primary.main', borderRadius: 4 }}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
						<Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
							Rents Collected
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: size_height }}>
						<CircularProgress size='4rem' />
						<Typography variant='body2' size='4rem' color='primary.main' sx={{ mt: 2 }}>
							<em>Loading collected Rents</em>
						</Typography>
					</Box>
				</Paper>
			) : (
				<Paper
					elevation={3}
					sx={{ m: 1, width: size_width, minHeight: size_height, border: 1, borderColor: 'primary.main', borderRadius: 4 }}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
						<Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
							Rents Collected
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: size_height }}>
						<ResponsiveContainer width='100%' height={size_height}>
							<LineChart data={configRentCollectedChart} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
								<CartesianGrid strokeDasharray='3 3' />
								<XAxis
									dataKey='date'
									tickFormatter={(value) => {
										const date = new Date(value);
										return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
									}}
									angle={-25}
									textAnchor="end"
									height={50}
									style={{ fontSize: '0.8rem' }}
								/>
								<YAxis yAxisId='left' />
								<YAxis yAxisId='right' orientation='right' />
								<RechartsTooltip
									labelFormatter={(value) => {
										const date = new Date(value);
										return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
									}}
								/>
								<Legend />
								<Line yAxisId='left' type='monotone' dataKey='cumulativeRent' name='Cumulated Rents' stroke='#8884d8' dot={false} />
								<Line yAxisId='right' type='monotone' dataKey='rent' name='Rents' stroke='#82ca9d' dot={false} />
							</LineChart>
						</ResponsiveContainer>
					</Box>
				</Paper>
			)}

			<Paper
				elevation={3}
				sx={{ m: 1, width: size_width_reinvest, minHeight: size_height, border: 1, borderColor: 'primary.main', borderRadius: 4 }}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0, border: 0 }}>
					<Tooltip title={`Capital = Sum of Tokens minus Cumulated Rents * Reinvestment Ratio`}>
						<InfoOutlinedIcon color='primary' />
					</Tooltip>
					<Tooltip
						sx={{ ml: 3 }}
						title={`The Widget Wallet Total value represents your assets, Investments + Token Growth + Reinvested Returns + ${settingsDashboard.YieldProgress ?? 3}% Rents Annual valuation`}
					>
						<InfoOutlinedIcon color='secondary' />
					</Tooltip>
					<Typography variant='h5' sx={{ m: 1, ml: 6, color: 'primary.main' }}>
						Compound Interest
					</Typography>
					<Box
						sx={{
							mx: 1,
							p: 0,
							minWidth: 600,
							border: 0,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<TextField
							sx={{ ml: 'auto', mt: 1, width: width_size_TextField }}
							label='Growth Yield/Year (%)'
							onChange={(e) => {
								let newValue = parseFloat(e.target.value);
								if (newValue > 10) {
									newValue = 10;
								}
								if (newValue < 0) {
									newValue = 0;
								}
								setSettingsDashboardChange('YieldProgress', parseFloat(newValue.toFixed(2)));
							}}
							variant='outlined'
							type='number'
							size='small'
							value={settingsDashboard.YieldProgress ?? 3}
							inputProps={{
								min: 0,
								max: 10,
								step: 0.1,
								style: {
									textAlign: 'center',
								},
							}}
						/>

						<TextField
							sx={{ ml: 1, mt: 1, width: width_size_TextField }}
							label='Investment/Week ($)'
							onChange={(e) => {
								let newValue = e.target.value;
								// Si la valeur saisie dépasse 100, la ramener à 100
								if (newValue > 5000) {
									newValue = 100;
								}
								if (newValue < 0) {
									newValue = 0;
								}
								setSettingsDashboardChange('weekInvest', parseInt(newValue));
							}}
							variant='outlined'
							type='number'
							size='small'
							value={settingsDashboard.weekInvest}
							inputProps={{
								// min: min,
								// max: 10000,
								step: 5,
								style: {
									textAlign: 'center',
								},
							}}
						/>

						<TextField
							sx={{ ml: 1, mt: 1, width: width_size_TextField }}
							label='Reinvestment Ratio'
							onChange={(e) => {
								let newValue = e.target.value;
								// Si la valeur saisie dépasse 100, la ramener à 100
								if (newValue > 100) {
									newValue = 100;
								}
								if (newValue < 0) {
									newValue = 0;
								}
								setSettingsDashboardChange('reInvestRatio', parseInt(newValue) / 100);
							}}
							variant='outlined'
							type='number'
							size='small'
							value={settingsDashboard.reInvestRatio * 100}
							inputProps={{
								// min: min,
								// max: 100,
								step: 10,
								style: {
									textAlign: 'center',
								},
							}}
						/>

						<TextField
							sx={{ ml: 1, mt: 1, width: width_size_TextField }}
							label='Nb Month Invest'
							onChange={(e) => {
								let newValue = e.target.value;
								// Si la valeur saisie dépasse 100, la ramener à 100
								if (newValue > 30 * 12) {
									newValue = 30 * 12;
								}
								if (newValue < 0) {
									newValue = 0;
								}
								setSettingsDashboardChange('nbMonth', parseInt(newValue));
							}}
							variant='outlined'
							type='number'
							size='small'
							value={settingsDashboard.nbMonth}
							inputProps={{
								// min: min,
								max: 50 * 12,
								step: 1,
								style: {
									textAlign: 'center',
								},
							}}
						/>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: size_height, border: 0 }}>
					<ResponsiveContainer width='100%' height={size_height}>
						<LineChart data={configReInvestChart} margin={{ top: 5, right: 10, left: 10, bottom: 5 }}>
							<CartesianGrid strokeDasharray='3 3' />
							<XAxis
								dataKey='date'
								tickFormatter={(value) => {
									const date = new Date(value);
									return `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
								}}
								angle={-25}
								textAnchor="end"
								height={50}
								style={{ fontSize: '0.8rem' }}
							/>
							<YAxis yAxisId='left' />
							<YAxis yAxisId='right' orientation='right' />
							<RechartsTooltip
								labelFormatter={(value) => {
									const date = new Date(value);
									return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().slice(-2)} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
								}}
							/>
							<Legend />
							<Line yAxisId='left' type='monotone' dataKey='capitalR' name='Capital ReInvest' stroke='#8884d8' dot={false} />
							<Line yAxisId='left' type='monotone' dataKey='capital' name='Capital' stroke='#82ca9d' dot={false} />
							<Line yAxisId='left' type='monotone' dataKey='rentsC' name='Rents Collected' stroke='#ffc658' dot={false} />
							<Line yAxisId='right' type='monotone' dataKey='rentsW' name='Week Rents' stroke='#ff7300' dot={false} />
						</LineChart>
					</ResponsiveContainer>
				</Box>
			</Paper>
		</>
	);
}

export default WidgetRentCollected;
