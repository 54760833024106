import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { red } from '@mui/material/colors';

const TextFieldMarket = ({ min, max, step, levelSettings, nameSettings, settings, setSettings }) => {
  const showSetting = `show${nameSettings}`;
  const minSetting = `min${nameSettings}`;
  const maxSetting = `max${nameSettings}`;
  const isSetting = `is${nameSettings}`;
  const isSettingMenu = `is${nameSettings}Menu`;

  const [localMinValue, setLocalMinValue] = useState(null); // Valeur par défaut
  const [localMaxValue, setLocalMaxValue] = useState(null); // Valeur par défaut

  // Fonction pour valider une valeur
  const validateValueMin = (value, comparedValue) => {
    if (!value || isNaN(value)) return min;

    if (value < min) return min; // Respecte la limite inférieure
    if (value > max) return max; // Respecte la limite supérieure
    if (comparedValue !== null && value >= comparedValue) return Number(comparedValue) - step; // Garde `min` strictement inférieur à `max`

    return value;
  };

  const validateValueMax = (value, comparedValue) => {
    if (!value || isNaN(value)) return min;

    if (value < min) return min; // Respecte la limite inférieure
    if (value > max) return max; // Respecte la limite supérieure
    if (comparedValue !== null && value <= comparedValue) return Number(comparedValue) + step; // Garde `min` strictement inférieur à `max`

    return value;
  };

  // Fonction pour mettre à jour les valeurs imbriquées dans settings
  const updateNestedSettings = (obj, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const deepObj = keys.reduce((acc, key) => (acc[key] = acc[key] || {}), obj);
    deepObj[lastKey] = value;
    // console.log('lastKey', lastKey, '=', value);
  };

  // Fonction pour sauvegarder les valeurs dans settings
  const handleSaveMin = () => {
    // console.log('handleSaveMin');
    setSettings((prev) => {
      const updatedSettings = { ...prev };

      updateNestedSettings(updatedSettings, `${levelSettings}.${minSetting}`, validateValueMin(Number(localMinValue), localMaxValue));

      return updatedSettings;
    });
  };

  const handleSaveMax = () => {
    // console.log('handleSaveMax');
    setSettings((prev) => {
      const updatedSettings = { ...prev };

      updateNestedSettings(updatedSettings, `${levelSettings}.${maxSetting}`, validateValueMax(Number(localMaxValue), localMinValue));

      return updatedSettings;
    });
  };

  // Initialisation des valeurs locales si elles n'existent pas
  useEffect(() => {
    // Vérifie si `levelSettings` existe
    if (!settings[levelSettings]) {
      console.log('useEffect: Initialisation de levelSettings');
      setSettings((prev) => ({
        ...prev,
        [levelSettings]: {
          [showSetting]: true,
          [minSetting]: min,
          [maxSetting]: max,
          [isSetting]: false,
          [isSettingMenu]: false,
        },
      }));
      return;
    }

    // Met à jour les paramètres manquants
    const updatedSettings = { ...settings[levelSettings] };
    let needsUpdate = false;

    if (!settings[levelSettings].hasOwnProperty(showSetting)) {
      updatedSettings[showSetting] = true;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(minSetting)) {
      updatedSettings[minSetting] = min;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(maxSetting)) {
      updatedSettings[maxSetting] = max;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(isSetting)) {
      updatedSettings[isSetting] = false;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(isSettingMenu)) {
      updatedSettings[isSettingMenu] = true;
      needsUpdate = true;
    }

    // Met à jour les paramètres si nécessaire
    if (needsUpdate) {
      console.log('Mise à jour des paramètres manquants :', nameSettings);
      setSettings((prev) => ({
        ...prev,
        [levelSettings]: updatedSettings,
      }));
    } else {
      // Synchronise les valeurs locales
      setLocalMinValue(settings[levelSettings][minSetting] || min);
      setLocalMaxValue(settings[levelSettings][maxSetting] || max);
    }
  }, [settings, levelSettings, min, max]);

  // useEffect(() => {
  //   // console.log('levelSettings:', settings[levelSettings]);
  //   console.log(nameSettings, 'minSetting:', settings[levelSettings][minSetting], localMinValue);
  //   console.log(nameSettings, 'maxSetting:', settings[levelSettings][maxSetting], localMaxValue);
  // }, [settings, localMinValue, localMaxValue]);

  if (!settings[levelSettings]) return;

  return (
    <>
      {/* {settings[levelSettings][isSettingMenu] && ( */}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'right', width: 1, border: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'top', justifyContent: 'start', width: 90, border: 0 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 1, border: 0 }}>
            <FilterAltIcon
              sx={{ ml: 0, fontSize: 30 }}
              color={settings[levelSettings][isSetting] ? 'secondary' : 'disabled'}
              onClick={() => {
                setSettings((prevSettings) => {
                  const updatedSettings = { ...prevSettings };
                  updatedSettings[levelSettings] = {
                    ...updatedSettings[levelSettings],
                    [isSetting]: !updatedSettings[levelSettings][isSetting],
                  };
                  return updatedSettings;
                });
              }}
            />
            <TuneOutlinedIcon
              sx={{ ml: 'auto', fontSize: 30 }}
              color={settings[levelSettings][isSettingMenu] ? 'secondary' : 'disabled'}
              onClick={() => {
                setSettings((prevSettings) => {
                  const updatedSettings = { ...prevSettings };
                  updatedSettings[levelSettings] = {
                    ...updatedSettings[levelSettings],
                    [isSettingMenu]: !updatedSettings[levelSettings][isSettingMenu],
                  };
                  return updatedSettings;
                });
              }}
            />
          </Box>
          <Box sx={{ width: 1 }}>
            {settings[levelSettings][isSettingMenu] && (
              <>
                {/* Champ de saisie pour la valeur minimale */}
                <TextField
                  sx={{
                    mt: 1,
                    '& .MuiInputLabel-root': {
                      color: settings[levelSettings][minSetting] === localMinValue ? 'primary.main' : 'secondary.main',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: settings[levelSettings][minSetting] === localMinValue ? 'primary.main' : 'secondary.main',
                        borderWidth: settings[levelSettings][minSetting] === localMinValue ? '1px' : '2px',
                      },
                      '&:hover fieldset': {
                        borderColor: settings[levelSettings][minSetting] === localMinValue ? 'primary.main' : 'secondary.main',
                        borderWidth: settings[levelSettings][minSetting] === localMinValue ? '1px' : '2px',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: settings[levelSettings][minSetting] === localMinValue ? 'primary.main' : 'secondary.main',
                        borderWidth: settings[levelSettings][minSetting] === localMinValue ? '1px' : '2px',
                      },
                    },
                  }}
                  label='Min'
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setLocalMinValue(e.target.value.replace(',', '.'))}
                  onBlur={handleSaveMin}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // console.log('onKeyUp', e.key);
                      handleSaveMin();
                    }
                  }}
                  variant='outlined'
                  type='text'
                  size='small'
                  value={localMinValue}
                  inputProps={{
                    min: min,
                    max: localMaxValue - step,
                    step: step,
                    style: {
                      textAlign: 'center',
                    },
                    inputMode: 'numeric',
                  }}
                />

                {/* Champ de saisie pour la valeur maximale */}
                <TextField
                  sx={{
                    mt: 1,
                    '& .MuiInputLabel-root': {
                      color: settings[levelSettings][maxSetting] === localMaxValue ? 'primary.main' : 'secondary.main',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: settings[levelSettings][maxSetting] === localMaxValue ? 'primary.main' : 'secondary.main',
                        borderWidth: settings[levelSettings][maxSetting] === localMaxValue ? '1px' : '2px',
                      },
                      '&:hover fieldset': {
                        borderColor: settings[levelSettings][maxSetting] === localMaxValue ? 'primary.main' : 'secondary.main',
                        borderWidth: settings[levelSettings][maxSetting] === localMaxValue ? '1px' : '2px',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: settings[levelSettings][maxSetting] === localMaxValue ? 'primary.main' : 'secondary.main',
                        borderWidth: settings[levelSettings][maxSetting] === localMaxValue ? '1px' : '2px',
                      },
                    },
                  }}
                  label='Max'
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => setLocalMaxValue(e.target.value.replace(',', '.'))}
                  onBlur={handleSaveMax}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      // console.log('onKeyUp', e.key);
                      handleSaveMax();
                    }
                  }}
                  variant='outlined'
                  type='text'
                  size='small'
                  value={localMaxValue}
                  inputProps={{
                    min: localMinValue + step,
                    max: max,
                    step: step,
                    style: {
                      textAlign: 'center',
                    },
                    inputMode: 'numeric',
                  }}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
      {/* )} */}
    </>
  );
};

TextFieldMarket.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  levelSettings: PropTypes.string.isRequired, // Niveau intermédiaire dynamique, ex: "PurchaseOffers"
  nameSettings: PropTypes.string.isRequired, // Nom des paramètres imbriqués, ex: "Position"
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default TextFieldMarket;
