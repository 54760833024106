import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { SettingsContext } from '../../../context/settings-context';
import { Select, Box, Typography, Paper, MenuItem, Switch } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

// Enregistrer les éléments nécessaires pour le graphique en secteurs
Chart.register(ArcElement, Tooltip, Legend);

function PiePropertyDiversity() {
  const { realTokens, holderWallets, balanceWallets } = useContext(SettingsContext);
  const { settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);
  const [tokens, setTokens] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [isCity, setIsCity] = useState(false);
  const selectedRentType = 'Year';

  // Accéder au thème MUI
  const theme = useTheme();

  useEffect(() => {
    if (realTokens) {
      let tokens = [
        ...realTokens.list.filter(
          (data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'
        ),
      ];

      let tokensBalance = [{ label: 'PiePropertyType', chain: 'total', count: 0, rent: 0, total: 0, yield: 0, tokens: [] }];

      if (balanceWallets) {
        tokensBalance.forEach((tokenBalance) => {
          if (holderWallets)
            holderWallets
              .filter((holderWallet) => holderWallet.checked)
              .forEach((holderWallet) => {
                for (const wallet of Object.keys(balanceWallets)) {
                  if (holderWallet.address === wallet) {
                    for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                      if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                        if (chain === 'eth') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
                              if (realToken) {
                                if (selectedRentType === 'Day')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                                if (selectedRentType === 'Week')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                                if (selectedRentType === 'Month')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                                if (selectedRentType === 'Year')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                                tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                if (existingTokenIndex >= 0) {
                                  // console.log("Update eth", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, token.amount);
                                  tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                                  tokenBalance.tokens[existingTokenIndex].chain.push({
                                    id: 'eth',
                                    amount: parseFloat(token.amount),
                                    wallet: wallet,
                                  });
                                } else {
                                  // If the token doesn't exist, push a new entry
                                  tokenBalance.tokens.push({
                                    id: realToken.id,
                                    token: realToken,
                                    amount: parseFloat(token.amount),
                                    chain: [{ id: 'eth', amount: parseFloat(token.amount), wallet: wallet }],
                                  });
                                }
                              }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                        if (chain === 'xdai') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
                              if (realToken) {
                                if (selectedRentType === 'Day')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                                if (selectedRentType === 'Week')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                                if (selectedRentType === 'Month')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                                if (selectedRentType === 'Year')
                                  tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                                tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                                tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                if (existingTokenIndex >= 0) {
                                  tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                                  tokenBalance.tokens[existingTokenIndex].chain.push({
                                    id: 'xdai',
                                    amount: parseFloat(token.amount),
                                    wallet: wallet,
                                  });
                                } else {
                                  tokenBalance.tokens.push({
                                    id: realToken.id,
                                    token: realToken,
                                    amount: parseFloat(token.amount),
                                    chain: [{ id: 'xdai', amount: parseFloat(token.amount), wallet: wallet }],
                                  });
                                }
                              }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                        if (chain === 'rmm') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                              if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
                                if (realToken) {
                                  if (selectedRentType === 'Day')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                                  if (selectedRentType === 'Week')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                                  if (selectedRentType === 'Month')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                                  if (selectedRentType === 'Year')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                                  tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                  let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                  if (existingTokenIndex >= 0) {
                                    tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                                    tokenBalance.tokens[existingTokenIndex].chain.push({
                                      id: 'rmm',
                                      amount: parseFloat(rmmBalance),
                                      wallet: wallet,
                                    });
                                  } else {
                                    // If the token doesn't exist, push a new entry
                                    tokenBalance.tokens.push({
                                      id: realToken.id,
                                      token: realToken,
                                      amount: parseFloat(rmmBalance),
                                      chain: [{ id: 'rmm', amount: parseFloat(rmmBalance), wallet: wallet }],
                                    });
                                  }
                                }
                              }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                        if (chain === 'rmmv3') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                              if (token.token.id !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
                                if (realToken) {
                                  if (selectedRentType === 'Day')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                                  if (selectedRentType === 'Week')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                                  if (selectedRentType === 'Month')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                                  if (selectedRentType === 'Year')
                                    tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                                  tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                                  tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                  let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                  if (existingTokenIndex >= 0) {
                                    tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                                    tokenBalance.tokens[existingTokenIndex].chain.push({
                                      id: 'rmm',
                                      amount: parseFloat(rmmBalance),
                                      wallet: wallet,
                                    });
                                  } else {
                                    // If the token doesn't exist, push a new entry
                                    tokenBalance.tokens.push({
                                      id: realToken.id,
                                      token: realToken,
                                      amount: parseFloat(rmmBalance),
                                      chain: [{ id: 'rmm', amount: parseFloat(rmmBalance), wallet: wallet }],
                                    });
                                  }
                                }
                              }
                            });
                        }
                      }

                      if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                        if (chain === 'pool') {
                          const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                          if (tokensList)
                            tokensList.forEach((token) => {
                              // console.log("chain", tokenBalance.chain, "walletBalance:", wallet, "token", token);
                              const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                              const totalSupply = parseFloat(token.pair.totalSupply);
                              if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                                if (realToken) {
                                  const poolBalance = (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                                  const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                                  const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                                  const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
                                  // if (Date.now() > getNextRonday(realToken))
                                  {
                                    if (selectedRentType === 'Day')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                                    if (selectedRentType === 'Week')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                                    if (selectedRentType === 'Month')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                                    if (selectedRentType === 'Year')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                    tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                    tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                                    tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                    let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                    if (existingTokenIndex >= 0) {
                                      // console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
                                      tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                                      tokenBalance.tokens[existingTokenIndex].chain.push({
                                        id: 'pool',
                                        amount: parseFloat(poolBalance),
                                        wallet: wallet,
                                      });
                                    } else {
                                      // If the token doesn't exist, push a new entry
                                      tokenBalance.tokens.push({
                                        id: realToken.id,
                                        token: realToken,
                                        amount: parseFloat(poolBalance),
                                        chain: [{ id: 'pool', amount: parseFloat(poolBalance), wallet: wallet }],
                                      });
                                    }
                                  }
                                }
                              }
                              if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                                const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                                if (realToken) {
                                  const poolBalance = (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                                  const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                                  const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                                  const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
                                  // if (Date.now() > getNextRonday(realToken))
                                  {
                                    if (selectedRentType === 'Day')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                                    if (selectedRentType === 'Week')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                                    if (selectedRentType === 'Month')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                                    if (selectedRentType === 'Year')
                                      tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                    tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                    tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                                    tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                                    let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                                    if (existingTokenIndex >= 0) {
                                      // console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
                                      tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                                      tokenBalance.tokens[existingTokenIndex].chain.push({
                                        id: 'pool',
                                        amount: parseFloat(poolBalance),
                                        wallet: wallet,
                                      });
                                    } else {
                                      // If the token doesn't exist, push a new entry
                                      tokenBalance.tokens.push({
                                        id: realToken.id,
                                        token: realToken,
                                        amount: parseFloat(poolBalance),
                                        chain: [{ id: 'pool', amount: parseFloat(poolBalance), wallet: wallet }],
                                      });
                                    }
                                  }
                                }
                              }
                            });
                        }
                      }
                    }
                  }
                }
              });
        });
      }
      setTokens(tokensBalance);
    }
  }, [realTokens, balanceWallets, holderWallets]);

  useEffect(() => {
    if (tokens) {
      // Fonction utilitaire pour récupérer le nom de l'état
      const getStateName = (stateCode) => {
        const states = {
          AL: 'Alabama',
          AK: 'Alaska',
          AZ: 'Arizona',
          AR: 'Arkansas',
          CA: 'California',
          CO: 'Colorado',
          CT: 'Connecticut',
          DE: 'Delaware',
          FL: 'Florida',
          GA: 'Georgia',
          HI: 'Hawaii',
          ID: 'Idaho',
          IL: 'Illinois',
          IN: 'Indiana',
          IA: 'Iowa',
          KS: 'Kansas',
          KY: 'Kentucky',
          LA: 'Louisiana',
          ME: 'Maine',
          MD: 'Maryland',
          MA: 'Massachusetts',
          MI: 'Michigan',
          MN: 'Minnesota',
          MS: 'Mississippi',
          MO: 'Missouri',
          MT: 'Montana',
          NE: 'Nebraska',
          NV: 'Nevada',
          NH: 'New Hampshire',
          NJ: 'New Jersey',
          NM: 'New Mexico',
          NY: 'New York',
          NC: 'North Carolina',
          ND: 'North Dakota',
          OH: 'Ohio',
          OK: 'Oklahoma',
          OR: 'Oregon',
          PA: 'Pennsylvania',
          RI: 'Rhode Island',
          SC: 'South Carolina',
          SD: 'South Dakota',
          TN: 'Tennessee',
          TX: 'Texas',
          UT: 'Utah',
          VT: 'Vermont',
          VA: 'Virginia',
          WA: 'Washington',
          WV: 'West Virginia',
          WI: 'Wisconsin',
          WY: 'Wyoming',
        };
        return states[stateCode.toUpperCase()] || 'Unknown State';
      };

      const calculateCounts = (isCity) => {
        return tokens[0].tokens.reduce((acc, token) => {
          const key = isCity
            ? token.token.fullName.split(',')[1].trim()
            : token.token.fullName.split(',').length === 3
              ? getStateName(token.token.fullName.split(',')[2].trim().slice(0, 2))
              : token.token.fullName.split(',')[3].trim();

          if (!acc[key]) {
            acc[key] = { nbProperties: 1, capital: token.amount * token.token.tokenPrice, list: [token] };
          } else {
            acc[key].nbProperties += 1;
            acc[key].capital += token.amount * token.token.tokenPrice;
            acc[key].list.push(token);
          }
          return acc;
        }, {});
      };
      const counts = calculateCounts(isCity);

      const totalProperties = parseInt(tokens[0].tokens.reduce((acc, data) => acc + (data.token.totalUnits > 0 ? 1 : 0 || 0), 0));
      const totalCapital = tokens[0].total;

      const data = Object.entries(counts).map(([key, count]) => ({
        label: key,
        nbProperties: counts[key].nbProperties,
        capital: parseFloat((counts[key].capital / 1000).toFixed(2)),
        list: counts[key].list,
        value:
          settingsDashboard.selectedCalculType === 'RealTokens'
            ? (100 * count.nbProperties) / totalProperties
            : (100 * count.capital) / totalCapital,
      }));
      // console.table(data);

      // Limiter les résultats à 30 entrées
      const limitedData = data.sort((a, b) => b.value - a.value || a.label.localeCompare(b.label)).slice(0, 26);

      const chartData = {
        labels: limitedData.map((d) =>
          settingsDashboard.selectedCalculType === 'RealTokens'
            ? d.label + ` (${d.nbProperties})`
            : d.capital > 999999
              ? d.label + ` (${d.capital / 1000}M$)`
              : d.label + ` (${d.capital}k$)`
        ),
        datasets: [
          {
            data: limitedData.map((d) => d.value),
            backgroundColor: limitedData.map(
              (_, i) => `hsl(${(i * 360) / limitedData.length}, 100%, ${theme.palette.mode === 'dark' ? '30%' : '50%'})`
            ),
          },
        ],
      };
      setChartData(chartData);
    }
  }, [tokens, settingsDashboard.selectedCalculType, isCity, theme.palette.mode]);

  const setSettingsDashboardChange = (key, newValue) => {
    setSettingsDashboard((prevSettings) => ({
      ...prevSettings,
      [key]: newValue,
    }));
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Permet de contrôler directement la taille
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // return `${context.label}: ${context.raw.toFixed(1)}%`;
            return `Percentage: ${context.raw.toFixed(1)}%`;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 0, // Supprime l'espace au-dessus
        bottom: 0, // Supprime l'espace en bas
      },
    },
    // cutout: "70%", // Transforme le camembert en anneau (doughnut) en définissant la taille du centre vide
  };

  if (!settingsDashboard.isExpandedCharts) return <></>;
  if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
  if (!chartData) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        m: 1,
        // minWidth: 300,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 4,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 1 }}>
          <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
            Properties to
          </Typography>
          <Select
            sx={{ mr: 1, minWidth: 125 }}
            value={settingsDashboard.selectedCalculType}
            onChange={(e) => setSettingsDashboardChange('selectedCalculType', e.target.value)}
            size='small'
          >
            {settingsDashboard.calculType.map((p, index) => (
              <MenuItem key={index} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
          <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
            Diversity Ratio
          </Typography>
        </Box>

        <Box sx={{ pr: 1, width: 160, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end' }}>
          <Typography variant='body2' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
            {isCity ? 'Cities (26)' : 'Countries'}
          </Typography>
          <Switch size='small' checked={isCity} onChange={() => setIsCity(!isCity)} name='switchCity' />
        </Box>
      </Box>

      <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', m: 0 }}>
        <Doughnut data={chartData} options={options} width={750} height={300} />
      </Box>
    </Paper>
  );
}

export default PiePropertyDiversity;
