import React, { useContext, useEffect } from 'react';
import { SettingsContext } from '../../context/settings-context';

import CoinHistory from './WalletsChart/CoinHistory';

import { Box, Paper, Typography } from '@mui/material';

function Wallets() {
  const { historyWallets, settingsWallet } = useContext(SettingsContext);

  if (!historyWallets) return <></>;
  if (!settingsWallet) return <></>;

  if (!historyWallets[settingsWallet.selectedWallet]) return <></>;
  if (!historyWallets[settingsWallet.selectedWallet].coins[settingsWallet.selectedBlockchain]) return <></>;

  const coinUsed = historyWallets[settingsWallet.selectedWallet].coins[settingsWallet.selectedBlockchain];
  let isArchive = false;

  for (const cryptoLabel in coinUsed) {
    if (Object.keys(coinUsed[cryptoLabel]).length > 0) {
      isArchive = true;
      break;
    }
  }

  if (!isArchive)
    return (
      <Box sx={{ flexWrap: 'wrap', m: 1, p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Paper elevation={3} sx={{ m: 1, maxWidth: 600, border: 1, borderColor: 'secondary.main', borderRadius: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 0 }}>
            <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
              No Archive stored for the selected wallet.
            </Typography>
            <Typography variant='body2' sx={{ mx: 10, my: 0, color: 'secondary.main' }}>
              Blockchain: {settingsWallet.selectedBlockchain}
            </Typography>
            <Typography variant='body2' sx={{ mx: 10, my: 0, color: 'secondary.main' }}>
              wallet: {settingsWallet.selectedWallet}
            </Typography>
          </Box>
        </Paper>
      </Box>
    );

  // console.log("Stats - Wallet");

  return (
    <Box sx={{ flexWrap: 'wrap', m: 1, p: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {Object.keys(coinUsed).map((coin) => (
        <CoinHistory key={coin} coin={coin} archive={coinUsed[coin]} />
      ))}
    </Box>
  );
}

export default Wallets;
