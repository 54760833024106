import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, TextField, Typography } from '@mui/material';

const TextFieldSetting = ({ value, onChange, disabled, label, title }) => {
  const handleChange = (event) => {
    onChange(event);
  };
  const [localSelect, setLocalSelect] = useState('');

  const handle_setLocalSelect = (value) => {
    setLocalSelect(value);
    handleChange(value);
  };

  useEffect(() => {
    let val = value;
    setLocalSelect(val);
  }, [value]);

  return (
    <Box
      sx={{ mx: 1, p: 0, minWidth: 600, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography variant='body2' sx={{ mr: 'auto' }}>
        {title}
      </Typography>

      <TextField
        sx={{ mt: 1, width: 175 }}
        label={label}
        onChange={(e) => handle_setLocalSelect(e.target.value)}
        variant='outlined'
        type='text'
        size='small'
        value={localSelect}
        disabled={disabled}
        inputProps={{
          style: {
            textAlign: 'center',
          },
        }}
      />
    </Box>
  );
};

TextFieldSetting.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default TextFieldSetting;
