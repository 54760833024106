import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { Avatar, Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';

function CoinHistory({ coin, archive }) {
  const { settingsWallet, blockchainsClient, setBlockchainsClient } = useContext(SettingsContext);

  const filterArchive = {};
  for (const key in archive) {
    if (!isNaN(parseInt(key))) {
      filterArchive[key] = archive[key];
    }
  }

  let blockchainCoin = blockchainsClient[settingsWallet.selectedBlockchain].coinList[coin];
  if (!blockchainCoin) return <></>;

  const priceHistory = Object.keys(filterArchive).map((x) => ({
    timestamp: parseInt(x),
    balance: archive[x].balance,
    usd: archive[x].balance * archive[x].usd,
  }));

  if (blockchainCoin.history === null) {
    setBlockchainsClient((prevSettings) => ({
      ...prevSettings,
      history: false,
    }));
    blockchainCoin = blockchainsClient[settingsWallet.selectedBlockchain].coinList[coin];
  }
  if (blockchainCoin.usd === null) {
    setBlockchainsClient((prevSettings) => ({
      ...prevSettings,
      usd: false,
    }));
    blockchainCoin = blockchainsClient[settingsWallet.selectedBlockchain].coinList[coin];
  }

  // console.log("CoinHistory", coin, blockchainCoin.history,blockchainCoin.usd,blockchainCoin, );
  if (!blockchainCoin.history && !blockchainCoin.usd) return <></>;

  const yieldFormatter = (date, balanceOrYield) => {
    return `${date.toLocaleDateString()} - Value: ${balanceOrYield.toFixed(2)}`;
  };
  const maxBalances = blockchainCoin.usd
    ? Math.max(...priceHistory.map((x) => x.balance))
    : Math.max(...priceHistory.map((x) => x.balance));
  const times = priceHistory.map((x) => new Date(x.timestamp));
  const balances = priceHistory.map((x) => parseFloat(x.balance));
  const usdValues = blockchainCoin.usd ? priceHistory.map((x) => parseFloat(x.usd)) : [];
  // const balances = blockchainCoin.usd ? priceHistory.map((x) => parseFloat(x.usd)) : priceHistory.map((x) => parseFloat(x.balance));

  // console.log("balances", coin, balances);

  const yieldsAndTimestamp = priceHistory
    .map((x, index) => {
      if (index > 0) {
        const deltaTime = x.timestamp - priceHistory[index - 1].timestamp;
        const deltaBalance = x.balance - priceHistory[index - 1].balance;
        const yieldFullTime = 1000 * 60 * 60 * 24 * 365;
        const value = parseFloat((100 * ((deltaBalance * yieldFullTime) / deltaTime)) / priceHistory[index - 1].balance);
        return { id: index, timestamp: new Date(x.timestamp), yield: value };
      } else return { id: index, timestamp: new Date(x.timestamp), yield: 0 };
    })
    .filter((x) => x.yield > 0)
    .filter((x) => x.yield < 50);
  const yieldstimes = yieldsAndTimestamp.map((x) => x.timestamp);
  const yields = yieldsAndTimestamp.map((x) => x.yield);
  const avgYield = yieldsAndTimestamp.length > 0 ? yieldsAndTimestamp.reduce((acc, x) => acc + x.yield, 0) / yieldsAndTimestamp.length : 0;
  const avgYields = yieldsAndTimestamp.map((x) => avgYield);

  // console.log("balances", coin, balances);
  // console.log("yieldsAndTimestamp", coin, yields, avgYields, yieldsAndTimestamp);

  if (blockchainCoin.history && (!maxBalances > 0 || balances.length < 4))
    return (
      <>
        {/* <Box sx={{ mx: 1, p: 0, minWidth: 250, border: 1, borderColor: "secondary.main", borderRadius: 4 }}> */}
        {/* <Box sx={{ flexWrap: "flex", m: 1, p: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
					<Avatar alt={blockchainCoin.name} src={blockchainCoin.imgToken} fontSize='small' sx={{ width: 50, height: 50, m: 0 }} />

					<Typography variant='h5' sx={{ my: 1, color: "primary.main", display: "flex", alignItems: "center", justifyContent: "center" }}>
						{blockchainCoin.name}
					</Typography>
					<Typography variant='h5' sx={{ my: 1, color: "primary.main", display: "flex", alignItems: "center", justifyContent: "center" }}>
						Token not used on RMM
					</Typography>
				</Box> */}
        {/* </Box> */}
      </>
    );

  return (
    <Box sx={{
      m: 1,
      p: 2,
      minWidth: 750,
      border: 1,
      borderColor: 'primary.main',
      borderRadius: 4,
      bgcolor: 'background.paper',
      boxShadow: 3
    }}>
      <Box sx={{
        flexWrap: 'wrap',
        mx: 1,
        p: 2,
        mb: 3,
        borderBottom: 1,
        borderColor: 'primary.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}>
        <Avatar
          alt={blockchainCoin.name}
          src={blockchainCoin.imgToken}
          sx={{
            width: 60,
            height: 60,
            boxShadow: 2
          }}
        />
        <Typography variant='h5' color='primary.main'>
          {blockchainCoin.name}
        </Typography>
      </Box>

      {maxBalances > 0 && (
        <Box sx={{
          minWidth: 500,
          height: 300,
          mb: 2,
          p: 1,
          bgcolor: '#f5f5f5',
          borderRadius: 2
        }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={{ top: 10, right: 30, left: 10, bottom: 5 }}
              data={priceHistory.map(item => ({
                timestamp: item.timestamp,
                balance: parseFloat(item.balance),
                usd: blockchainCoin.usd ? parseFloat(item.usd) : undefined
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis
                dataKey="timestamp"
                type="number"
                scale="time"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString(undefined, {
                  day: '2-digit',
                  month: '2-digit'
                })}
                style={{ fontSize: '0.8rem', fontWeight: 500 }}
                angle={-45}
                textAnchor="end"
                height={50}
              />
              <YAxis
                axisLine={true}
                tickLine={true}
                tickSize={8}
                dx={-3}
                allowDecimals={true}
                tickMargin={5}
                style={{ fontSize: '0.8rem', fontWeight: 500 }}
              />
              <RechartsTooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '10px'
                }}
                formatter={(value, name) => [value.toFixed(2), name]}
                labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString(undefined, {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              />
              <Legend wrapperStyle={{ paddingTop: '20px' }} />
              <Line
                type="monotone"
                dataKey="balance"
                name="Balance"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
              />
              {blockchainCoin.usd && (
                <Line
                  type="monotone"
                  dataKey="usd"
                  name="Balance (in $)"
                  stroke="#82ca9d"
                  strokeWidth={2}
                  dot={false}
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}

      {yields.length > 0 && (
        <Box sx={{
          minWidth: 500,
          height: 300,
          p: 2,
          bgcolor: '#f5f5f5',
          borderRadius: 2
        }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              margin={{ top: 10, right: 30, left: 10, bottom: 5 }}
              data={yieldsAndTimestamp.map(item => ({
                timestamp: item.timestamp.getTime(),
                yield: item.yield,
                avgYield: avgYield
              }))}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
              <XAxis
                dataKey="timestamp"
                type="number"
                scale="time"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString(undefined, {
                  day: '2-digit',
                  month: '2-digit'
                })}
                style={{ fontSize: '0.8rem', fontWeight: 500 }}
                angle={-45}
                textAnchor="end"
                height={50}
              />
              <YAxis
                axisLine={true}
                tickLine={true}
                tickSize={8}
                dx={-3}
                allowDecimals={true}
                tickMargin={5}
                style={{ fontSize: '0.8rem', fontWeight: 500 }}
              />
              <RechartsTooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '10px'
                }}
                formatter={(value, name) => [`${value.toFixed(2)}%`, name]}
                labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString(undefined, {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              />
              <Legend wrapperStyle={{ paddingTop: '20px' }} />
              <Line
                type="monotone"
                dataKey="yield"
                name="Yield (in %)"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
              />
              <Line
                type="monotone"
                dataKey="avgYield"
                name="Average Yield (in %)"
                stroke="#ff69b4"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
}

export default CoinHistory;
