import React, { useContext, useState, useEffect } from 'react';
import { SettingsContext } from '../../context/settings-context';
import { MyThemeContext } from '../../context/theme-context';

import {
  Box,
  Slide,
  Dialog,
  Button,
  Typography,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
} from '@mui/material';
import { LineChart } from '@mui/x-charts';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { green, red, blue, purple, orange } from '@mui/material/colors';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FormatedNumber from './Formater/FormatedNumber';
import FormatedPrice from './Formater/FormatedPrice';
import FormatedPercentage from './Formater/FormatedPercentage';
import FormatedNumberDays from './Formater/FormatedNumberDays';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function TokenInfos({ open, setOpen, token }) {
  const { settingsMarket, settingsWallet } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [rents, setRents] = useState(null);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  const handleClose = () => {
    setOpen(false);
  };

  const get_APIURL_RENT_HOLDER = async (wallet) => {
    const APIURL_WHITELIST = 'https://ehpst.duckdns.org/realt_rent_tracker/api/rent_holder/' + wallet;
    console.log('get_APIURL_RENT_HOLDER - wallet:', APIURL_WHITELIST);
    try {
      let response = await fetch(APIURL_WHITELIST);
      // console.log("get_WhiteListTokens - response", response);
      if (response.ok) {
        let body = await response.json();
        setRents(body);
        // console.log("get_APIURL_RENT_HOLDER - body", body);
        // openSnackbar(`Update realTokens WhiteList of ${wallet.slice(0, 6)}...${wallet.slice(-4)}`, "success");
      }
    } catch (error) {
      console.log('get_APIURL_RENT_HOLDER - fetch error: wallet:', wallet, error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        console.log('TokenInfos - useEffect:', settingsWallet);
        if (settingsWallet) if (settingsWallet.selectedWallet) await get_APIURL_RENT_HOLDER(settingsWallet.selectedWallet);
      }
    };
    fetchData();
  }, [open, settingsWallet]);

  if (!open) return <></>;
  if (!token) return <></>;

  // Define the start date and end date (today)
  const startDate = new Date(token.rentStartDate.date);
  const today = new Date();
  const oneWeekInMs = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const oneYearInMs = 365 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

  // Generate synthetic priceHistory array
  const priceHistory = [];
  let rent = 0;
  for (let currentTime = startDate.getTime(); currentTime <= today.getTime(); currentTime += oneWeekInMs) {
    const closestHistoricalPrice = token.historic.prices
      .sort((a, b) => new Date(b.timsync).getTime() - new Date(a.timsync).getTime())
      .find((p) => new Date(p.timsync).getTime() <= currentTime);
    const closestHistoricalYield = token.historic.yields
      .sort((a, b) => new Date(b.timsync).getTime() - new Date(a.timsync).getTime())
      .find((p) => new Date(p.timsync).getTime() <= currentTime);

    // console.log("startDate.getTime()", startDate.getTime());
    // console.log("currentTime", currentTime);
    // console.log("currentTime", currentTime - startDate.getTime());
    // console.log("rent", (((100 * rent) / (currentTime - startDate.getTime())) * oneYearInMs) / closestHistoricalPrice.price);

    let rt_yield = parseFloat(closestHistoricalYield.yield.toFixed(2));
    if (currentTime !== startDate.getTime()) {
      rent += ((closestHistoricalPrice.price * closestHistoricalYield.yield) / 100 / 365) * 7;
      rt_yield = parseFloat(
        (((100 * (rent + closestHistoricalPrice.price - token.historic.init_price)) / (currentTime - startDate.getTime())) * oneYearInMs) /
          token.historic.init_price
      ).toFixed(2);
    }

    priceHistory.push({
      timestamp: currentTime,
      price: parseFloat(closestHistoricalPrice.price.toFixed(2)),
      yield: parseFloat(closestHistoricalYield.yield.toFixed(2)),
      rt_yield: parseFloat(rt_yield),
      init_price: parseFloat(token.historic.init_price.toFixed(2)),
      avg_yield: parseFloat(token.historic.avg_yield.toFixed(2)),
      init_yield: parseFloat(token.historic.init_yield.toFixed(2)),
      rent: rent,
      full_rent: rent + closestHistoricalPrice.price - token.historic.init_price,
    });
  }
  // console.table(priceHistory);
  // console.table(token.historic.yields);

  const configPriceLineChart = {
    series: [{ data: priceHistory.map((x) => parseFloat(x.price)), label: 'Token Price', showMark: false }],
    height: 250,
  };
  const configRentLineChart = {
    series: [
      { data: priceHistory.map((x) => parseFloat(x.rent)), label: 'Rents', showMark: false },
      ...(token.historic.prices.length > 1
        ? [
            {
              data: priceHistory.map((x) => parseFloat(x.full_rent)),
              label: 'Rents & Prices',
              showMark: false,
              lineStyle: {
                strokeDasharray: '4 8',
              },
            },
          ]
        : []),
      ...(token.historic.prices.length > 1
        ? [
            {
              data: priceHistory.map((x) => parseFloat(x.init_price)),
              label: 'Initial Price',
              showMark: false,
              lineStyle: {
                strokeDasharray: '4 8',
              },
            },
          ]
        : []),
    ],
    height: 250,
  };

  const configTokenYieldLineChart = {
    series: [
      { data: priceHistory.map((x) => parseFloat(x.yield)), label: 'Yield', showMark: false },
      { data: priceHistory.map((x) => parseFloat(x.init_yield)), label: 'Initial Yield', showMark: false },
    ],
    height: 250,
  };

  const configYieldLineChart = {
    series: [
      {
        data: priceHistory.map((x) => parseFloat(x.avg_yield)),
        label: 'Average Yield',
        showMark: false,
        lineDash: [4, 8],
      },
      {
        data: priceHistory.map((x) => parseFloat(x.rt_yield)),
        label: 'RealTime Yield',
        showMark: false,
        lineDash: [4, 8],
      },
    ],
    height: 250,
  };

  const xAxisPriceLineChart = {
    data: priceHistory.map((x) => new Date(x.timestamp)),
    scaleType: 'time',
  };

  const rowTitle = 180;
  const rowValue = 130;
  const rowSeparation = 10;
  const imgSize = 212;

  console.log('token', token);
  // 	if(!('rentStartDate' in token))
  // else
  // 	if(!('date' in token.rentStartDate))
  // 		console.log('token.rentStartDate',token.rentStartDate)

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      sx={{
        m: 0,
        width: 1,
        '& .MuiDialog-paper': {
          width: 1100,
          maxWidth: 'none',
          border: 5,
          color: blue[600],
          borderRadius: 0,
        },
      }}
    >
      <DialogTitle sx={{ m: 1, p: 0, border: 0 }}>
        <Box sx={{ border: 2, color: blue[600], display: 'flex', flexDirection: 'row', alignItems: 'top', justifyContent: 'top' }}>
          <Box component='img' alt='imgToken' src={token.imageLink[0]} sx={{ m: 0, width: imgSize, height: imgSize }} />
          <Box sx={{ m: 1, p: 0, border: 0 }}>
            <Box sx={{ pb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => window.open(token.marketplaceLink, '_blank')}
              >
                <OpenInNewIcon />
              </Box>
              <Typography variant='h4' sx={{ ml: 1, color: blue[600] }} onClick={() => window.open(token.marketplaceLink, '_blank')}>
                {isMatch ? token.shortName : token.fullName}
              </Typography>
            </Box>

            <Box sx={{ ml: 2, mt: 0, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ ml: 0, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='h6' sx={{ color: blue[600] }}>
                  {'Expected Income'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedPercentage variant='h6' value={token.annualPercentageYield / 100} color={blue[600]} />
              </Box>
              <Box sx={{ ml: rowSeparation, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='h6' sx={{ color: blue[600] }}>
                  {'Initial Income'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedPercentage variant='h6' value={token.historic.init_yield / 100} color={blue[600]} />
              </Box>
            </Box>

            <Box sx={{ ml: 2, mt: 1, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ ml: 0, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Income Start Date'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {token.rentStartDate.date.slice(0, 10)}
                </Typography>
              </Box>
              <Box sx={{ ml: rowSeparation, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Last Valuation Date'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {token.historic.prices[token.historic.prices.length - 1].timsync.slice(0, 10)}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ ml: 2, mt: 1, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ ml: 0, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Year income per Token'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedPrice variant='body2' value={token.netRentYearPerToken} color={blue[600]} />
              </Box>
              <Box sx={{ ml: rowSeparation, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Initial income'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedPrice variant='body2' value={(token.historic.init_price * token.historic.init_yield) / 100} color={blue[600]} />
              </Box>
            </Box>

            <Box sx={{ ml: 2, mt: 1, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ ml: 0, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Token Price'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedPrice variant='body2' value={token.tokenPrice} color={blue[600]} />
              </Box>
              <Box sx={{ ml: rowSeparation, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Initial token Price'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedPrice variant='body2' value={token.historic.init_price} color={blue[600]} />
              </Box>
            </Box>

            <Box sx={{ ml: 2, mt: 1, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ ml: 0, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Holder Position'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {token.amount}
                </Typography>
              </Box>
              <Box sx={{ ml: rowSeparation, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <Typography variant='body2' sx={{ color: blue[600] }}>
                  {'Total Tokens'}
                </Typography>
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <FormatedNumber variant='body2' value={token.totalTokens} color={blue[600]} />
              </Box>
            </Box>

            <Box sx={{ ml: 2, mt: 1, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ ml: 0, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                {/* <Typography variant='body1' sx={{ color: blue[600] }}>
									{"Token Type"}
								</Typography> */}
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {/* <Typography variant='body1' sx={{ color: blue[600] }}>
									{token.propertyTypeName}
								</Typography> */}
              </Box>
              <Box sx={{ ml: rowSeparation, width: rowTitle, display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                {/* <Typography variant='body2' sx={{ color: blue[600] }}>
									{"Token Type"}
								</Typography> */}
              </Box>
              <Box sx={{ ml: 0, width: rowValue, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                {/* <Typography variant='body2' sx={{ color: blue[600] }}>
									{token.propertyTypeName}
								</Typography> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ m: 1, p: 0, border: 1 }}>
        <DialogContentText id='alert-dialog-slide-description' sx={{ m: 1, border: 0, color: blue[500] }}>
          <Box sx={{ ml: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start' }}>
            {priceHistory.length < 2 ? (
              <Typography variant='h4' sx={{ ml: 1, color: blue[600] }}>
                There is no Rent for this realToken, come back in few days ...
              </Typography>
            ) : (
              <>
                <Box sx={{ ml: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                  <Box sx={{ minWidth: isMatch ? 400 : 500, alignItems: 'center' }}>
                    <LineChart
                      // tooltip={{ formatter: balanceFormatter }}
                      xAxis={[
                        {
                          ...xAxisPriceLineChart,
                          tickMinStep: 3600 * 1000 * 24, // min step: 24h
                        },
                      ]}
                      {...configPriceLineChart}
                    />
                    <Typography variant='body2' sx={{ m: 0, width: '100%', textAlign: 'center', color: blue[600], fontStyle: 'italic' }}>
                      RealToken Price Evolution
                    </Typography>
                  </Box>
                  <Box sx={{ minWidth: isMatch ? 400 : 500 }}>
                    <LineChart
                      // tooltip={{ formatter: balanceFormatter }}
                      xAxis={[
                        {
                          ...xAxisPriceLineChart,
                          tickMinStep: 3600 * 1000 * 24, // min step: 24h
                        },
                      ]}
                      {...configRentLineChart}
                    />
                    <Typography variant='body2' sx={{ m: 0, width: '100%', textAlign: 'center', color: blue[600], fontStyle: 'italic' }}>
                      Accumulated rents (including valuation prices) vs Initial Price
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
                  <Box sx={{ minWidth: isMatch ? 400 : 500 }}>
                    <LineChart
                      // tooltip={{ formatter: balanceFormatter }}
                      xAxis={[
                        {
                          ...xAxisPriceLineChart,
                          tickMinStep: 3600 * 1000 * 24, // min step: 24h
                        },
                      ]}
                      {...configTokenYieldLineChart}
                    />
                    <Typography variant='body2' sx={{ m: 0, width: '100%', textAlign: 'center', color: blue[600], fontStyle: 'italic' }}>
                      RealToken Yield Evolution
                    </Typography>
                  </Box>
                  <Box sx={{ minWidth: isMatch ? 400 : 500 }}>
                    {token.historic.yields.length > 1 && (
                      <>
                        <LineChart
                          // tooltip={{ formatter: balanceFormatter }}
                          xAxis={[
                            {
                              ...xAxisPriceLineChart,
                              tickMinStep: 3600 * 1000 * 24, // min step: 24h
                            },
                          ]}
                          {...configYieldLineChart}
                        />
                        <Typography
                          variant='body2'
                          sx={{ m: 0, width: '100%', textAlign: 'center', color: blue[600], fontStyle: 'italic' }}
                        >
                          RealTime Yield including valuation prices of the realToken
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      {/* <DialogActions sx={{ m: 1, border: 3, color: red[500] }}> */}
      {/* <Button onClick={handleClose}>Close</Button> */}
      {/* </DialogActions> */}
      {/* <Box sx={{ ml: 0, width: 1, display: "flex", alignItems: "center", justifyContent: "end" }}>
				<Button onClick={handleClose}>Close</Button>
			</Box> */}
    </Dialog>
  );
}

export default TokenInfos;
