import React, { createContext, useContext, useEffect, useState } from 'react';
import { createPublicClient, http, formatUnits } from 'viem';
import { gnosis, mainnet } from 'viem/chains';
import { SnackbarContext } from './customsnackbar-context';
import { SettingsContext } from './settings-context';

// ABI minimal pour la fonction balanceOf
const ERC20_ABI = [
  {
    inputs: [{ name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
];

export const ViemRealTokensContext = createContext();

export const ViemRealTokensProvider = ({ children }) => {
  const { openSnackbar } = useContext(SnackbarContext);
  const { realTokens, settingsWallet, holderWallets } = useContext(SettingsContext);
  const [balances, setBalances] = useState({});

  // Création des clients viem
  const gnosisClient = createPublicClient({
    chain: gnosis,
    transport: http(),
  });

  const mainnetClient = createPublicClient({
    chain: mainnet,
    transport: http(),
  });

  const getTokenBalance = async (client, tokenAddress, walletAddress) => {
    try {
      const [balance, decimals] = await Promise.all([
        client.readContract({
          address: tokenAddress,
          abi: ERC20_ABI,
          functionName: 'balanceOf',
          args: [walletAddress],
        }),
        client.readContract({
          address: tokenAddress,
          abi: ERC20_ABI,
          functionName: 'decimals',
        }),
      ]);

      return {
        raw: balance,
        formatted: formatUnits(balance, decimals),
      };
    } catch (error) {
      console.error(`Erreur lors de la récupération du solde pour ${tokenAddress}:`, error);
      return null;
    }
  };

  const updateWalletBalances = async (wallet) => {
    const newBalances = { ...balances };
    newBalances[wallet] = {
      gnosis: {},
      ethereum: {},
      timestamp: Date.now(),
    };

    if (realTokens?.list) {
      // Traitement des tokens sur Gnosis
      const gnosisTokens = realTokens.list.filter((token) => token.chainId === 100);
      for (const token of gnosisTokens) {
        const balance = await getTokenBalance(gnosisClient, token.address, wallet);
        if (balance && Number(balance.formatted) > 0) {
          newBalances[wallet].gnosis[token.address] = balance;
        }
      }

      // Traitement des tokens sur Ethereum
      const ethereumTokens = realTokens.list.filter((token) => token.chainId === 1);
      for (const token of ethereumTokens) {
        const balance = await getTokenBalance(mainnetClient, token.address, wallet);
        if (balance && Number(balance.formatted) > 0) {
          newBalances[wallet].ethereum[token.address] = balance;
        }
      }
    }

    setBalances(newBalances);
    return newBalances;
  };

  const updateAllBalances = async () => {
    const wallets = [...(holderWallets || [])].map((w) => w.address);
    for (const wallet of wallets) {
      const currentBalance = balances[wallet];
      const timer = holderWallets?.find((w) => w.address === wallet)
        ? settingsWallet?.timerUpdateTokenHolderWallets
        : settingsWallet?.timerUpdateTokenFollowWallets;

      if (!currentBalance || !currentBalance.timestamp || currentBalance.timestamp + timer < Date.now()) {
        await updateWalletBalances(wallet);
        openSnackbar(`Mise à jour des balances pour ${wallet.slice(0, 6)}...${wallet.slice(-4)}`, 'success');
      }
    }
  };

  useEffect(() => {
    if (realTokens?.list && (holderWallets)) {
      const interval = setInterval(() => {
        updateAllBalances();
      }, 60000); // Vérifie toutes les minutes si des mises à jour sont nécessaires

      return () => clearInterval(interval);
    }
  }, [realTokens, holderWallets]);

  const refreshBalances = () => {
    updateAllBalances();
  };

  return (
    <ViemRealTokensContext.Provider
      value={{
        balances,
        refreshBalances,
      }}
    >
      {children}
    </ViemRealTokensContext.Provider>
  );
};
