import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n'; // Importer le fichier de configuration d'i18next

import { SnackbarProvider } from './context/customsnackbar-context';
import { SettingsProvider } from './context/settings-context';
import { MyThemeProvider } from './context/theme-context';
import { RealTokensProvider } from './context/realTokens-context';
import { ApiCoinGeckoProvider } from './context/apiCoinGecko-context';
import { Web3Provider } from './context/web3-context.jsx';
import { ViemRealTokensProvider } from './context/viem-realtokens-context';
import ErrorBoundary from './components/Common/ErrorBoundary';

import '@fontsource/roboto';
import './styles/leaflet.css';

// Ajoutez cette fonction de gestion d'erreurs
window.onerror = function (message, source, lineno, colno, error) {
  console.error('Erreur globale:', { message, source, lineno, colno, error });
  return false;
};

// Ajoutez cette gestion des rejets de promesses non gérés
window.addEventListener('unhandledrejection', function (event) {
  console.error('Promesse rejetée non gérée:', event.reason);
});

try {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  const logBuffer = [];
  const MAX_LOGS = 100;

  // Surcharge des méthodes console
  ['log', 'warn', 'error', 'info'].forEach((method) => {
    const originalMethod = console[method];
    console[method] = (...args) => {
      logBuffer.push({ type: method, message: args.join(' '), timestamp: new Date() });
      if (logBuffer.length > MAX_LOGS) logBuffer.shift();
      originalMethod.apply(console, args);
    };
  });

  root.render(
    <ErrorBoundary>
      <React.StrictMode>
        <SnackbarProvider>
          <SettingsProvider>
            <MyThemeProvider>
              <Web3Provider>
                <RealTokensProvider>
                  <ViemRealTokensProvider>
                    <ApiCoinGeckoProvider>
                      <App />
                    </ApiCoinGeckoProvider>
                  </ViemRealTokensProvider>
                </RealTokensProvider>
              </Web3Provider>
            </MyThemeProvider>
          </SettingsProvider>
        </SnackbarProvider>
      </React.StrictMode>
    </ErrorBoundary>
  );
} catch (error) {
  console.error('Erreur lors du rendu initial:', error);
}
