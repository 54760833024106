import React from 'react';

const logBuffer = [];

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary a capturé une erreur:', error, errorInfo);
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  handle_erase_market_settings = () => {
    localStorage.removeItem('pitsbi__realt__settingsMarket');
    // Reload the page
    window.location.reload();
  };

  handle_erase_settings = () => {
    localStorage.removeItem('pitsbi__realt__lastOpenedPopper');
    localStorage.removeItem('pitsbi__realt__settingsApiCoinGecko');
    localStorage.removeItem('pitsbi__realt__settingsDashboard');
    localStorage.removeItem('pitsbi__realt__settingsMap');
    localStorage.removeItem('pitsbi__realt__settingsMarket');
    localStorage.removeItem('pitsbi__realt__settingsStats');
    localStorage.removeItem('pitsbi__realt__settingsTrafic');
    localStorage.removeItem('pitsbi__realt__settingsTraffic');
    // Reload the page
    window.location.reload();
  };

  handle_erase_all_settings = () => {
    localStorage.clear();
    // Reload the page
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h1>Quelque chose s'est mal passé.</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
