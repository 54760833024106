import React from 'react';
import { Typography } from '@mui/material';

const FormatedPrice = ({ value, color = 'inherit', variant = 'body2', fontWeight = 'normal', digit = 2 }) => {
  const userLocale = navigator.language; // const userLocales = navigator.languages;
  // console.log("userLocale FormatedPrice", userLocale,value); // console.log(userLocales[0]);

  const formatedPrice =
    value !== null
      ? !isNaN(value)
        ? value.toLocaleString(userLocale, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: digit,
            currencyDisplay: 'narrowSymbol', // "symbol", "narrowSymbol", "code", "name"
            useGrouping: true,
          })
        : 'NaN'
      : 'N/A';

  return (
    <Typography variant={variant} sx={{ color: color }} style={{ fontWeight: fontWeight }}>
      {formatedPrice}
    </Typography>
  );
};

export default FormatedPrice;
