import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function Menu03({ number, title, content, description, defaultExpand = true }) {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    setIsExpanded(defaultExpand);
  }, []);

  return (
    <Box sx={{ width: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ ml: 8, display: 'flex', flexDirection: 'row' }}>
        <IconButton aria-label={isExpanded ? 'Collapse' : 'Expand'} onClick={toggleExpand}>
          {isExpanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        </IconButton>
        <Typography variant='h6' sx={{ m: 1, color: 'primary.main', cursor: 'pointer' }} onClick={toggleExpand}>
          {number}
          {') '}
          {title}
        </Typography>
      </Box>
      {isExpanded && (
        <>
          {description && (
            <Typography variant='body2' sx={{ ml: 7, color: 'text.secondary' }}>
              {description}
            </Typography>
          )}
          {content && <Box sx={{ flexWrap: 'wrap', m: 0, display: 'flex', alignItems: 'top', justifyContent: 'center' }}>{content}</Box>}
        </>
      )}
    </Box>
  );
}

Menu03.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
  // description: PropTypes.string, // Removed isRequired to make description optional
  defaultExpand: PropTypes.bool,
};

export default Menu03;
