import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { Box, Typography } from '@mui/material';
import SwitchSetting from '../Common/SwitchSetting';
import TextFieldSetting from '../Common/TextFieldSetting';

function SettingsWallet(props) {
  const { settingsWallet, setSettingsWallet } = useContext(SettingsContext);
  // selectedBlockchain: "Gnosis",
  // selectedWallet: null,
  // selectedWalletHideCoins: false,
  if (!settingsWallet) return <></>;

  const selectedBlockchainChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      selectedBlockchain: newValue,
    }));
  };
  const selectedWalletChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      selectedWallet: newValue,
    }));
  };
  const selectedWalletHideChange = (newValue) => {
    // console.log("newValue", newValue, settingsWallet);
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      selectedWalletHideCoins: newValue,
    }));
  };

  return (
    <Box
      sx={{
        m: 1,
        p: 1,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h4' sx={{ my: 1, px: 2, color: 'primary.main' }}>
        Selected Wallet configured in Header Menu
      </Typography>

      <Box
        sx={{
          mx: 1,
          p: 0,
          border: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          justifyContent: 'center',
        }}
      >
        <TextFieldSetting
          value={settingsWallet.selectedBlockchain}
          onChange={(newValue) => selectedBlockchainChange(newValue)}
          disabled={true}
          label='Blochain Name'
          title='Selected Wallet Blockchain used by the holder'
        />
        <TextFieldSetting
          value={
            settingsWallet.selectedWallet ? settingsWallet.selectedWallet.slice(0, 6) + '...' + settingsWallet.selectedWallet.slice(-4) : ''
          }
          onChange={(newValue) => selectedWalletChange(newValue)}
          disabled={true}
          label='Wallet address'
          title='Selected Wallet Addresse used by the holder'
        />
        <SwitchSetting
          value={settingsWallet.selectedWalletHideCoins}
          onChange={(newValue) => selectedWalletHideChange(newValue)}
          title='Hide/Show tokens in the selected wallet without balance'
        />
      </Box>
    </Box>
  );
}

export default SettingsWallet;
