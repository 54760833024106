import { useTheme } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { MyThemeContext } from '../../../context/theme-context';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import RadioSettingRentStartMarket from '../../Settings/Common/RadioSettingRentStartMarket';
import TextFieldMarket from '../../Settings/Common/TextFieldMarket';
import SalesOffersBody from './SalesOffersBody';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function SalesPersoOffersHead({ offers, cityList, coinsList }) {
  const { settingsMarket, setSettingsMarket, DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [selectedFullName, setSelectedFullName] = useState(settingsMarket.SaleOffers.selectedFullName || '');
  const [selectedFullNameMemo, setSelectedFullNameMemo] = useState(settingsMarket.SaleOffers.selectedFullName || '');
  const [filter, setFilter] = useState(false);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState([]);
  const [anchorElCities, setAnchorElCities] = useState(null);
  const [anchorElCoins, setAnchorElCoins] = useState(null);
  const [anchorElPropertyType, setAnchorElPropertyType] = useState(null);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  const [sortBy, setSortBy] = useState(settingsMarket.SaleOffers.sortBy || { field: 'token_newYield', order: 'desc', type: 'number' });
  const theme = useTheme();

  const handleClickCities = (event) => {
    setAnchorElCities(event.currentTarget);
  };

  const handleCloseCities = () => {
    setAnchorElCities(null);
  };

  const handleClickCoins = (event) => {
    setAnchorElCoins(event.currentTarget);
  };

  const handleCloseCoins = () => {
    setAnchorElCoins(null);
  };

  const handleClickPropertyType = (event) => {
    setAnchorElPropertyType(event.currentTarget);
  };

  const handleClosePropertyType = () => {
    setAnchorElPropertyType(null);
  };

  const handleSort = (field, type) => {
    setSortBy((prevSortBy) => ({
      field,
      order: prevSortBy.field === field && prevSortBy.order === 'desc' ? 'asc' : 'desc',
      type,
    }));
  };

  useEffect(() => {
    setSettingsMarket((prevSettings) => ({
      ...prevSettings,
      SaleOffers: {
        ...prevSettings.SaleOffers,
        selectedFullName: selectedFullName,
      },
    }));
  }, [selectedFullName]);

  useEffect(() => {
    let types = [];

    function areArraysEqual(array1, array2) {
      if (array1.length !== array2.length) return false;
      return array1.every((element, index) => JSON.stringify(element) === JSON.stringify(array2[index]));
    }

    let updatedSettings = { ...settingsMarket };
    if (
      !('propertyType' in settingsMarket) ||
      !areArraysEqual(
        settingsMarket.propertyType,
        DEFAULT_PROPERTY_TYPE.slice(1).filter((p) => !p.label.startsWith('Text_'))
      )
    ) {
      updatedSettings.propertyType = DEFAULT_PROPERTY_TYPE.slice(1).filter((p) => !p.label.startsWith('Text_'));
      updatedSettings.SaleOffers = { ...updatedSettings.SaleOffers, unselectPropertyType: [] };
      updatedSettings.SaleOffers = { ...updatedSettings.SaleOffers, unselectPropertyType: [] };
      updatedSettings.WalletSaleOffers = { ...updatedSettings.WalletSaleOffers, unselectPropertyType: [] };
      updatedSettings.WalletSaleOffers = { ...updatedSettings.WalletSaleOffers, unselectPropertyType: [] };
    }
    types = JSON.parse(JSON.stringify(updatedSettings.propertyType));

    // Initialisation des paramètres de SaleOffers
    if (!(settingsMarket.SaleOffers && 'isOfferAmount' in settingsMarket.SaleOffers)) {
      updatedSettings.SaleOffers = {
        ...updatedSettings.SaleOffers,
        isOfferAmount: true,
        isOfferAmountMenu: false,
        minOfferAmount: 0.01,
        maxOfferAmount: 9999,
      };
    }

    if ('SwapCatOffersList' in updatedSettings) {
      console.log('delete SwapCatOffersList');
      const { SwapCatOffersList, ...rest } = updatedSettings;
      updatedSettings = rest;
    }

    if ('YamOffersList' in updatedSettings) {
      console.log('delete YamOffersList');
      const { YamOffersList, ...rest } = updatedSettings;
      updatedSettings = rest;
    }

    if (settingsMarket.SaleOffers && settingsMarket.SaleOffers.unselectPropertyType) {
      // console.log("unselectPropertyType",settingsMarket.SaleOffers.unselectPropertyType,types);
      types.forEach((type) => {
        type.checked = true;
        if (settingsMarket.SaleOffers.unselectPropertyType.includes(type.index)) {
          type.checked = false;
        }
        return type;
      });
      setSelectedPropertyType(types);
    }
    if (!updatedSettings.SaleOffers.unselectPropertyType) {
      types.forEach((type) => {
        type.checked = true;
        return type;
      });
      setSelectedPropertyType(types);
    }

    // Configuration des villes sélectionnées
    const cities = cityList.map((city) => ({
      ...city,
      checked: !(settingsMarket.SaleOffers?.unselectedCity || []).includes(city.label),
    }));

    // Configuration des pièces sélectionnées
    const coins = coinsList.map((coin) => ({
      ...coin,
      checked: !(settingsMarket.SaleOffers?.unselectedCoins || []).includes(coin.label),
    }));

    // Mise à jour unique de l'état

    if (JSON.stringify(settingsMarket) !== JSON.stringify(updatedSettings)) setSettingsMarket(updatedSettings);
    if (JSON.stringify(selectedCity) !== JSON.stringify(cities)) setSelectedCity(cities);
    if (JSON.stringify(selectedCoins) !== JSON.stringify(coins)) setSelectedCoins(coins);
  }, [cityList, coinsList, settingsMarket]);

  const handleSelectAllCities = (checked) => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    if (checked) {
      settings.SaleOffers.unselectedCity = [];
      handleCloseCities(null);
    } else {
      settings.SaleOffers.unselectedCity = [];
      selectedCity.forEach((city) => {
        settings.SaleOffers.unselectedCity = [...(settings.SaleOffers.unselectedCity || []), city.label];
      });
    }
    setSettingsMarket(settings);
  };

  const handleCityChange = (unselectCity) => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    if (!unselectCity.checked && settings.SaleOffers.unselectedCity) {
      settings.SaleOffers.unselectedCity = settings.SaleOffers.unselectedCity.filter((city) => city !== unselectCity.label);
    }
    if (unselectCity.checked || !settings.SaleOffers.unselectedCity) {
      settings.SaleOffers.unselectedCity = [...(settings.SaleOffers.unselectedCity || []), unselectCity.label];
    }
    setSettingsMarket(settings);
  };

  const handleSelectAllCoins = (checked) => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    if (checked) {
      settings.SaleOffers.unselectedCoins = [];
      handleCloseCoins(null);
    } else {
      settings.SaleOffers.unselectedCoins = [];
      selectedCoins.forEach((coin) => {
        settings.SaleOffers.unselectedCoins = [...(settings.SaleOffers.unselectedCoins || []), coin.label];
      });
    }
    setSettingsMarket(settings);
  };

  const handleCoinsChange = (unselectCoin) => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    if (!unselectCoin.checked && settings.SaleOffers.unselectedCoins) {
      settings.SaleOffers.unselectedCoins = settings.SaleOffers.unselectedCoins.filter((coin) => coin !== unselectCoin.label);
    }
    if (unselectCoin.checked || !settings.SaleOffers.unselectedCoins) {
      settings.SaleOffers.unselectedCoins = [...(settings.SaleOffers.unselectedCoins || []), unselectCoin.label];
    }
    setSettingsMarket(settings);
  };

  const handleSelectAllPropertyType = (checked) => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    if (checked) {
      settings.SaleOffers.unselectPropertyType = [];
      handleClosePropertyType(null);
    } else {
      settings.SaleOffers.unselectPropertyType = [];
      selectedPropertyType.forEach((type) => {
        settings.SaleOffers.unselectPropertyType = [...(settings.SaleOffers.unselectPropertyType || []), type.index];
      });
    }
    setSettingsMarket(settings);
  };

  const handlePropertyTypeChange = (unselectPropertyType) => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    if (!unselectPropertyType.checked && settings.SaleOffers.unselectPropertyType) {
      settings.SaleOffers.unselectPropertyType = settings.SaleOffers.unselectPropertyType.filter(
        (type) => type !== unselectPropertyType.index
      );
    }
    if (unselectPropertyType.checked || !settings.SaleOffers.unselectPropertyType) {
      settings.SaleOffers.unselectPropertyType = [...(settings.SaleOffers.unselectPropertyType || []), unselectPropertyType.index];
    }
    setSettingsMarket(settings);
  };

  // console.log("SalesOffersHead", "sortedOffers", sortedOffers[2]);

  let offersList = offers ? [...offers] : [];
  if (selectedFullName)
    offersList = offers.filter(
      (data) =>
        data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()) ||
        data.holder_address.toLowerCase().includes(selectedFullName.toLowerCase())
    );

  const sortedOffers = [...offersList].sort((a, b) => {
    const order = sortBy.order === 'asc' ? 1 : -1;
    if (sortBy.type === 'number') return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
    if (sortBy.type === 'text') return order * a[sortBy.field].localeCompare(b[sortBy.field]);
    else return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(() => settingsMarket.SaleOffers.perso_rowsPerPage || 15);

  useEffect(() => {
    let settings = JSON.parse(JSON.stringify(settingsMarket));
    settings.SaleOffers.sortBy = sortBy;
    setSettingsMarket(settings);
  }, [sortBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);

    setSettingsMarket((prevSettings) => ({
      ...prevSettings,
      SaleOffers: {
        ...prevSettings.PurchaseOffers,
        perso_rowsPerPage: event.target.value,
      },
    }));
  };

  if (!offers) return;
  <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
      <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
        Waiting Offers synchronisation ...
      </Typography>
    </Box>
  </Paper>;
  if (sortedOffers.length / rowsPerPage < page && page > 0) handleChangePage(page, page - 1);

  // console.log("offers", offers[0]);

  const filterActive =
    settingsMarket.SaleOffers.isPosition ||
    settingsMarket.SaleOffers.isOfferAmount ||
    settingsMarket.SaleOffers.isRatioPrice ||
    settingsMarket.SaleOffers.isRealtPrice ||
    settingsMarket.SaleOffers.isRealtYield ||
    settingsMarket.SaleOffers.isNewPrice ||
    settingsMarket.SaleOffers.isNewYield ||
    settingsMarket.SaleOffers.isRented ||
    settingsMarket.SaleOffers.isLastPricingDays ||
    settingsMarket.SaleOffers.isRentStart ||
    settingsMarket.SaleOffers.isnotRentStart;

  return (
    <>
      <Box
        sx={{
          mb: 2,
          width: 1,
          border: 0,
          borderColor: 'primary.main',
          borderRadius: 4,
        }}
      >
        <Box sx={{ width: 'auto', my: 0, mx: 0, border: 0, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          {/*
					<Box>
            <Tooltip title={filter ? 'Hide Filter Settings' : 'Show Filter Settings'}>
							<FilterAltIcon
								sx={{ ml: 0, fontSize: 40 }}
								color={filterActive ? "secondary" : filter ? "primary" : "disabled"}
								onClick={() => {
									setFilter(!filter);
								}}
							/>
						</Tooltip>
					</Box> 
					*/}

          <Box sx={{ width: 0.75, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='h5' sx={{ m: 0, color: 'inherit' }}>
              Private holder Offer(s)
            </Typography>
          </Box>

          {isMatch ? (
            <TablePagination
              sx={{
                ml: 'auto',
                borderRadius: 2,
                '.MuiTablePagination-toolbar': {
                  minHeight: 'auto',
                  padding: '0 0px',
                },
                '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                  display: 'none',
                },
                '.MuiTablePagination-select, .MuiTablePagination-selectIcon': {
                  height: 'auto',
                  padding: '0 0px',
                },
                '.MuiTablePagination-actions': {
                  marginLeft: '0px',
                },
              }}
              rowsPerPageOptions={[5, 10, 15, 20]}
              component='div'
              count={sortedOffers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <TablePagination
              sx={{ ml: 'auto', borderRadius: 2 }}
              rowsPerPageOptions={[5, 10, 15, 20]}
              component='div'
              count={sortedOffers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Box>

        <Paper elevation={3} sx={{ m: 0, width: 1, overflow: 'hidden' }}>
          <TableContainer sx={{ mt: 0 }}>
            <Table sx={{ background: 'background.paper' }} aria-label='sticky table'>
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                    '&:last-child th': { borderBottom: 0 },
                  }}
                >
                  <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1, maxWidth: 60 }} colSpan={3}>
                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                      RealT
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('fullName', 'text')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Token Name
                      </Typography>
                      {sortBy.field === 'fullName' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }} onClick={() => handleSort('city', 'text')}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        City
                      </Typography>
                      {sortBy.field === 'city' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  {settingsMarket.SaleOffers.showPosition && (
                    <TableCell
                      sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                      onClick={() => handleSort('position', 'number')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <FilterAltIcon
                          sx={{ mr: 0.5, fontSize: 22 }}
                          color={settingsMarket.SaleOffers.isPosition ? 'secondary' : 'disabled'}
                          onClick={(e) => {
                            e.stopPropagation();
                            let settings = JSON.parse(JSON.stringify(settingsMarket));
                            settings.SaleOffers.isPosition = !settingsMarket.SaleOffers.isPosition;
                            setSettingsMarket(settings);
                          }}
                        />
                        <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                          Owned
                        </Typography>
                        {sortBy.field === 'position' &&
                          (sortBy.order === 'asc' ? (
                            <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                          ) : (
                            <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                          ))}
                      </Box>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleSort('tokenPrice', 'number')}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <FilterAltIcon
                        sx={{ mr: 0.5, fontSize: 22 }}
                        color={settingsMarket.SaleOffers.isRealtPrice ? 'secondary' : 'disabled'}
                        onClick={(e) => {
                          e.stopPropagation();
                          let settings = JSON.parse(JSON.stringify(settingsMarket));
                          settings.SaleOffers.isRealtPrice = !settingsMarket.SaleOffers.isRealtPrice;
                          setSettingsMarket(settings);
                        }}
                      />
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        RealT Price
                      </Typography>
                      {sortBy.field === 'tokenPrice' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleSort('annualPercentageYield', 'number')}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <FilterAltIcon
                        sx={{ mr: 0.5, fontSize: 22 }}
                        color={settingsMarket.SaleOffers.isRealtYield ? 'secondary' : 'disabled'}
                        onClick={(e) => {
                          e.stopPropagation();
                          let settings = JSON.parse(JSON.stringify(settingsMarket));
                          settings.SaleOffers.isRealtYield = !settingsMarket.SaleOffers.isRealtYield;
                          setSettingsMarket(settings);
                        }}
                      />
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        RealT Yield
                      </Typography>
                      {sortBy.field === 'annualPercentageYield' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    // onClick={() => handleSort("token_amount", "number")}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Offer Token
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleSort('token_amount', 'number')}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <FilterAltIcon
                        sx={{ mr: 0.5, fontSize: 22 }}
                        color={settingsMarket.SaleOffers.isOfferAmount ? 'secondary' : 'disabled'}
                        onClick={(e) => {
                          e.stopPropagation();
                          let settings = JSON.parse(JSON.stringify(settingsMarket));
                          settings.SaleOffers.isOfferAmount = !settingsMarket.SaleOffers.isOfferAmount;
                          setSettingsMarket(settings);
                        }}
                      />
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Offer Quantity
                      </Typography>

                      {sortBy.field === 'token_amount' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleSort('token_value_usd', 'number')}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <FilterAltIcon
                        sx={{ mr: 0.5, fontSize: 22 }}
                        color={settingsMarket.SaleOffers.isNewPrice ? 'secondary' : 'disabled'}
                        onClick={(e) => {
                          e.stopPropagation();
                          let settings = JSON.parse(JSON.stringify(settingsMarket));
                          settings.SaleOffers.isNewPrice = !settingsMarket.SaleOffers.isNewPrice;
                          setSettingsMarket(settings);
                        }}
                      />
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Offer Price
                      </Typography>
                      {sortBy.field === 'token_value_usd' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleSort('token_newYield', 'number')}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <FilterAltIcon
                        sx={{ mr: 0.5, fontSize: 22 }}
                        color={settingsMarket.SaleOffers.isNewYield ? 'secondary' : 'disabled'}
                        onClick={(e) => {
                          e.stopPropagation();
                          let settings = JSON.parse(JSON.stringify(settingsMarket));
                          settings.SaleOffers.isNewYield = !settingsMarket.SaleOffers.isNewYield;
                          setSettingsMarket(settings);
                        }}
                      />
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        New Yield
                      </Typography>
                      {sortBy.field === 'token_newYield' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  <TableCell
                    sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                    onClick={() => handleSort('token_ratio', 'number')}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                      <FilterAltIcon
                        sx={{ mr: 0.5, fontSize: 22 }}
                        color={settingsMarket.SaleOffers.isRatioPrice ? 'secondary' : 'disabled'}
                        onClick={(e) => {
                          e.stopPropagation();
                          let settings = JSON.parse(JSON.stringify(settingsMarket));
                          settings.SaleOffers.isRatioPrice = !settingsMarket.SaleOffers.isRatioPrice;
                          setSettingsMarket(settings);
                        }}
                      />
                      <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                        Ratio Price
                      </Typography>
                      {sortBy.field === 'token_ratio' &&
                        (sortBy.order === 'asc' ? (
                          <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                        ))}
                    </Box>
                  </TableCell>

                  {settingsMarket.SaleOffers.showRented && (
                    <TableCell
                      sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                      onClick={() => handleSort('token_rented', 'number')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <FilterAltIcon
                          sx={{ mr: 0.5, fontSize: 22 }}
                          color={settingsMarket.SaleOffers.isRented ? 'secondary' : 'disabled'}
                          onClick={(e) => {
                            e.stopPropagation();
                            let settings = JSON.parse(JSON.stringify(settingsMarket));
                            settings.SaleOffers.isRented = !settingsMarket.SaleOffers.isRented;
                            setSettingsMarket(settings);
                          }}
                        />
                        <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                          Rented
                        </Typography>
                        {sortBy.field === 'token_rented' &&
                          (sortBy.order === 'asc' ? (
                            <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                          ) : (
                            <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                          ))}
                      </Box>
                    </TableCell>
                  )}

                  {settingsMarket.SaleOffers.showLastPricingDays && (
                    <>
                      <TableCell
                        sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}
                        onClick={() => handleSort('last_pricing_days', 'number')}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                          <FilterAltIcon
                            sx={{ mr: 0.5, fontSize: 22 }}
                            color={settingsMarket.SaleOffers.isLastPricingDays ? 'secondary' : 'disabled'}
                            onClick={(e) => {
                              e.stopPropagation();
                              let settings = JSON.parse(JSON.stringify(settingsMarket));
                              settings.SaleOffers.isLastPricingDays = !settingsMarket.SaleOffers.isLastPricingDays;
                              setSettingsMarket(settings);
                            }}
                          />
                          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                            Last Pricing
                          </Typography>
                          {sortBy.field === 'last_pricing_days' &&
                            (sortBy.order === 'asc' ? (
                              <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                            ) : (
                              <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                            ))}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ pl: 1, pr: settingsMarket.SaleOffers.showRentStart ? 0 : 1, py: 1, '&:hover': { cursor: 'pointer' } }}
                        onClick={() => handleSort('nb_pricing', 'number')}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                          <FilterAltIcon
                            sx={{ mr: 0.5, fontSize: 22 }}
                            color={settingsMarket.SaleOffers.isNbPricing ? 'secondary' : 'disabled'}
                            onClick={(e) => {
                              e.stopPropagation();
                              let settings = JSON.parse(JSON.stringify(settingsMarket));
                              settings.SaleOffers.isNbPricing = !settingsMarket.SaleOffers.isNbPricing;
                              setSettingsMarket(settings);
                            }}
                          />
                          <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                            Nb Pricing
                          </Typography>
                          {sortBy.field === 'nb_pricing' &&
                            (sortBy.order === 'asc' ? (
                              <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                            ) : (
                              <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                            ))}
                        </Box>
                      </TableCell>
                    </>
                  )}

                  {settingsMarket.SaleOffers.showRentStart && (
                    <TableCell
                      sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' } }}
                      onClick={() => handleSort('rent_start', 'text')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                        <FilterAltIcon
                          sx={{ mr: 0.5, fontSize: 22 }}
                          color={
                            settingsMarket.SaleOffers.isRentStart || settingsMarket.SaleOffers.isnotRentStart
                              ? settingsMarket.SaleOffers.autoRentStart
                                ? 'secondary'
                                : 'warning'
                              : 'disabled'
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            let settings = JSON.parse(JSON.stringify(settingsMarket));
                            if (settingsMarket.SaleOffers.isRentStart || settingsMarket.SaleOffers.isnotRentStart) {
                              settings.SaleOffers.isRentStart = false;
                              settings.SaleOffers.isnotRentStart = false;
                            } else {
                              settings.SaleOffers.isRentStart = true;
                              settings.SaleOffers.isnotRentStart = false;
                            }
                            setSettingsMarket(settings);
                          }}
                        />
                        <Typography variant='body2' style={{ fontWeight: 'bold' }}>
                          Rent Start
                        </Typography>
                        {sortBy.field === 'rent_start' &&
                          (sortBy.order === 'asc' ? (
                            <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                          ) : (
                            <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                          ))}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              {filter && (
                <TableHead>
                  <TableRow
                    sx={{
                      borderBottom: `2px solid ${theme.palette.primary.main}`,
                      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                      '&:last-child th': { borderBottom: 0 },
                    }}
                  >
                    <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }}></TableCell>
                    <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }}></TableCell>
                    <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }}></TableCell>
                    <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }}></TableCell>
                    <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1 }}></TableCell>

                    {/* Holder Position */}
                    {settingsMarket.SaleOffers.showPosition && (
                      <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                        <TextFieldMarket
                          min={0}
                          max={9999}
                          step={0.1}
                          nameSettings={'Position'}
                          levelSettings={'SaleOffers'}
                          settings={settingsMarket}
                          setSettings={setSettingsMarket}
                        />
                      </TableCell>
                    )}

                    {/* RealT Price */}
                    <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                      <TextFieldMarket
                        min={0}
                        max={999}
                        step={0.1}
                        nameSettings={'RealtPrice'}
                        levelSettings={'SaleOffers'}
                        settings={settingsMarket}
                        setSettings={setSettingsMarket}
                      />
                    </TableCell>

                    {/* RealT Yield */}
                    <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                      <TextFieldMarket
                        min={0}
                        max={100}
                        step={0.1}
                        nameSettings={'RealtYield'}
                        levelSettings={'SaleOffers'}
                        settings={settingsMarket}
                        setSettings={setSettingsMarket}
                      />
                    </TableCell>

                    {/* Offer Token */}
                    <TableCell sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' } }}></TableCell>

                    {/* Offer Quantity */}
                    <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                      <TextFieldMarket
                        min={0}
                        max={9999}
                        step={0.1}
                        nameSettings={'OfferAmount'}
                        levelSettings={'SaleOffers'}
                        settings={settingsMarket}
                        setSettings={setSettingsMarket}
                      />
                    </TableCell>

                    {/* Offer Price */}
                    <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                      <TextFieldMarket
                        min={0}
                        max={9999}
                        step={0.1}
                        nameSettings={'NewPrice'}
                        levelSettings={'SaleOffers'}
                        settings={settingsMarket}
                        setSettings={setSettingsMarket}
                      />
                    </TableCell>

                    {/* New Yield */}
                    <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                      <TextFieldMarket
                        min={0}
                        max={9999}
                        step={0.1}
                        nameSettings={'NewYield'}
                        levelSettings={'SaleOffers'}
                        settings={settingsMarket}
                        setSettings={setSettingsMarket}
                      />
                    </TableCell>

                    {/* Ratio Price */}
                    <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                      <TextFieldMarket
                        min={0}
                        max={999}
                        step={0.1}
                        nameSettings={'RatioPrice'}
                        levelSettings={'SaleOffers'}
                        settings={settingsMarket}
                        setSettings={setSettingsMarket}
                      />
                    </TableCell>

                    {/* Rented */}
                    {settingsMarket.SaleOffers.showRented && (
                      <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                        <TextFieldMarket
                          min={0}
                          max={100}
                          step={0.1}
                          nameSettings={'Rented'}
                          levelSettings={'SaleOffers'}
                          settings={settingsMarket}
                          setSettings={setSettingsMarket}
                        />
                      </TableCell>
                    )}

                    {/* Last Pricing */}
                    {settingsMarket.SaleOffers.showLastPricingDays && (
                      <>
                        <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                          <TextFieldMarket
                            min={0}
                            max={9999}
                            step={1}
                            nameSettings={'LastPricingDays'}
                            levelSettings={'SaleOffers'}
                            settings={settingsMarket}
                            setSettings={setSettingsMarket}
                          />
                        </TableCell>

                        <TableCell
                          align='right'
                          sx={{
                            pl: 1,
                            pr: settingsMarket.SaleOffers.showRentStart ? 0 : 1,
                            py: 1,
                            '&:hover': { cursor: 'pointer' },
                            verticalAlign: 'top',
                          }}
                        >
                          <TextFieldMarket
                            min={0}
                            max={100}
                            step={0}
                            nameSettings={'NbPricing'}
                            levelSettings={'SaleOffers'}
                            settings={settingsMarket}
                            setSettings={setSettingsMarket}
                          />
                        </TableCell>
                      </>
                    )}

                    {/* Rent Start */}
                    {settingsMarket.SaleOffers.showRentStart && (
                      <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1, '&:hover': { cursor: 'pointer' }, verticalAlign: 'top' }}>
                        <RadioSettingRentStartMarket
                          nameSettings={'RentStart'}
                          levelSettings={'SaleOffers'}
                          settings={settingsMarket}
                          setSettings={setSettingsMarket}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
              )}

              <TableBody sx={{ p: 0 }}>
                {sortedOffers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((offer, index) => (
                  <SalesOffersBody key={index} offer={offer} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}

export default SalesPersoOffersHead;
