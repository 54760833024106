import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { RealTokensContext } from '../../../context/realTokens-context';
import { SnackbarContext } from '../../../context/customsnackbar-context';

import { Box } from '@mui/system';
import { Button, Checkbox, IconButton, Paper, Popper, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import InsightsIcon from '@mui/icons-material/Insights';
import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import SavingsIcon from '@mui/icons-material/Savings';
import BusinessIcon from '@mui/icons-material/Business';

function SelectHolderWallet() {
  const { openSnackbar } = useContext(SnackbarContext);
  const { holderWallets, setHolderWallets, settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);
  const { refresh_holderWallets } = useContext(RealTokensContext);
  const settings = { ...settingsDashboard };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [countdown, setCountdown] = useState(15);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    let timer;
    let interval;
    let timeout = 15;
    if (open) {
      setCountdown(timeout);
      interval = setInterval(() => {
        setCountdown((currentCountdown) => currentCountdown - 1);
      }, 1000);
      timer = setTimeout(() => {
        setAnchorEl(null);
      }, timeout * 1000);
    }
    return () => {
      if (timer) clearTimeout(timer);
      if (interval) clearInterval(interval);
    };
  }, [open]);

  if (!holderWallets || holderWallets.length === 0) return <></>;
  let nbSelectedWallets = holderWallets.filter((w) => w.checked).length;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    // setAnchorEl(anchorEl ? null : document.head);
    // document.body
  };

  const toogleHolderWallet = (wallet) => {
    const filtered = holderWallets.filter((w) => w.address !== wallet.wallet.address);
    const selected = holderWallets.filter((w) => w.address === wallet.wallet.address);
    selected[0].checked = !selected[0].checked;
    if (!filtered) setHolderWallets(selected);
    else setHolderWallets(filtered.concat(selected));
    // SnackbarMessage("You remove the wallet address : " + wallet.wallet, "success");
  };

  return (
    <Box sx={{ position: 'relative', zIndex: 10, width: 1, height: 'auto' }}>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 10,
          p: 1,
          py: 0,
          width: 1,
          height: '44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Tooltip title='Select active wallets'>
          <Button sx={{ m: 0, px: 1 }} onClick={handleClick} variant={open ? 'contained' : 'text'} color={open ? 'secondary' : 'primary'}>
            Holder Wallets ({nbSelectedWallets})
          </Button>
        </Tooltip>

        <Tooltip title={settings.isUsingRMM ? 'Exclude RMM v3 Assets' : 'Include RMM v3 Assets'}>
          <SavingsIcon
            sx={{ ml: 'auto', color: settings.isUsingRMM ? 'primary.main' : 'lightgrey' }}
            onClick={() => {
              settings.isUsingRMM = !settings.isUsingRMM;
              setSettingsDashboard(settings);
            }}
          />
        </Tooltip>

        <Tooltip title={settings.isUsingRWA ? 'Exclude RWA Asset' : 'Include RWA Asset'}>
          <BusinessIcon
            sx={{ ml: 3, color: settings.isUsingRWA ? 'primary.main' : 'lightgrey' }}
            onClick={() => {
              settings.isUsingRWA = !settings.isUsingRWA;
              setSettingsDashboard(settings);
            }}
          />
        </Tooltip>

        <Tooltip title={settings.isExpandedInsight ? 'Hide Insight' : 'Show Insight'}>
          <InsightsIcon
            sx={{ ml: 3, color: settings.isExpandedInsight ? 'primary.main' : 'lightgrey' }}
            onClick={() => {
              settings.isExpandedInsight = !settings.isExpandedInsight;
              setSettingsDashboard(settings);
            }}
          />
        </Tooltip>

        <Tooltip title={settings.isExpandedCharts ? 'Hide Charts' : 'Show Charts'}>
          <AnalyticsTwoToneIcon
            sx={{ ml: 3, color: settings.isExpandedCharts ? 'primary.main' : 'lightgrey' }}
            onClick={() => {
              settings.isExpandedCharts = !settings.isExpandedCharts;
              setSettingsDashboard(settings);
            }}
          />
        </Tooltip>

        <Tooltip title='Refresh Holder Wallets'>
          <RefreshIcon
            sx={{ ml: 3, color: 'primary.main' }}
            onClick={() => {
              openSnackbar('You start the refresh for holder Wallets', 'success');
              refresh_holderWallets();
            }}
          />
        </Tooltip>

        <Popper id={id} open={open} anchorEl={anchorEl} sx={{ p: 1 }}>
          <Paper elevation={20} sx={{ m: 1, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
            <Box sx={{ px: 1, py: 0.5 }}>
              <Box sx={{ px: 1, display: 'flew', flexDirection: 'row', alignItems: 'start' }}>
                <Typography variant='body1' sx={{ mt: 1, mr: 'auto', color: 'primary.main', fontWeight: 'bold' }}>
                  Select active wallets :
                </Typography>
                <Typography variant='p' sx={{ ml: 'auto', color: 'secondary.main', fontSize: '0.75rem' }}>
                  {countdown}s
                </Typography>
              </Box>
              {holderWallets
                .sort((a, b) => a.address - b.address)
                .map((wallet) => (
                  <Box
                    key={wallet.address}
                    sx={{
                      m: 0,
                      mx: 1,
                      p: 0,
                      minWidth: 225,
                      border: 0,
                      borderColor: 'primary.main',
                      borderRadius: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Checkbox checked={wallet.checked} sx={{ m: 0, mr: 2, padding: 0.5 }} onClick={() => toogleHolderWallet({ wallet })} />
                    <Typography variant='body2' sx={{ mr: 'auto', color: 'primary.main' }}>
                      {wallet.name ? wallet.name : wallet.address.slice(0, 6) + '...' + wallet.address.slice(-4)}
                    </Typography>
                    <IconButton
                      sx={{ width: 6, height: 6 }}
                      variant='contained'
                      onClick={() => {
                        navigator.clipboard.writeText(wallet.address);
                        openSnackbar('Copied : ' + wallet.address, 'success');
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                ))}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </Box>
  );
}

export default SelectHolderWallet;
