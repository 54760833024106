import React, { useContext, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper, TextField, Select, MenuItem } from '@mui/material';
import { BarChart } from '@mui/x-charts';

function BarPropertyYield() {
  const { realTokens, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);
  const [yieldIncrement, setYieldIncrement] = useState(1);

  // console.log("BarPropertyYield",realTokens,settingsStats,DEFAULT_PROPERTY_TYPE)

  if (!realTokens) return <></>;
  if (settingsStats.selectedPropertyType === null) return <></>;
  if (!DEFAULT_PROPERTY_TYPE) return <></>;
  if (realTokens.list.length === 0) return <></>;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];

  if (settingsStats.selectedPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === settingsStats.selectedPropertyType);

  const yieldCounts = tokens.reduce((acc, token) => {
    const yieldCategory = Math.floor(token.annualPercentageYield / yieldIncrement) * yieldIncrement; // Rounds down to the nearest multiple of 2
    const label = `<${yieldCategory + yieldIncrement < 10 ? '0' + (yieldCategory + yieldIncrement) : yieldCategory + yieldIncrement}%`;
    acc[label] = (acc[label] || 0) + 1;
    return acc;
  }, {});

  const data = Object.entries(yieldCounts)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([label, count]) => ({
      value: count,
      label: label,
    }));

  const chartSetting = {
    width: 880,
    height: 300,
  };

  const selectedPropertyTypeChange = (newValue) => {
    setSettingsStats((prevSettings) => ({
      ...prevSettings,
      selectedPropertyType: newValue,
    }));
  };

  // console.log("BarPropertyYield");

  return (
    <Paper elevation={3} sx={{ m: 1, width: 'auto', minWidth: 'auto', border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Property Type
        </Typography>
        <Select
          sx={{ mt: 0, ml: 0, minWidth: 125 }}
          value={settingsStats.selectedPropertyType}
          onChange={(e) => selectedPropertyTypeChange(e.target.value)}
          size='small'
        >
          {DEFAULT_PROPERTY_TYPE.filter((p) => !p.label.startsWith('Text_')).map((p) => (
            <MenuItem key={p.index} value={p.index}>
              {p.label}
            </MenuItem>
          ))}
        </Select>
        <Typography variant='h5' sx={{ m: 1, ml: 10, color: 'primary.main' }}>
          Yield Step
        </Typography>
        <TextField
          sx={{ mt: 0, ml: 0, minWidth: 55 }}
          // label='Yield Step (%)'
          onChange={(e) => setYieldIncrement(parseInt(e.target.value))}
          variant='outlined'
          type='number'
          size='small'
          value={yieldIncrement}
          inputProps={{
            min: 1,
            max: 10,
            step: 1,
            style: {
              textAlign: 'center',
              padding: '5px 6px',
            },
          }}
        />
      </Box>
      <Box
        sx={{
          flexWrap: 'wrap',
          m: 1,
          p: 0,
          border: 0,
          borderColor: 'primary.main',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
          <Typography
            variant='body1'
            sx={{
              width: 30,
              my: 0,
              ml: 0,
              color: 'primary.main',
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
            }}
          >
            Number of Property by Yield
          </Typography>
        </Box>
        <BarChart
          xAxis={[{ scaleType: 'band', data: data.map((d) => d.label) }]}
          series={[{ data: data.map((d) => d.value) }]}
          {...chartSetting}
          margin={{ left: 35, right: 0, top: 10, bottom: 20 }}
        />
      </Box>
    </Paper>
  );
}

export default BarPropertyYield;
