import React, { useContext, useEffect } from 'react';
import { SettingsContext } from '../context/settings-context';

import { Box } from '@mui/material';

import LoadingWebSite from '../components/Common/LoadingWebSite';
import TableTokens from '../components/Upmp/TableTokens';

const TITLE = "Pit's BI - Upmp";

function UPMP() {
  const { settingsLoaded } = useContext(SettingsContext);

  useEffect(() => {
    document.title = TITLE;
  }, []);

  return (
    <Box>
      {!settingsLoaded && <LoadingWebSite />}
      {settingsLoaded && (
        <Box sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <TableTokens />
        </Box>
      )}
    </Box>
  );
}

export default UPMP;
