import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Switch, Typography } from '@mui/material';

const SwitchSetting = ({ value, onChange, title }) => {
  const handleChange = (event) => {
    onChange(event);
  };
  const [localSelect, setLocalSelect] = useState(false);

  const handle_setLocalSelect = (value) => {
    setLocalSelect(!localSelect);
    handleChange(!localSelect);
  };

  useEffect(() => {
    let val = value;
    setLocalSelect(val);
  }, [value]);

  return (
    <Box
      sx={{ mx: 1, p: 0, minWidth: 600, border: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography variant='body2' sx={{ mr: 'auto' }}>
        {title}
      </Typography>

      <Box sx={{ width: 175, border: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Switch checked={localSelect} onChange={handle_setLocalSelect} inputProps={{ 'aria-label': 'controlled' }} />
      </Box>
    </Box>
  );
};

SwitchSetting.propTypes = {
  value: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default SwitchSetting;
