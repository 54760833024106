import React, { useContext, useEffect, useRef, useState } from 'react';
import { MyThemeContext } from '../../context/theme-context';

import { green, red } from '@mui/material/colors';

import { TableCell, Avatar, TableRow, Box, Typography, useMediaQuery } from '@mui/material';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

import FormatedNumberDigit from '../Common/Formater/FormatedNumberDigit';

function CityRow({ city, order }) {
  const { themeContext } = useContext(MyThemeContext);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  // console.log("city", city);
  // const imgSrc = country.flag.replace("https://ipgeolocation.io/static", "/images");

  if (!city) return <></>;

  return (
    <>
      <TableRow key={city.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <FormatedNumberDigit value={order} color={'primary.main'} digit={0} />
        </TableCell>

        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <Typography variant='body2'>{city.name}</Typography>
        </TableCell>

        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <Typography variant='body2'>{city.latitude}</Typography>
        </TableCell>

        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <Typography variant='body2'>{city.longitude}</Typography>
        </TableCell>

        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <Typography variant='body2'>
            <strong>{city.nb_clients}</strong>{' '}
            {city.pitsbi + city.pitswap - city.nb_clients > 0 ? `(${city.pitsbi + city.pitswap - city.nb_clients})` : ''}
          </Typography>
        </TableCell>

        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <Typography variant='body2'>
            <strong>{city.pitsbi}</strong> ({((100 * city.pitsbi) / (city.pitsbi + city.pitswap)).toFixed(2)}
            {'%)'}
          </Typography>
        </TableCell>

        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.86 }}>
          <Typography variant='body2'>
            <strong>{city.pitswap}</strong> ({((100 * city.pitswap) / (city.pitsbi + city.pitswap)).toFixed(2)}
            {'%)'}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CityRow;
