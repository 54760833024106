import React, { useState } from 'react';

import { Slide, Popper } from '@mui/material';

import Donate from './Donate';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function DonatePopper({ open, anchorEl, setAnchorEl }) {
  const [countdown, setCountdown] = useState(15);
  const id = open ? 'simple-popper' : undefined;

  const setOpen = (open) => {
    console.log('La fenetre se ferme toute seule ...');
  };

  if (!open) return <></>;

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} sx={{ p: 1 }}>
      <Donate open={open} setOpen={setOpen} />
    </Popper>
  );
}

export default DonatePopper;
