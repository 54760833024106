import React from 'react';
import { Typography } from '@mui/material';

const FormatedNumberDays = ({ value, color = 'inherit', variant = 'body2', fontWeight = 'normal', digit = 2 }) => {
  // const userLocale = navigator.language; // const userLocales = navigator.languages;
  // console.log("userLocale", userLocale); // console.log(userLocales[0]);

  const formatedNumber = value !== null ? (!isNaN(value) ? (parseInt(10 ** digit * value) / 10 ** digit).toLocaleString() : 'NaN') : 'N/A';

  return (
    <Typography variant={variant} sx={{ color: color }} style={{ fontWeight: fontWeight }}>
      {value >= 0 ? formatedNumber + ' days' : 'Wait 1st Rent'}
    </Typography>
  );
};

export default FormatedNumberDays;
