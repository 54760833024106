import L from 'leaflet';
import 'leaflet.markercluster'; // Import leaflet.markercluster
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css'; // Import Leaflet styles
import React, { useContext, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { SettingsContext } from '../../context/settings-context';

import { Box, Typography } from '@mui/material';

// Custom Hook to add Marker Clusters to the Map
const MarkerClusterGroupComponent = ({ points }) => {
  const map = useMap();

  useEffect(() => {
    // Create marker cluster group
    const markers = L.markerClusterGroup();

    points
      .filter((point) => point.name !== null)
      .forEach((point) => {
        // Create a marker with a custom icon
        if (!point.name) console.log('point', point);
        const marker = L.marker(point.position, { icon: createCustomIcon(point.nb_ips) });

        // Create a container element for React content
        const popupContent = document.createElement('div');

        // Render MUI content into the container element
        // ReactDOM.render(<PopupContent point={point} />, popupContent);
        const root = createRoot(popupContent);
        root.render(<PopupContent point={point} />);

        // Bind the container element to the marker's popup
        marker.bindPopup(popupContent);

        // Add marker to cluster group
        markers.addLayer(marker);
      });

    // Add marker cluster group to map
    map.addLayer(markers);

    // Cleanup function to remove markers when component unmounts
    return () => {
      map.removeLayer(markers);
    };
  }, []);
  // }, [map, points]);

  return null;
};

// Function to create a custom marker icon with the number of IPs
const createCustomIcon = (nb_ips) => {
  return L.divIcon({
    className: 'custom-icon',
    html: `<div style="position: relative; background: red; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; color: white; font-weight: bold;">
             ${nb_ips}
           </div>`,
  });
};

// React component for popup content using MUI components
const PopupContent = ({ point }) => (
  <Box>
    <Typography variant='h4' sx={{ fontWeight: 'bold', fontSize: '1.5em' }}>
      {point.name}
    </Typography>
    <Typography variant='h4' sx={{ fontWeight: 'normal', fontSize: '1.25em' }}>
      {point.country_name}
    </Typography>
    <Typography variant='h4' sx={{ fontStyle: 'italic', fontSize: '1.0em' }}>
      {point.nb_ips} user(s) connected
    </Typography>
  </Box>
);

function VisitorsMapView({ countries }) {
  const { realTokens } = useContext(SettingsContext);
  const [points, setPoints] = useState([]);
  // const [mapCenter, setMapCenter] = useState([48.8566, 2.3522]); // Default center (Paris)
  const [mapCenter, setMapCenter] = useState([0, 0]); // Default center (Paris)

  useEffect(() => {
    if (!countries) return;
    // console.log("countries", countries);

    const iterableCountries = Object.values(countries);

    // Extract GPS points (name, lat, lng)
    const extractedPoints = iterableCountries.flatMap((country) => {
      return Object.values(country.cities).map((city) => ({
        id: city.name, // Using city name as ID
        name: city.name,
        position: [parseFloat(city.latitude), parseFloat(city.longitude)], // Convert coordinates
        nb_ips: city.ips.length, // Number of IPs in the city
        country_name: country.name, // Include the country name
        country_code: country.code, // Include the country code
        city: city,
      }));
    });

    // Update state with extracted points
    setPoints(extractedPoints);
  }, [realTokens, countries]);

  return (
    <>
      {points.length > 0 && (
        <>
          <Box
            sx={{
              m: 0,
              p: 0,
              height: '450px',
              width: '80%', // Takes the full width of the window
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: 1,
            }}
          >
            <MapContainer center={mapCenter} zoom={2} style={{ height: '100%', width: '100%' }}>
              {/* TileLayer to display the map */}
              <TileLayer
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />

              {/* Marker Cluster Group Component to group markers dynamically based on zoom level */}
              <MarkerClusterGroupComponent points={points} />
            </MapContainer>
          </Box>
        </>
      )}
    </>
  );
}

export default VisitorsMapView;
