import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../context/settings-context';
import 'leaflet/dist/leaflet.css'; // Importer les styles de Leaflet
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import LoadingWebSite from '../components/Common/LoadingWebSite';
import Header from '../components/Header/Header';

import L from 'leaflet';
import { Box } from '@mui/material';
// import WalletsMapView from "../components/Map/WalletsMapView";
import MenuMapView from '../components/Map/MenuMapView';
import { MapContainer, TileLayer } from 'react-leaflet';

// Définir l'icône de style goutte d'eau
const customIcon = L.icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/512/252/252025.png', // URL de l'icône goutte d'eau (modifiable)
  iconSize: [32, 32], // Taille de l'icône
  iconAnchor: [16, 32], // Point d'ancrage de l'icône (au bas de l'icône)
  popupAnchor: [0, -32], // Position du popup par rapport à l'icône
});

const TITLE = "Pit's BI - Map";

function Map({ defaultPropertyType = 0 }) {
  const { settingsLoaded, balanceWallets, holderWallets } = useContext(SettingsContext);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    document.title = TITLE;
  }, []);

  return (
    <Box>
      <Header />
      {!settingsLoaded && <LoadingWebSite />}

      {settingsLoaded && <MenuMapView />}
    </Box>
  );
}

export default Map;
