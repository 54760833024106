import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Enregistrer les éléments nécessaires pour le graphique en secteurs
ChartJS.register(ArcElement, Tooltip, Legend);

function PieYearHolderCost() {
  const { realTokens } = useContext(SettingsContext);
  const [chartData, setChartData] = useState(null);

  // Accéder au thème MUI
  const theme = useTheme();

  useEffect(() => {
    if (realTokens) {
      let tokens = [
        ...realTokens.list.filter(
          (data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'
        ),
      ];

      const propertyManagement = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyManagement || 0), 0));
      const propertyMaintenanceMonthly = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyMaintenanceMonthly || 0), 0));
      const realtPlatform = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.realtPlatform || 0), 0));
      // const miscellaneousCosts = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.miscellaneousCosts || 0), 0));
      const propertyTaxes = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyTaxes || 0), 0));
      const insurance = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.insurance || 0), 0));
      const utilities = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.utilities || 0), 0));
      const total = (propertyManagement + propertyMaintenanceMonthly + realtPlatform + utilities + propertyTaxes + insurance) / 100;
      // const total = 1000000

      let data = [
        { value: propertyTaxes / total, label: 'Property Taxes' },
        { value: propertyManagement / total, label: 'Property Management' },
        { value: propertyMaintenanceMonthly / total, label: 'Property Maintenance' },
        { value: utilities / total, label: 'Utilities Cost' },
        { value: insurance / total, label: 'Insurances Cost' },
        { value: realtPlatform / total, label: 'RealT Rent Distribution' },
        // { value: miscellaneousCosts / total, label: "Miscellaneous Costs" },
      ];
      data = data.sort((a, b) => b.value - a.value);

      const chartData = {
        labels: data.map((d) => d.label + ` (${((total * d.value) / 1000000).toFixed(2)}M$)`),
        datasets: [
          {
            data: data.map((d) => d.value),
            backgroundColor: data.map((_, i) => `hsl(${(i * 360) / data.length},  100%, ${theme.palette.mode === 'dark' ? '30%' : '50%'})`),
          },
        ],
      };

      setChartData(chartData);
    }
  }, [realTokens, theme.palette.mode]);
  // console.log("data", data);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Permet de contrôler directement la taille
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            console.log(context);
            // return `${context.label}: ${context.raw.toFixed(1)}%`;
            return `Percentage: ${context.raw.toFixed(1)}%`;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 0, // Supprime l'espace au-dessus
        bottom: 0, // Supprime l'espace en bas
      },
    },
    // cutout: "70%", // Transforme le camembert en anneau (doughnut) en définissant la taille du centre vide
  };

  if (!chartData) return null;
  return (
    <Paper elevation={3} sx={{ m: 1, width: '35%', minWidth: 300, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Holder Cost Ratio
        </Typography>
      </Box>

      <Box sx={{ p: 1, display: 'flex', justifyContent: 'center', m: 0 }}>
        <Pie data={chartData} options={options} width={600} height={300} />
      </Box>
    </Paper>
  );
}

export default PieYearHolderCost;
