import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Autocomplete, Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';

function TablePropertyVerifyCompanies() {
  const { realTokens, DEFAULT_PROPERTY_TYPE, settingsStats, setSettingsStats } = useContext(SettingsContext);
  const { balanceWallets, holderWallets } = useContext(SettingsContext);
  const [tokens, setTokens] = useState(null);
  const [compagnies, setCompagnies] = useState(null);
  const [compagniesListed, setCompagniesListed] = useState([]);
  const [compagniesUnlisted, setCompagniesUnlisted] = useState([]);

  const [selectedTokenListed, setSelectedTokenListed] = useState('');
  const [selectedTokenUnlisted, setSelectedTokenUnlisted] = useState('');

  useEffect(() => {
    if (realTokens) {
      const realtokensList = JSON.parse(JSON.stringify(realTokens.list));
      if (realtokensList) {
        let tokens = realtokensList.filter(
          (data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'
        );

        if (balanceWallets) {
          tokens.forEach((token) => {
            token.position = 0;
            holderWallets
              .filter((holderWallet) => holderWallet.checked)
              .forEach((holderWallet) => {
                for (const wallet of Object.keys(balanceWallets)) {
                  if (holderWallet.address === wallet) {
                    for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                      if (chain === 'eth') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens || [];
                        const tokensList = tokens.filter((object) => object?.token?.id === token.gnosisContract);
                        tokensList.forEach((object) => {
                          const tokenAmount = parseFloat(object.amount);
                          token.position += tokenAmount;
                        });
                      }

                      if (chain === 'xdai') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens || [];
                        const tokensList = tokens.filter((object) => object?.token?.id === token.gnosisContract);
                        tokensList.forEach((object) => {
                          const tokenAmount = parseFloat(object.amount);
                          token.position += tokenAmount;
                        });
                      }

                      if (chain === 'rmm') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens || [];
                        const tokensList = tokens.filter((object) => object?.token?.id === token.gnosisContract);
                        tokensList.forEach((object) => {
                          const tokenAmount = parseInt(object.currentATokenBalance) / 10 ** object.reserve.decimals;
                          token.position += tokenAmount;
                        });
                      }

                      if (chain === 'rmmv3') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens || [];
                        const tokensList = tokens.filter((object) => object?.token?.id === token.gnosisContract);
                        tokensList.forEach((object) => {
                          const tokenAmount = parseInt(object.amount) / 10 ** object.token.decimals;
                          token.position += tokenAmount;
                        });
                      }

                      if (chain === 'pool') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens || [];
                        const tokensList = tokens.filter(
                          (object) => object?.pair?.token0?.id === token.gnosisContract || object?.pair?.token1?.id === token.gnosisContract
                        );
                        tokensList.forEach((object) => {
                          const liquidityTokenBalance = parseFloat(object.liquidityTokenBalance);
                          const totalSupply = parseFloat(object.pair.totalSupply);

                          if (token.gnosisContract === object.pair.token0.id) {
                            const tokenAmount =
                              object.pair.token0.liquidity > 1000
                                ? (liquidityTokenBalance * object.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                : (liquidityTokenBalance * object.pair.token0.liquidity) / totalSupply;
                            token.position += parseFloat(tokenAmount);
                          }
                          if (token.gnosisContract === object.pair.token1.id) {
                            const tokenAmount =
                              object.pair.token1.liquidity > 1000
                                ? (liquidityTokenBalance * object.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                : (liquidityTokenBalance * object.pair.token1.liquidity) / totalSupply;
                            token.position += parseFloat(tokenAmount);
                          }
                        });
                      }
                    }
                  }
                }
              });
          });
        }

        // tokens = tokens.map((token) => {
        // 	const startDate = new Date(token.rentStartDate.date);
        // 	return { ...token, startDate: startDate };
        // });

        setTokens(tokens);
      }
    }
  }, [realTokens]);

  useEffect(() => {
    const API_PITSBI_URL = 'https://api.pitsbi.io/api/';

    const getRealTokensCompagnies = async () => {
      try {
        const response = await fetch(API_PITSBI_URL + 'realTokensCompagnies');
        if (response.ok) {
          const body = await response.json();
          setCompagnies(body); // Assuming body is an array of companies
        } else {
          console.error('Failed get_realTokensCompagnies:', response.status, response.statusText);
        }
      } catch (error) {
        console.log('get_realTokensCompagnies', '- fetch error :', error);
      }
    };

    getRealTokensCompagnies();
  }, []);

  useEffect(() => {
    if (tokens && compagnies) {
      // Filtering companies that match tokens by realtokens_id
      const listed = compagnies
        .filter((company) => tokens.some((token) => token.id === company.realtokens_id))
        .map((company) => ({
          ...company,
          fullName: tokens.find((token) => token.id === company.realtokens_id).fullName,
          token: tokens.find((token) => token.id === company.realtokens_id),
        }));

      // Finding tokens that do not match any company ID
      const unlisted = tokens.filter((token) => !compagnies.some((company) => company.realtokens_id === token.id));

      setCompagniesListed(listed);
      setCompagniesUnlisted(unlisted);
      // console.table(listed);
    }
  }, [tokens, compagnies]);

  const handleTokenListedChange = (event, value) => {
    // Mettre à jour l'objet complet sélectionné
    setSelectedTokenListed(value);
  };
  const handleTokenUnlistedChange = (event, value) => {
    // Mettre à jour l'objet complet sélectionné
    setSelectedTokenUnlisted(value);
  };

  const width_size_TextField = 150;
  const size_width = 900;
  const size_height = 150;
  const size_width_reinvest = 1000;

  // if (!realTokens || !tokens || !listData) {
  if (!realTokens || !tokens || !compagnies || !compagniesListed) {
    return (
      <Paper elevation={3} sx={{ m: 1, minHeight: size_height, width: '60%', border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: size_height }}>
          <CircularProgress size='4rem' />
          <Typography variant='body2' color='primary.main' sx={{ mt: 2 }}>
            <em>Loading Compagnies Verification...</em>
          </Typography>
        </Box>
      </Paper>
    );
  }

  const nb_listed = compagniesListed.length || 0;
  const nb_unlisted = compagniesUnlisted.length || 0;

  // console.log('selectedTokenListed', selectedTokenListed);

  return (
    <Paper
      elevation={3}
      sx={{ my: 1, p: 1, minHeight: size_height, width: '95%', border: 1, borderColor: 'primary.main', borderRadius: 4 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'top',
          justifyContent: 'center',
          width: '100%',
          minHeight: size_height,
          border: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            width: '49%',
            minHeight: size_height,
            border: 1,
            borderColor: 'primary.main',
            borderRadius: 4,
          }}
        >
          <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
            Properties without verification ({nb_unlisted}/{tokens.length})
          </Typography>
          <Box sx={{ mt: 1, px: 2, width: '100%' }}>
            <Autocomplete
              sx={{ width: '100%' }}
              options={compagniesUnlisted}
              getOptionLabel={(option) => option?.fullName || ''}
              onChange={handleTokenUnlistedChange}
              value={selectedTokenUnlisted}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label='Select a realToken' variant='outlined' sx={{ width: '100%' }} />}
            />
          </Box>
          {selectedTokenUnlisted && (
            <Box sx={{ mt: 2, px: 2, width: '100%' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', cursor: 'pointer' }}
                onClick={() => window.open(selectedTokenUnlisted.marketplaceLink + '#tab-title-offering_tab', '_blank')}
              >
                <OpenInNewIcon sx={{ color: 'primary.main' }} />
                <Typography variant='h6' sx={{ ml: 1, color: 'primary.main' }}>
                  Selected RealToken:
                </Typography>
              </Box>

              <Box sx={{ pl: 3, mb: 1 }}>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Name: <strong>{`${selectedTokenUnlisted.shortName}`}</strong>
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Price: <strong>{`${selectedTokenUnlisted.tokenPrice.toFixed(2)} $`}</strong>
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Yield: <strong>{`${selectedTokenUnlisted.annualPercentageYield.toFixed(2)} %`}</strong>
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Owned: <strong>{`${selectedTokenUnlisted.position}`}</strong>
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Nb of realTokens: <strong>{`${selectedTokenUnlisted.totalTokens}`}</strong>
                </Typography>
                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                  Property Type: <strong>{`${DEFAULT_PROPERTY_TYPE[selectedTokenUnlisted.propertyType].label}`}</strong>
                </Typography>
              </Box>
              <Typography variant='h6' sx={{ mt: 1, color: 'primary.main' }}>
                Issuer Name:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                  pl: 3,
                  pr: 0,
                  my: 1,
                  gap: 2, // Ajoute un espace entre les éléments
                }}
              >
                <TextField
                  label='Enter Issuer Name'
                  variant='outlined'
                  sx={{ flexGrow: 1 }} // Le champ occupe tout l'espace disponible
                  onChange={(e) => console.log('Issuer Name:', e.target.value)} // Gère la saisie de l'issuer name
                />
                <Button variant='contained' disabled color='success'>
                  Send
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            ml: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            width: '49%',
            minHeight: size_height,
            border: 1,
            borderColor: 'primary.main',
            borderRadius: 4,
          }}
        >
          <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
            Verified Properties ({nb_listed}/{tokens.length})
          </Typography>
          <Box sx={{ mx: 2, width: '100%' }}>
            <Autocomplete
              sx={{ ml: 2, width: '100%' }}
              options={compagniesListed}
              getOptionLabel={(option) => option?.fullName || ''}
              onChange={handleTokenListedChange}
              value={selectedTokenListed}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => <TextField {...params} label='Select a realToken' variant='outlined' sx={{ mt: 1, width: '95%' }} />}
            />
            {selectedTokenListed && (
              <Box sx={{ mt: 2, px: 2, width: '100%' }}>
                <Box
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', cursor: 'pointer' }}
                  onClick={() => window.open(selectedTokenListed.token.marketplaceLink + '#tab-title-offering_tab', '_blank')}
                >
                  <OpenInNewIcon sx={{ color: 'primary.main' }} />
                  <Typography variant='h6' sx={{ ml: 1, color: 'primary.main' }}>
                    Selected RealToken:
                  </Typography>
                </Box>
                <Box sx={{ pl: 3, mb: 1 }}>
                  <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                    Last update: <strong>{`${new Date(selectedTokenListed.timsync).toLocaleDateString()}`}</strong>
                  </Typography>
                </Box>

                {'delaware' in selectedTokenListed.compagnies_metadata && (
                  <>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', cursor: 'pointer' }}
                      onClick={() => window.open('https://icis.corp.delaware.gov/eCorp/EntitySearch/NameSearch.aspx', '_blank')}
                    >
                      <Typography variant='h6' sx={{ ml: 0, color: 'primary.main' }}>
                        Delaware Division of Corporations :
                      </Typography>
                      <OpenInNewIcon sx={{ ml: 1, color: 'primary.main' }} />
                    </Box>
                    <Box sx={{ pl: 3, mb: 1 }}>
                      {Object.keys(selectedTokenListed.compagnies_metadata.delaware).map((key, index) => (
                        <Typography key={index} variant='body2' sx={{ color: 'text.secondary', mt: 1 }}>
                          {`${key}: `}
                          <strong>{selectedTokenListed.compagnies_metadata.delaware[key]}</strong>
                        </Typography>
                      ))}
                    </Box>
                  </>
                )}

                {'opencorporate' in selectedTokenListed.compagnies_metadata && (
                  <>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', cursor: 'pointer' }}
                      onClick={() => window.open(selectedTokenListed.compagnies_metadata.opencorporate['link'], '_blank')}
                    >
                      <Typography variant='h6' sx={{ ml: 0, color: 'primary.main' }}>
                        OpenCorporate :
                      </Typography>
                      <OpenInNewIcon sx={{ ml: 1, color: 'primary.main' }} />
                    </Box>
                    <Box sx={{ pl: 3, mb: 1 }}>
                      {Object.keys(selectedTokenListed.compagnies_metadata.opencorporate).map((key, index) => (
                        <Typography key={index} variant='body2' sx={{ color: 'text.secondary', mt: 1 }}>
                          {`${key}: `}
                          <strong>{selectedTokenListed.compagnies_metadata.opencorporate[key]}</strong>
                        </Typography>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
export default TablePropertyVerifyCompanies;
