import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow, Select, MenuItem, TableHead } from '@mui/material';
import FormatedPercentage from '../../Common/Formater/FormatedPercentage';

function WidgetProperties() {
  const { realTokens, getNextRonday, holderWallets, balanceWallets } = useContext(SettingsContext);
  const { settingsDashboard, setSettingsDashboard } = useContext(SettingsContext);

  // console.log("holderWallets",holderWallets)
  if (!realTokens) return <></>;
  if (!holderWallets) return <></>;
  if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];

  let tokensBalance = [
    { label: 'Ethereum', chain: 'eth', count: 0, units: 0, total: 0, yield: 0, tokens: [] },
    { label: 'Gnosis', chain: 'xdai', count: 0, units: 0, total: 0, yield: 0, tokens: [] },
    { label: 'RMM', chain: 'rmm', count: 0, units: 0, total: 0, yield: 0, tokens: [] },
    { label: 'RMM v3', chain: 'rmmv3', count: 0, units: 0, total: 0, yield: 0, tokens: [] },
    { label: 'Levinswap', chain: 'pool', count: 0, units: 0, total: 0, yield: 0, tokens: [] },
    { label: 'Total', chain: 'total', count: 0, units: 0, total: 0, yield: 0, tokens: [] },
  ];

  if (balanceWallets) {
    tokensBalance.forEach((tokenBalance) => {
      holderWallets
        .filter((holderWallet) => holderWallet.checked)
        .forEach((holderWallet) => {
          for (const wallet of Object.keys(balanceWallets)) {
            if (holderWallet.address === wallet) {
              for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                  if (chain === 'eth') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
                        if (realToken) {
                          // if (Date.now() > getNextRonday(realToken))
                          let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                          if (existingTokenIndex >= 0) {
                            // console.log("Update eth - token exist ....");
                            tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                            tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * token.amount);
                            tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * token.amount);
                            tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                          } else {
                            // If the token doesn't exist, push a new entry
                            tokenBalance.tokens.push({ id: realToken.id, token: realToken });
                            tokenBalance.count += 1;
                            tokenBalance.units += realToken.rentedUnits;
                            tokenBalance.total += realToken.totalUnits;
                            tokenBalance.yield = parseFloat(tokenBalance.units / tokenBalance.total);
                            if (!tokenBalance.capitalTotal) {
                              tokenBalance.capitalTotal = parseFloat(realToken.tokenPrice * token.amount);
                              tokenBalance.capital = parseFloat(tokenBalance.yield * realToken.tokenPrice * token.amount);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            } else {
                              tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * token.amount);
                              tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * token.amount);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            }
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                  if (chain === 'xdai') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
                        if (realToken) {
                          // if (Date.now() > getNextRonday(realToken))
                          let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                          if (existingTokenIndex >= 0) {
                            // console.log("WidgetProperties - Update xdai - token exist ....");
                            tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                            tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * token.amount);
                            tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * token.amount);
                            tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                          } else {
                            // If the token doesn't exist, push a new entry
                            tokenBalance.tokens.push({ id: realToken.id, token: realToken });
                            tokenBalance.count += 1;
                            tokenBalance.units += realToken.rentedUnits;
                            tokenBalance.total += realToken.totalUnits;
                            tokenBalance.yield = parseFloat(tokenBalance.units / tokenBalance.total);
                            if (!tokenBalance.capitalTotal) {
                              tokenBalance.capitalTotal = parseFloat(realToken.tokenPrice * token.amount);
                              tokenBalance.capital = parseFloat(tokenBalance.yield * realToken.tokenPrice * token.amount);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            } else {
                              tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * token.amount);
                              tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * token.amount);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            }
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                  if (chain === 'rmm') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                        if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                          let realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
                          if (realToken) {
                            // if (Date.now() > getNextRonday(realToken))
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              // console.log("Update rmm - token exist ....");
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                              tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * rmmBalance);
                              tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * rmmBalance);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            } else {
                              // If the token doesn't exist, push a new entry
                              realToken.amount = rmmBalance;
                              tokenBalance.tokens.push({ id: realToken.id, token: realToken });
                              tokenBalance.count += 1;
                              tokenBalance.units += realToken.rentedUnits;
                              tokenBalance.total += realToken.totalUnits;
                              tokenBalance.yield = parseFloat(tokenBalance.units / tokenBalance.total);
                              if (!tokenBalance.capitalTotal) {
                                tokenBalance.capitalTotal = parseFloat(realToken.tokenPrice * rmmBalance);
                                tokenBalance.capital = parseFloat(tokenBalance.yield * realToken.tokenPrice * rmmBalance);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                              } else {
                                tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * rmmBalance);
                                tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * rmmBalance);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                              }
                            }
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                  if (chain === 'rmmv3') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                        if (token.token.id !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                          let realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
                          if (realToken) {
                            // if (Date.now() > getNextRonday(realToken))
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              // console.log("Update rmm - token exist ....");
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                              tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * rmmBalance);
                              tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * rmmBalance);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            } else {
                              // If the token doesn't exist, push a new entry
                              realToken.amount = rmmBalance;
                              tokenBalance.tokens.push({ id: realToken.id, token: realToken });
                              tokenBalance.count += 1;
                              tokenBalance.units += realToken.rentedUnits;
                              tokenBalance.total += realToken.totalUnits;
                              tokenBalance.yield = parseFloat(tokenBalance.units / tokenBalance.total);
                              if (!tokenBalance.capitalTotal) {
                                tokenBalance.capitalTotal = parseFloat(realToken.tokenPrice * rmmBalance);
                                tokenBalance.capital = parseFloat(tokenBalance.yield * realToken.tokenPrice * rmmBalance);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                                // console.log("WidgetProperties rmmv3", realToken.shortName, tokenBalance.yield, realToken.tokenPrice, rmmBalance);
                              } else {
                                tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * rmmBalance);
                                tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * rmmBalance);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                              }
                            }
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                  if (chain === 'pool') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
                        const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                        const totalSupply = parseFloat(token.pair.totalSupply);
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                          let realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                          const poolBalance =
                            token.pair.token0.liquidity > 1000
                              ? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
                              : (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                          if (realToken) {
                            // if (Date.now() > getNextRonday(realToken))
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              // console.log("Update pool - token exist ....");
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                              tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * poolBalance * 2);
                              tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * poolBalance * 2);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            } else {
                              // If the token doesn't exist, push a new entry
                              realToken.amount = poolBalance;
                              tokenBalance.tokens.push({ id: realToken.id, token: realToken });
                              tokenBalance.count += 1;
                              tokenBalance.units += realToken.rentedUnits;
                              tokenBalance.total += realToken.totalUnits;
                              tokenBalance.yield = parseFloat(tokenBalance.units / tokenBalance.total);
                              if (!tokenBalance.capitalTotal) {
                                tokenBalance.capitalTotal = parseFloat(realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capital = parseFloat(tokenBalance.yield * realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                                // console.log("WidgetProperties pool", realToken.shortName, tokenBalance.yield, realToken.tokenPrice, rmmBalance);
                              } else {
                                tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                              }
                            }
                          }
                        }
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                          let realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                          const poolBalance =
                            token.pair.token1.liquidity > 1000
                              ? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
                              : (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                          if (realToken) {
                            // if (Date.now() > getNextRonday(realToken))
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              // console.log("Update pool - token exist ....");
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                              tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * poolBalance * 2);
                              tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * poolBalance * 2);
                              tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                            } else {
                              // If the token doesn't exist, push a new entry
                              realToken.amount = poolBalance;
                              tokenBalance.tokens.push({ id: realToken.id, token: realToken });
                              tokenBalance.count += 1;
                              tokenBalance.units += realToken.rentedUnits;
                              tokenBalance.total += realToken.totalUnits;
                              tokenBalance.yield = parseFloat(tokenBalance.units / tokenBalance.total);
                              if (!tokenBalance.capitalTotal) {
                                tokenBalance.capitalTotal = parseFloat(realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capital = parseFloat(tokenBalance.yield * realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                                // console.log("WidgetProperties pool", realToken.shortName, tokenBalance.yield, realToken.tokenPrice, rmmBalance);
                              } else {
                                tokenBalance.capitalTotal += parseFloat(realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capital += parseFloat(tokenBalance.yield * realToken.tokenPrice * poolBalance * 2);
                                tokenBalance.capitalYield = tokenBalance.capital / tokenBalance.capitalTotal;
                              }
                            }
                          }
                        }
                      });
                  }
                }
              }
            }
          }
        });
    });
  }

  const setSettingsDashboardChange = (key, newValue) => {
    // console.log("setSettingsStatsChange:", key, newValue);
    setSettingsDashboard((prevSettings) => ({
      ...prevSettings,
      [key]: newValue,
    }));
  };

  // console.log("WidgetProperties - tokensBalance", tokensBalance);

  if (!tokensBalance[5].count > 0) return <></>;
  return (
    <Paper elevation={3} sx={{ m: 1, minWidth: 370, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Properties - Ratio by
        </Typography>
        <Select
          sx={{ mr: 1, minWidth: 125 }}
          value={settingsDashboard.selectedCalculType}
          onChange={(e) => setSettingsDashboardChange('selectedCalculType', e.target.value)}
          size='small'
        >
          {settingsDashboard.calculType.map((p, index) => (
            <MenuItem key={index} value={p}>
              {p}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 330,
              background: 'background.paper',
              '& thead th': { borderWidth: 0 },
              '& tbody tr:last-child td': { borderBottom: 0 },
            }}
            aria-label='sticky table'
          >
            <TableHead>
              <TableRow sx={{ m: 0, p: 1 }}>
                <TableCell align='left' sx={{ pl: 2, pr: 0, py: 0.5 }}>
                  Chain
                </TableCell>
                <TableCell align='right' sx={{ pl: 2, pr: 0, py: 0.5 }}>
                  Token
                </TableCell>
                <TableCell align='right' sx={{ pl: 2, pr: 0, py: 0.5 }}>
                  Units
                </TableCell>
                <TableCell align='right' sx={{ pl: 2, pr: 2, py: 0.5 }}>
                  Ratio
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ mt: 0 }}>
              {tokensBalance
                .filter((token) => token.count > 0)
                .map((token) => (
                  <TableRow key={token.label}>
                    <TableCell align='left' sx={{ pl: 2, pr: 0, py: 0.5 }}>
                      <Typography
                        variant='body2'
                        component='span'
                        style={{ fontWeight: token.chain === 'total' ? 'bold' : 'normal' }}
                        sx={{ color: token.chain === 'total' ? 'primary.main' : 'inherit' }}
                      >
                        {token.label}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' sx={{ pl: 2, pr: 0, py: 0.5 }}>
                      <Typography
                        variant='body2'
                        component='span'
                        style={{ fontWeight: token.chain === 'total' ? 'bold' : 'normal' }}
                        sx={{ color: token.chain === 'total' ? 'primary.main' : 'inherit' }}
                      >
                        {token.count}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' sx={{ pl: 2, pr: 0, py: 0.5 }}>
                      <Typography
                        variant='body2'
                        component='span'
                        style={{ fontWeight: token.chain === 'total' ? 'bold' : 'normal' }}
                        sx={{ color: token.chain === 'total' ? 'primary.main' : 'inherit' }}
                      >
                        {token.units}/{token.total}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' sx={{ pl: 2, pr: 2, py: 0.5 }}>
                      <FormatedPercentage
                        value={settingsDashboard.selectedCalculType === 'RealTokens' ? token.units / token.total : token.capitalYield}
                        variant={'body2'}
                        color={token.chain === 'total' ? 'primary.main' : 'inherit'}
                        fontWeight={token.chain === 'total' ? 'bold' : 'normal'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default WidgetProperties;
