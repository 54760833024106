import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { TableRow, TableCell, IconButton, Avatar, Tooltip } from '@mui/material';
import { green, red } from '@mui/material/colors';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FormatedPrice from '../../Common/Formater/FormatedPrice';

function TablePropertyPriceSectorsMapRow({ token, parent, calculType }) {
  const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);

  const handleTokenClick = (marketplaceLink) => {
    window.open(marketplaceLink, '_blank');
  };

  const initialDate = new Date(token.initialLaunchDate.date);
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 30);
  token.isNew = initialDate > sevenDaysAgo;

  // console.log("calculType", calculType, "token", token);

  if (token.id === 0) return <></>;
  return (
    <TableRow key={token.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell
        sx={{ pl: 1, pr: 1, py: 1, cursor: 'pointer' }}
        align='center'
        component='th'
        scope='row'
        onClick={() => handleTokenClick(token.marketplaceLink)}
      >
        <OpenInNewIcon />
      </TableCell>
      {token.isNew ? (
        <>
          <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
            <IconButton sx={{ ml: 0 }}>
              <Avatar
                alt='Logo'
                src='/images/new_gpt.png'
                sx={{ ml: 0, width: 25, height: 25, imgProps: { style: { filter: 'brightness(0) invert(0)' } } }}
              />
            </IconButton>
          </TableCell>
          <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
            {token.fullName}
          </TableCell>
        </>
      ) : (
        <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} colSpan={2}>
          {token.fullName}
        </TableCell>
      )}

      <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }}>
        {token.initialLaunchDate.date.slice(0, 10)}
      </TableCell>
      <Tooltip
        title={
          token.totalInvestment > token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve ? (
            <FormatedPrice value={token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve} />
          ) : (
            'Token Revaluated'
          )
        }
      >
        <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} component='th' scope='row'>
          <FormatedPrice value={token.totalInvestment} color={'primary.main'} />
        </TableCell>
      </Tooltip>
      <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} component='th' scope='row'>
        {DEFAULT_PROPERTY_TYPE[token.propertyType].label}
      </TableCell>
      <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} component='th' scope='row'>
        <FormatedPrice
          value={token.doorsPrice}
          color={
            calculType === 'Door'
              ? parent.maxPrice ===
                (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits
                ? red[700]
                : parent.minPrice ===
                    (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits
                  ? green[500]
                  : 'primary.main'
              : ''
          }
          fontWeight={
            calculType === 'Door'
              ? parent.maxPrice ===
                (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits
                ? 'bold'
                : parent.minPrice ===
                    (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.totalUnits
                  ? 'bold'
                  : 'normal'
              : 'normal'
          }
        />
      </TableCell>
      <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} component='th' scope='row'>
        <FormatedPrice
          value={token.sqftPrice}
          color={
            calculType === 'sqft'
              ? parent.maxPrice ===
                (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet
                ? red[700]
                : parent.minPrice ===
                    (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet
                  ? green[500]
                  : 'primary.main'
              : ''
          }
          fontWeight={
            calculType === 'sqft'
              ? parent.maxPrice ===
                (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet
                ? 'bold'
                : parent.minPrice ===
                    (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet
                  ? 'bold'
                  : 'normal'
              : 'normal'
          }
        />
      </TableCell>
      <TableCell align='center' sx={{ pl: 1, pr: 1, py: 1 }} component='th' scope='row'>
        <FormatedPrice
          value={token.m2Price}
          color={
            calculType === 'm2'
              ? parent.maxPrice ===
                (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet / 0.092903
                ? red[700]
                : parent.minPrice ===
                    (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet / 0.092903
                  ? green[500]
                  : 'primary.main'
              : ''
          }
          fontWeight={
            calculType === 'm2' || calculType === 'sqft' || calculType === 'Door'
              ? parent.maxPrice ===
                (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet / 0.092903
                ? 'bold'
                : parent.minPrice ===
                    (token.underlyingAssetPrice + token.initialMaintenanceReserve + token.renovationReserve) / token.squareFeet / 0.092903
                  ? 'bold'
                  : 'normal'
              : 'normal'
          }
        />
      </TableCell>
    </TableRow>
  );
}

export default TablePropertyPriceSectorsMapRow;
