import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from '@mui/material';
import FormatedNumber from '../../Common/Formater/FormatedNumber';

function WidgetRealTokens() {
  const { realTokens, holderWallets, balanceWallets } = useContext(SettingsContext);

  if (!realTokens) return <></>;
  if (!holderWallets) return <></>;
  if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];

  // console.log("equity_token",realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType==="equity_token"))

  let tokensBalance = [
    { label: 'Ethereum', chain: 'eth', count: 0, tokens: [] },
    { label: 'Gnosis', chain: 'xdai', count: 0, tokens: [] },
    { label: 'RMM', chain: 'rmm', count: 0, tokens: [] },
    { label: 'RMM v3', chain: 'rmmv3', count: 0, tokens: [] },
    { label: 'Levinswap', chain: 'pool', count: 0, tokens: [] },
    { label: 'Total', chain: 'total', count: 0, tokens: [] },
  ];

  if (balanceWallets) {
    tokensBalance.forEach((tokenBalance) => {
      holderWallets
        .filter((holderWallet) => holderWallet.checked)
        .forEach((holderWallet) => {
          for (const wallet of Object.keys(balanceWallets)) {
            if (holderWallet.address === wallet) {
              for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                  if (chain === 'eth') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        tokenBalance.count += parseFloat(token.amount);
                      });
                  }
                }

                if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                  if (chain === 'xdai') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // if (token.token.id === "0x0675e8f4a52ea6c845cb6427af03616a2af42170") console.log("xdai", token.amount, token.token.id);
                        if (token.token.id !== '0x0675e8f4a52ea6c845cb6427af03616a2af42170') tokenBalance.count += parseFloat(token.amount);
                      });
                  }
                }

                if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                  if (chain === 'rmm') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                        if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                          tokenBalance.count += parseFloat(rmmBalance);
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                  if (chain === 'rmmv3') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                        tokenBalance.count += parseFloat(rmmBalance);
                      });
                  }
                }

                if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                  if (chain === 'pool') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
                        const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                        const totalSupply = parseFloat(token.pair.totalSupply);
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token0.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                              tokenBalance.count += parseFloat(poolBalance);
                            }
                        }
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token1.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                              tokenBalance.count += parseFloat(poolBalance);
                            }
                        }
                      });
                  }
                }
              }
            }
          }
        });
    });
  }

  // console.log("WidgetRealTokens - tokensBalance", tokensBalance);

  if (!tokensBalance[5].count > 0) return <></>;
  return (
    <Paper elevation={3} sx={{ m: 1, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          RealTokens
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 200,
              background: 'background.paper',
              '& thead th': { borderWidth: 0 },
              '& tbody tr:last-child td': { borderBottom: 0 },
            }}
            aria-label='sticky table'
          >
            <TableBody sx={{ mt: 0 }}>
              {tokensBalance
                .filter((token) => token.count > 0)
                .map((token) => (
                  <TableRow key={token.label}>
                    <TableCell align='left' sx={{ px: 2, py: 0.5 }}>
                      <Typography
                        variant='body2'
                        component='span'
                        style={{ fontWeight: token.chain === 'total' ? 'bold' : 'normal' }}
                        sx={{ color: token.chain === 'total' ? 'primary.main' : 'inherit' }}
                      >
                        {token.label}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' sx={{ px: 2, py: 0.5 }}>
                      <FormatedNumber
                        value={token.count}
                        variant={'body2'}
                        color={token.chain === 'total' ? 'primary.main' : 'inherit'}
                        fontWeight={token.chain === 'total' ? 'bold' : 'normal'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default WidgetRealTokens;
