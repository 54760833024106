import React from 'react';
// import { Link } from "react-router-dom";
import { Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

const DrawerComponent = (props) => {
  return (
    <Box>
      <Drawer key='MarketDrawer' open={props.openDrawer} anchor='right' onClose={() => props.setOpenDrawer(false)}>
        <List>
          {props.tabs.map((link, index) => (
            <>
              <ListItemButton key={'MarketDrawer' + index} onClick={() => props.setOpenDrawer(false)}>
                <ListItemIcon key={index} onClick={() => props.handleTabClick(index)}>
                  {link.icon ? link.icon : ''}
                  <ListItemText key={index}>
                    <Typography variant='h4' color='inherit' sx={{ ml: 1 }}>
                      {link.name}
                    </Typography>
                  </ListItemText>
                </ListItemIcon>
              </ListItemButton>
              <Divider />
            </>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerComponent;
