import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

const DrawerComponent = (props) => {
  return (
    <Box>
      <Drawer key={'HeaderDrawerComponent'} open={props.openDrawer} onClose={() => props.setOpenDrawer(false)}>
        <List>
          {props.links
            .filter((l) => l.hide !== true)
            .map((link) => (
              <React.Fragment key={link.url}>
                <ListItemButton onClick={() => props.setOpenDrawer(false)} component={Link} to={link.url}>
                  <ListItemIcon>
                    {link.icon ? link.icon : ''}
                    <ListItemText>
                      <Typography variant='h4' color='inherit' sx={{ ml: 1 }}>
                        {link.name}
                      </Typography>
                    </ListItemText>
                  </ListItemIcon>
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerComponent;
