import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from '@mui/material';
// import { useTheme } from "@mui/system";

function TableYearHolderCost() {
  const { realTokens } = useContext(SettingsContext);

  if (!realTokens) return <></>;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];

  // const totalTotalProperties = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits > 0 ? 1 : 0 || 0), 0));
  // const totalTotalUnits = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits || 0), 0));
  // const totalTokenPrice = tokens.reduce((acc, data) => acc + (data.tokenPrice || 0), 0);
  // const totalTotalTokens = parseInt(tokens.reduce((acc, data) => acc + (data.totalTokens || 0), 0));

  // const miscellaneousCosts = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.miscellaneousCosts || 0), 0));
  const propertyMaintenanceMonthly = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyMaintenanceMonthly || 0), 0));
  const propertyManagement = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyManagement || 0), 0));
  const realtPlatform = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.realtPlatform || 0), 0));
  const propertyTaxes = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.propertyTaxes || 0), 0));
  const insurance = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.insurance || 0), 0));
  const utilities = 12 * parseInt(tokens.reduce((acc, data) => acc + (data.utilities || 0), 0));

  // console.log("totalGrossRentYear", totalGrossRentYear);

  return (
    <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Holders Cost / Year
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 300,
              p: 1,
              background: 'background.paper',
              '& thead th': { borderWidth: 0 },
              '& tbody tr:last-child td': { borderBottom: 0 },
            }}
            aria-label='sticky table'
          >
            <TableBody sx={{ mt: 0 }}>
              <TableRow>
                <TableCell align='left'>Property Taxes</TableCell>
                <TableCell align='right'>{propertyTaxes.toLocaleString()} $</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>Property Management</TableCell>
                <TableCell align='right'>{propertyManagement.toLocaleString()} $</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>Property Maintenance</TableCell>
                <TableCell align='right'>{propertyMaintenanceMonthly.toLocaleString()} $</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>Utilities Cost</TableCell>
                <TableCell align='right'>{utilities.toLocaleString()} $</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>Insurance Cost</TableCell>
                <TableCell align='right'>{insurance.toLocaleString()} $</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left'>RealT Rent Distribution</TableCell>
                <TableCell align='right'>{realtPlatform.toLocaleString()} $</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default TableYearHolderCost;
