/* eslint-disable no-lone-blocks */
import React, { createContext, useEffect, useState } from 'react';
// import Web3 from "web3";

export const SettingsContext = createContext();

const FRONT_END_NAME = "Pit's BI";
const FRONT_END_VERSION = '0.9.3.018 beta';

function generateColoredIconUrl(color) {
  const baseUrl = './images/maps'; // Base URL de l'icône
  return `${baseUrl}/${color}.png`;
}

const DEFAULT_RENT_TYPE = ['Day', 'Week', 'Month', 'Year'];
const DEFAULT_CALCUL_TYPE = ['RealTokens', 'Capital Assets'];

function createListAndColor(index, color, label) {
  return { index, color, label, img: generateColoredIconUrl(color), selected: true };
}
const DEFAULT_PROPERTY_COLORS = {
  0: 'white', // All Properties
  1: 'red', // Single Family
  2: 'blue', // Multi Family
  3: 'green', // Duplex
  4: 'purple', // Condominium
  6: 'orange', // Mixed-Use
  7: 'deeppink', // Triplex
  8: 'limegreen', // Quadplex
  9: 'cyan', // Commercial
  10: 'silver', // SFR Portfolio
  11: 'grey', // MFR Portfolio
  12: 'yellow', // Resort Bungalow
};
const DEFAULT_RENTED_COLORS = [
  createListAndColor(0, 'white', 'All Rented type'),
  createListAndColor(1, 'red', 'Vacant'),
  createListAndColor(2, 'yellow', 'Partially Rented'),
  createListAndColor(3, 'green', 'Fully Rented'),
];
const DEFAULT_OWNED_COLORS = [
  createListAndColor(0, 'white', 'All realTokens'),
  createListAndColor(1, 'green', 'Owned'),
  // createListAndColor(2, "yellow", "Partially Owned"),
  createListAndColor(2, 'yellow', '<1 token owned'),
  createListAndColor(3, 'red', 'Not owned'),
];
const DEFAULT_NEWS_COLORS = [
  createListAndColor(0, 'white', 'All realTokens'),
  createListAndColor(1, 'blue', '7 days'),
  createListAndColor(2, 'purple', '30 days'),
  createListAndColor(3, 'lightgrey', 'No news'),
];

const DEFAULT_SETTINGS_TRAFIC = {
  country_rowsPerPage: 25,
  city_rowsPerPage: 25,
};

const DEFAULT_SETTINGS_DASHBOARD = {
  isExpandedHighlight: true,
  isExpandedHistory: false,
  isExpandedTokens: false,
  isExpandedInsight: false,
  isExpandedCharts: false,
  selectedPropertyType: 0,
  selectedRentType: 'Week',
  selectedCalculType: 'RealTokens',
  rentType: DEFAULT_RENT_TYPE,
  calculType: DEFAULT_CALCUL_TYPE,
};
const DEFAULT_SETTINGS_MAP = {
  version: 5,
  selectedPropertyType: 0,
  selectedRentedType: 0,
  filterByOwnedType: 0,
  propertyColors: DEFAULT_PROPERTY_COLORS,
  rentedColors: DEFAULT_RENTED_COLORS,
  ownedColors: DEFAULT_OWNED_COLORS,
  newsColors: DEFAULT_NEWS_COLORS,
};
const DEFAULT_SETTINGS_STATS = {
  selectedPropertyType: 0,
  selectedCalculType: 'RealTokens',
  rentType: DEFAULT_RENT_TYPE,
  calculType: DEFAULT_CALCUL_TYPE,
};
const DEFAULT_SETTINGS_API_CoinGecko = {
  timerUpdateCoinsConvertion: 30 * 60 * 1000,
  timerUpdateCoinsBalance: 30 * 60 * 1000,
  timerHistoryzeCoinsBalance: 4 * 60 * 60 * 1000,
  timerDeleteCoinsBalance: 30 * 24 * 60 * 60 * 1000,
};
const DEFAULT_SETTINGS_WALLET_TOKEN = {
  selectedBlockchain: 'Gnosis',
  selectedWallet: '',
  selectedWalletHideCoins: true,
  timerApiUpdate: 15 * 60 * 1000,
  timeoutApiGet: 5 * 60 * 1000,
  timerDeleteTokenFollowWallets: 90 * 24 * 60 * 60 * 1000,
  timerDeleteTokenHolderWallets: 90 * 24 * 60 * 60 * 1000,
  timerHistorizeTokenFollowWallets: 7 * 24 * 60 * 60 * 1000,
  timerHistorizeTokenHolderWallets: 12 * 60 * 60 * 1000,
  timerUpdateTokenFollowWallets: 7 * 24 * 60 * 60 * 1000,
  timerUpdateTokenHolderWallets: 1 * 24 * 60 * 60 * 1000,
  timerUpdateWhiteListTokens: 12 * 60 * 60 * 1000,
};
const DEFAULT_SETTINGS_MARKET = {
  PurchaseOffers: { selectedPropertyType: 0, unselectedCity: [], showRentStart: true, showPosition: true, showRented: true },
  SaleOffers: { selectedPropertyType: 0, unselectedCity: [], showRentStart: true, showPosition: true, showRented: true },
  SwapCatLastUpdate: 0,
  YamLastUpdate: 0,
  selectedBlockchain: 0,
  timerRefreshOffers: 1 * 60 * 1000,
  timerUpdateOffers: 5 * 60 * 1000,
  timestamp: 0,
};
const DEFAULT_PROPERTY_TYPE = [
  { index: 0, label: 'All Property' },
  { index: 1, label: 'Single Family' },
  { index: 2, label: 'Multi Family' },
  { index: 3, label: 'Duplex' },
  { index: 4, label: 'Condominium' },
  { index: 5, label: 'Text_05' },
  { index: 6, label: 'Mixed-Use' },
  { index: 7, label: 'Triplex' },
  { index: 8, label: 'Quadplex' },
  { index: 9, label: 'Commercial' },
  { index: 10, label: 'SFR Portfolio' },
  { index: 11, label: 'MFR Portfolio' },
  { index: 12, label: 'Resort Bungalow' },
  { index: 13, label: 'Text_13' },
  { index: 14, label: 'Text_14' },
  { index: 15, label: 'Text_15' },
  { index: 16, label: 'Text_16' },
  { index: 17, label: 'Text_17' },
  { index: 18, label: 'Text_18' },
  { index: 19, label: 'Text_19' },
  { index: 20, label: 'Text_20' },
];

// const mini_ABI_COIN = [
// 	{
// 		constant: true,
// 		inputs: [{ name: "_owner", type: "address" }],
// 		name: "balanceOf",
// 		outputs: [{ name: "balance", type: "uint256" }],
// 		type: "function",
// 	},
// ];

const ABI_COIN = [
  {
    constant: true,
    inputs: [],
    name: 'name',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'mintingFinished',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ name: 'result', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: '_bridgeContract', type: 'address' }],
    name: 'setBridgeContract',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'totalSupply',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_sender', type: 'address' },
      { name: '_recipient', type: 'address' },
      { name: '_amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'PERMIT_TYPEHASH',
    outputs: [{ name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'decimals',
    outputs: [{ name: '', type: 'uint8' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'DOMAIN_SEPARATOR',
    outputs: [{ name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ name: 'result', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' },
      { name: '_data', type: 'bytes' },
    ],
    name: 'transferAndCall',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_amount', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: '_value', type: 'uint256' }],
    name: 'burn',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'version',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseApproval',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_token', type: 'address' },
      { name: '_to', type: 'address' },
    ],
    name: 'claimTokens',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '_owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  { constant: false, inputs: [], name: 'renounceOwnership', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function' },
  {
    constant: true,
    inputs: [{ name: '_address', type: 'address' }],
    name: 'isBridge',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [],
    name: 'finishMinting',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [{ name: '', type: 'address' }],
    name: 'nonces',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'getTokenInterfacesVersion',
    outputs: [
      { name: 'major', type: 'uint64' },
      { name: 'minor', type: 'uint64' },
      { name: 'patch', type: 'uint64' },
    ],
    payable: false,
    stateMutability: 'pure',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_holder', type: 'address' },
      { name: '_spender', type: 'address' },
      { name: '_nonce', type: 'uint256' },
      { name: '_expiry', type: 'uint256' },
      { name: '_allowed', type: 'bool' },
      { name: '_v', type: 'uint8' },
      { name: '_r', type: 'bytes32' },
      { name: '_s', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', type: 'string' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: 'spender', type: 'address' },
      { name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_value', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_to', type: 'address' },
      { name: '_amount', type: 'uint256' },
    ],
    name: 'push',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_from', type: 'address' },
      { name: '_to', type: 'address' },
      { name: '_amount', type: 'uint256' },
    ],
    name: 'move',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'PERMIT_TYPEHASH_LEGACY',
    outputs: [{ name: '', type: 'bytes32' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: true,
    inputs: [],
    name: 'bridgeContract',
    outputs: [{ name: '', type: 'address' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_holder', type: 'address' },
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' },
      { name: '_deadline', type: 'uint256' },
      { name: '_v', type: 'uint8' },
      { name: '_r', type: 'bytes32' },
      { name: '_s', type: 'bytes32' },
    ],
    name: 'permit',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_addedValue', type: 'uint256' },
    ],
    name: 'increaseApproval',
    outputs: [{ name: '', type: 'bool' }],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: '_owner', type: 'address' },
      { name: '_spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    constant: false,
    inputs: [
      { name: '_from', type: 'address' },
      { name: '_amount', type: 'uint256' },
    ],
    name: 'pull',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: false,
    inputs: [{ name: '_newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    constant: true,
    inputs: [
      { name: '', type: 'address' },
      { name: '', type: 'address' },
    ],
    name: 'expirations',
    outputs: [{ name: '', type: 'uint256' }],
    payable: false,
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { name: '_name', type: 'string' },
      { name: '_symbol', type: 'string' },
      { name: '_decimals', type: 'uint8' },
      { name: '_chainId', type: 'uint256' },
    ],
    payable: false,
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'to', type: 'address' },
      { indexed: false, name: 'amount', type: 'uint256' },
    ],
    name: 'Mint',
    type: 'event',
  },
  { anonymous: false, inputs: [], name: 'MintFinished', type: 'event' },
  { anonymous: false, inputs: [{ indexed: true, name: 'previousOwner', type: 'address' }], name: 'OwnershipRenounced', type: 'event' },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'previousOwner', type: 'address' },
      { indexed: true, name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'burner', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Burn',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'from', type: 'address' },
      { indexed: true, name: 'to', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
      { indexed: false, name: 'data', type: 'bytes' },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'owner', type: 'address' },
      { indexed: true, name: 'spender', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: 'from', type: 'address' },
      { indexed: true, name: 'to', type: 'address' },
      { indexed: false, name: 'value', type: 'uint256' },
    ],
    name: 'Transfer',
    type: 'event',
  },
];

const EXCHANGE_LIST = {
  usd: {
    id: 0,
    name: 'USD',
    currency: '$',
    style: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      currencyDisplay: 'symbol',
      useGrouping: true,
    },
  },
};

const ETHEREUM_COINS_LIST = {
  ETH: {
    id: 0, //0=coin, other=token
    address: 'Blockchain coin, no contract address',
    name: 'ETH',
    decimal: 18,
    imgToken: '/images/cmc_eth.png',
    abi: null,
    api: 'https://api.coingecko.com/api/v3/coins/ethereum',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  USDC: {
    id: 1,
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    name: 'USDC',
    decimal: 6,
    imgToken: '/images/cmc_usdc.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/usd-coin',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  USDT: {
    id: 2,
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    name: 'USDT',
    decimal: 6,
    imgToken: '/images/cmc_usdt.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/tether',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  DAI: {
    id: 3,
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    name: 'DAI',
    decimal: 18,
    imgToken: '/images/gno_wxdai.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  EURe: {
    id: 4,
    address: '0x3231Cb76718CDeF2155FC47b5286d82e6eDA273f',
    name: 'EURe',
    decimal: 18,
    imgToken: '/images/gno_eure.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/monerium-eur-money',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  WBTC: {
    id: 5,
    address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    name: 'WBTC',
    decimal: 8,
    imgToken: '/images/cmc_wbtc.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/wrapped-bitcoin',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  WETH: {
    id: 6,
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    name: 'WETH',
    decimal: 18,
    imgToken: '/images/cmc_weth.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/weth',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  wstETH: {
    id: 7,
    address: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
    name: 'wstETH',
    decimal: 18,
    imgToken: '/images/cmc_wsteth.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/wrapped-steth',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
};

const GNOSIS_COINS_LIST = {
  xDAI: {
    id: 0, //0=coin, other=token
    address: 'Blockchain coin, no contract address',
    name: 'xDAI',
    decimal: 18,
    imgToken: '/images/cmc_dai.png',
    abi: null,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  WxDAI: {
    id: 1,
    address: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
    name: 'WxDAI',
    decimal: 18,
    imgToken: '/images/gno_wxdai.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  sDAI: {
    id: 2,
    address: '0xaf204776c7245bF4147c2612BF6e5972Ee483701',
    name: 'sDAI',
    decimal: 18,
    imgToken: '/images/cmc_sdai.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/savings-xdai',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  USDC: {
    id: 3,
    address: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
    name: 'USDC',
    decimal: 6,
    imgToken: '/images/cmc_usdc.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/usd-coin',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  USDT: {
    id: 4,
    address: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
    name: 'USDT',
    decimal: 6,
    imgToken: '/images/cmc_usdt.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/tether',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  EURe: {
    id: 5,
    address: '0xcB444e90D8198415266c6a2724b7900fb12FC56E',
    name: 'EURe',
    decimal: 18,
    imgToken: '/images/gno_eure.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/monerium-eur-money',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  lendWxDAIRMM: {
    id: 6,
    address: '0x7349C9eaA538e118725a6130e0f8341509b9f8A0',
    name: 'lendWxDAIRMM',
    decimal: 18,
    imgToken: '/images/gno_lend_rmm.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  borowWxDAIRMM: {
    id: 7,
    address: '0x6a7CeD66902D07066Ad08c81179d17d0fbE36829',
    name: 'borowWxDAIRMM',
    decimal: 18,
    imgToken: '/images/gno_borow_rmm.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  lendWxDAIRMMv3: {
    id: 8,
    address: '0x0cA4f5554Dd9Da6217d62D8df2816c82bba4157b',
    name: 'lendWxDAIRMMv3',
    decimal: 18,
    imgToken: '/images/phil4sure_wxdairmm.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: true,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  borowWxDAIRMMv3: {
    id: 9,
    address: '0x9908801dF7902675C3FEDD6Fea0294D18D5d5d34',
    name: 'borowWxDAIRMMv3',
    decimal: 18,
    imgToken: '/images/phil4sure_borow_wxdairmm.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/xdai',
    exchange: {},
    show: true,
    history: true,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  lendUSDCRMMv3: {
    id: 10,
    address: '0xeD56F76E9cBC6A64b821e9c016eAFbd3db5436D1',
    name: 'lendUSDCRMMv3',
    decimal: 6,
    imgToken: '/images/phil4sure_usdcrmm.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/usd-coin',
    exchange: {},
    show: true,
    history: true,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  borowUSDCRMMv3: {
    id: 11,
    address: '0x69c731aE5f5356a779f44C355aBB685d84e5E9e6',
    name: 'borowUSDCRMMv3',
    decimal: 6,
    imgToken: '/images/phil4sure_borow_usdcrmm.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/usd-coin',
    exchange: {},
    show: true,
    history: true,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  REG: {
    id: 16,
    address: '0x0aa1e96d2a46ec6beb2923de1e61addf5f5f1dce',
    name: 'REG',
    decimal: 18,
    imgToken: '/images/realt_reg.svg',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/realtoken-ecosystem-governance',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: true,
    isError: false,
    isRefreshing: false,
  },
  RWA: {
    id: 17,
    address: '0x0675e8F4A52eA6c845CB6427Af03616a2af42170',
    name: 'RWA',
    decimal: 9,
    imgToken: '/images/Equity_RWAv2.png',
    abi: ABI_COIN,
    api: null,
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  SOON: {
    id: 18,
    address: '0xaA2C0cf54cB418eB24E7e09053B82C875C68bb88',
    name: 'SOON',
    decimal: 18,
    imgToken: '/images/phil4sure_soon.png',
    abi: ABI_COIN,
    api: null,
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  GNO: {
    id: 20,
    address: '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb',
    name: 'GNO',
    decimal: 18,
    imgToken: '/images/gnosis_gno.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/gnosis',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  BORG: {
    id: 21,
    address: '0x6eefFC9Faa02700a7f0DcC363736CdD71238CAED',
    name: 'BORG',
    decimal: 18,
    imgToken: '/images/cmc_borg.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/swissborg',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  MATIC: {
    id: 22,
    address: '0x7122d7661c4564b7C6Cd4878B06766489a6028A2',
    name: 'MATIC',
    decimal: 18,
    imgToken: '/images/cmc_matic.png',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/matic-network',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  MPS: {
    id: 23,
    address: '0xfa57AA7beED63D03Aaf85fFd1753f5f6242588fb',
    name: 'MPS',
    decimal: 0,
    imgToken: '/images/cmc_mps.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/mt-pelerin-shares',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  WBTC: {
    id: 24,
    address: '0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252',
    name: 'WBTC',
    decimal: 8,
    imgToken: '/images/cmc_wbtc.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/wrapped-bitcoin',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  WETH: {
    id: 25,
    address: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
    name: 'WETH',
    decimal: 18,
    imgToken: '/images/cmc_weth.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/weth',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  wstETH: {
    id: 26,
    address: '0x6C76971f98945AE98dD7d4DFcA8711ebea946eA6',
    name: 'wstETH',
    decimal: 18,
    imgToken: '/images/cmc_wsteth.webp',
    abi: ABI_COIN,
    api: 'https://api.coingecko.com/api/v3/coins/wrapped-steth',
    exchange: {},
    show: true,
    history: false,
    yield: false,
    usd: false,
    isError: false,
    isRefreshing: false,
  },
  // GNO: {
  // 	id: 10,
  // 	address: "0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb",
  // 	name: "GNO",
  // 	decimal: 18,
  // 	imgToken: "/images/cmc_gno.webp",
  // 	abi: ABI_COIN,
  // 	api: "https://api.coingecko.com/api/v3/coins/gnosis",
  // 	usd: 0,
  // 	balance: 0,
  // 	show: true,
  // 	history: false,
  // 	timestamp_convert: 0,
  // 	timestamp_balance: 0,
  // 	error_convert: false,
  // 	error_balance: false,
  // 	refresh_convert: false,
  // 	refresh_balance: false,
  // 	priceHistory: [], // Tableau pour stocker l'historique des prix
  // },
};

const CHAINS_LIST = {
  Ethereum: {
    url: null,
    chainId: 0x1,
    name: 'Ethereum',
    image: '/images/cmc_eth.png',
    rpc: 'https://ethereum.publicnode.com',
    token: 'https://etherscan.io/token/',
    address: 'https://etherscan.io/address/',
    coinList: ETHEREUM_COINS_LIST,
    timestamp: 0,
  },
  Gnosis: {
    url: null,
    chainId: 0x64,
    name: 'Gnosis',
    image: '/images/cmc_gnosis.webp',
    rpc: 'https://rpc.gnosischain.com',
    rpcList: ['https://rpc.gnosischain.com', 'https://rpc.xdaichain.com', 'https://rpc.ankr.com/gnosis'],
    token: 'https://gnosisscan.io/token/',
    address: 'https://gnosisscan.io/address/',
    coinList: GNOSIS_COINS_LIST,
    timestamp: 0,
  },
};

export const SettingsProvider = ({ children }) => {
  try {
    //---------------------------------------
    // settings for the Theme
    //---------------------------------------
    const [themeState, setThemeState] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__themeState')) || false);
    {
      useEffect(() => {
        localStorage.setItem('pitsbi__realt__themeState', JSON.stringify(themeState));
      }, [themeState]);
    }
    //---------------------------------------
    // settings for the user wallets
    //---------------------------------------
    // holderWallets     : list of holder wallets
    // defiWallet       : defiWallet selected in the wallet
    // defiWalletFilter : Filter coins without amount
    //---------------------------------------
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__settingsUser')) || {});

    const [settingsWallet, setSettingsWallet] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsWallet');
      try {
        return storedValue ? JSON.parse(storedValue) : DEFAULT_SETTINGS_WALLET_TOKEN;
      } catch (error) {
        console.error('Error parsing settingsWallet from localStorage:', error);
        return DEFAULT_SETTINGS_WALLET_TOKEN; // Return default settings if there's an error
      }
    });
    const [settingsMarket, setSettingsMarket] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsMarket');
      try {
        // console.log("storedValue init", storedValue);
        const parsedValue = JSON.parse(storedValue); // Parse the stored value first

        // Check if the parsed value is null
        if (parsedValue === null) {
          console.log('storedValue', 'Remove Storage pitsbi__realt__settingsMarket');
          localStorage.removeItem('pitsbi__realt__settingsMarket');
          return DEFAULT_SETTINGS_MARKET;
        } else {
          // Now correctly checking parsedValue for SaleOffers.unselectedCity
          if (!parsedValue.SaleOffers || !parsedValue.SaleOffers.unselectedCity) {
            console.log('storedValue', 'Default settings due to missing city');
            return DEFAULT_SETTINGS_MARKET;
          }
        }

        // If all checks pass, return the parsed value
        return parsedValue;
      } catch (error) {
        console.error('Error parsing settingsMarket from localStorage:', error);
        return DEFAULT_SETTINGS_MARKET; // Return default settings if there's an error
      }
    });
    const [offersMarket, setOffersMarket] = useState({ SwapCatOffersList: [], YamOffersList: [] });
    const [holderWallets, setHolderWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__holderWallets')));
    {
      useEffect(() => {
        // console.log('pitsbi__realt__settingsUser',user)
        localStorage.setItem('pitsbi__realt__settingsUser', JSON.stringify(user));
      }, [user]);
      useEffect(() => {
        localStorage.setItem('pitsbi__realt__settingsWallet', JSON.stringify(settingsWallet));
      }, [settingsWallet]);
      useEffect(() => {
        localStorage.setItem('pitsbi__realt__settingsMarket', JSON.stringify(settingsMarket));
      }, [settingsMarket]);
      useEffect(() => {
        localStorage.setItem('pitsbi__realt__holderWallets', JSON.stringify(holderWallets));
      }, [holderWallets]);
    }
    //---------------------------------------
    // settings for the realTokens List
    //---------------------------------------
    // const [realTokens, setRealTokens] = useState(JSON.parse(sessionStorage.getItem('pitsbi__realt__realTokens')) || null);
    const [realTokens, setRealTokens] = useState(null);
    const [balanceWallets, setBalanceWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__balanceWallets')) || null);
    const [tokensWallets, setTokensWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__Wallets_tokens')) || {});
    const [coinsWallets, setCoinsWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__Wallets_coins')) || {});
    const [wlWallets, setWlWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__Wallets_wl')) || {});
    const [rentsWallets, setRentsWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__Wallets_rents')) || {});
    const [historyWallets, setHistoryWallets] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__historyWallets') || null));
    {
      // useEffect(() => {
      // 	// console.log("save", "pitsbi__realt__realTokens", realTokens);
      // 	sessionStorage.setItem("pitsbi__realt__realTokens", JSON.stringify(realTokens));
      // 	localStorage.removeItem("pitsbi__realt__realTokens");
      // }, [realTokens]);
      useEffect(() => {
        // console.log("save", "pitsbi__realt__balanceWallets", balanceWallets);
        localStorage.setItem('pitsbi__realt__balanceWallets', JSON.stringify(balanceWallets));
      }, [balanceWallets]);

      useEffect(() => {
        localStorage.setItem('pitsbi__realt__Wallets_tokens', JSON.stringify(tokensWallets));
      }, [tokensWallets]);

      useEffect(() => {
        localStorage.setItem('pitsbi__realt__Wallets_coins', JSON.stringify(coinsWallets));
      }, [coinsWallets]);

      useEffect(() => {
        localStorage.setItem('pitsbi__realt__Wallets_wl', JSON.stringify(wlWallets));
      }, [wlWallets]);

      useEffect(() => {
        localStorage.setItem('pitsbi__realt__Wallets_rents', JSON.stringify(rentsWallets));
      }, [rentsWallets]);

      useEffect(() => {
        // console.log("save", "pitsbi__realt__historyWallets", historyWallets);
        localStorage.setItem('pitsbi__realt__historyWallets', JSON.stringify(historyWallets));
      }, [historyWallets]);
    }
    //---------------------------------------
    // settings for apiCoinGecko
    //---------------------------------------
    const [settingsApiCoinGecko, setSettingsApiCoinGecko] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsApiCoinGecko');
      try {
        return storedValue ? JSON.parse(storedValue) : DEFAULT_SETTINGS_API_CoinGecko;
      } catch (error) {
        console.error('Error parsing settingsApiCoinGecko from localStorage:', error);
        return null;
      }
    });
    const [blockchainsClient, setBlockchainsClient] = useState(JSON.parse(sessionStorage.getItem('pitsbi__realt__blockchainsClient')));
    // const [exchangeClient, setExchangeClient] = useState(JSON.parse(localStorage.getItem('pitsbi__realt__exchangeClient')));

    {
      useEffect(() => {
        // console.log("save", "pitsbi__realt__settingsApiCoinGecko", settingsApiCoinGecko);
        localStorage.setItem('pitsbi__realt__settingsApiCoinGecko', JSON.stringify(settingsApiCoinGecko));
      }, [settingsApiCoinGecko]);
      useEffect(() => {
        // console.log("save", "pitsbi__realt__blockchainsClient", blockchainsClient);
        sessionStorage.setItem('pitsbi__realt__blockchainsClient', JSON.stringify(blockchainsClient));
        localStorage.removeItem('pitsbi__realt__blockchainsClient');
      }, [blockchainsClient]);
      // useEffect(() => {
      //   localStorage.setItem('pitsbi__realt__exchangeClient', JSON.stringify(exchangeClient));
      // }, [exchangeClient]);
    }

    //---------------------------------------
    // settings for Trafic page
    //---------------------------------------
    const [settingsTrafic, setSettingsTrafic] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsTrafic');
      try {
        return storedValue ? JSON.parse(storedValue) : DEFAULT_SETTINGS_TRAFIC;
      } catch (error) {
        console.error('Error parsing settingsDashboard from localStorage:', error);
        return null;
      }
    });
    {
      useEffect(() => {
        // console.log("save :", "pitsbi__realt__settingsTrafic", settingsTrafic);
        localStorage.setItem('pitsbi__realt__settingsTrafic', JSON.stringify(settingsTrafic));
      }, [settingsTrafic]);
    }

    //---------------------------------------
    // settings for Dashboard page
    //---------------------------------------
    const [settingsDashboard, setSettingsDashboard] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsDashboard');
      try {
        return storedValue ? JSON.parse(storedValue) : DEFAULT_SETTINGS_DASHBOARD;
      } catch (error) {
        console.error('Error parsing settingsDashboard from localStorage:', error);
        return null;
      }
    });
    {
      useEffect(() => {
        // console.log("save :", "pitsbi__realt__settingsDashboard", settingsDashboard);
        localStorage.setItem('pitsbi__realt__settingsDashboard', JSON.stringify(settingsDashboard));
      }, [settingsDashboard]);
    }

    //---------------------------------------
    // settings for Map page
    //---------------------------------------
    const [settingsMap, setSettingsMap] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsMap');
      try {
        let parsedValue = storedValue ? JSON.parse(storedValue) : DEFAULT_SETTINGS_MAP;

        // Vérification des listes et ajout des valeurs par défaut si elles sont absentes
        if (!parsedValue.propertyColors || parsedValue.version !== DEFAULT_SETTINGS_MAP.version)
          parsedValue.propertyColors = DEFAULT_SETTINGS_MAP.propertyColors;
        if (!parsedValue.rentedColors || parsedValue.version !== DEFAULT_SETTINGS_MAP.version)
          parsedValue.rentedColors = DEFAULT_SETTINGS_MAP.rentedColors;
        else if (parsedValue.rentedColors.length > 4) parsedValue.rentedColors = DEFAULT_SETTINGS_MAP.rentedColors;
        if (!parsedValue.ownedColors || parsedValue.version !== DEFAULT_SETTINGS_MAP.version)
          parsedValue.ownedColors = DEFAULT_SETTINGS_MAP.ownedColors;
        if (!parsedValue.newsColors || parsedValue.version !== DEFAULT_SETTINGS_MAP.version)
          parsedValue.newsColors = DEFAULT_SETTINGS_MAP.newsColors;

        return parsedValue;
      } catch (error) {
        console.error('Error parsing settingsDashboard from localStorage:', error);
        return DEFAULT_SETTINGS_MAP; // Retourner les valeurs par défaut en cas d'erreur
      }
    });
    {
      useEffect(() => {
        // console.log("save :", "pitsbi__realt__settingsMap", settingsMap);
        localStorage.setItem('pitsbi__realt__settingsMap', JSON.stringify(settingsMap));
      }, [settingsMap]);
    }

    //---------------------------------------
    // settings for Stats page
    //---------------------------------------
    const [settingsStats, setSettingsStats] = useState(() => {
      const storedValue = localStorage.getItem('pitsbi__realt__settingsStats');
      try {
        return storedValue ? JSON.parse(storedValue) : DEFAULT_SETTINGS_STATS;
      } catch (error) {
        console.error('Error parsing settingsStats from localStorage:', error);
        return null;
      }
    });
    {
      useEffect(() => {
        // console.log("save :", "pitsbi__realt__settingsStats", settingsStats);
        localStorage.setItem('pitsbi__realt__settingsStats', JSON.stringify(settingsStats));
      }, [settingsStats]);
    }

    //-----------------------------------------
    // funstions : list of all function to verify settings
    //-----------------------------------------
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    useEffect(() => {
      let settingsWellLoaded = true;

      // console.log("Starting Pits BI - Verify Dashboard Settings");
      {
        if (settingsDashboard) {
          const settings = { ...settingsDashboard };
          const keys = Object.keys(DEFAULT_SETTINGS_DASHBOARD);
          keys.forEach((key) => {
            if (!settings[key]) {
              settings[key] = DEFAULT_SETTINGS_DASHBOARD[key];
              // console.log("Starting Pits BI - Verify Dashboard Settings :", "Add [" + key + "]", DEFAULT_SETTINGS_DASHBOARD[key]);
              setSettingsDashboard(settings);
            }
          });
        }
        if (!settingsDashboard || typeof settingsDashboard !== 'object') {
          setSettingsDashboard(DEFAULT_SETTINGS_DASHBOARD);
          // console.log("Starting Pits BI - Initialise Dashboard Settings :", "Create settings");
        }
        // console.log("Starting Pits BI - Verify Dashboard Settings :", settingsWellLoaded ? "Good" : "Not Good");
      }

      // console.log("Starting Pits BI - Verify Stats Settings");
      {
        if (settingsStats) {
          const settings = { ...settingsStats };
          const keys = Object.keys(DEFAULT_SETTINGS_STATS);
          keys.forEach((key) => {
            if (!settings[key]) {
              settings[key] = DEFAULT_SETTINGS_STATS[key];
              // console.log("Starting Pits BI - Verify Stats Settings :", "Add [" + key + "]", DEFAULT_SETTINGS_STATS[key]);
              setSettingsStats(settings);
            }
          });
        }
        if (!settingsStats || typeof settingsStats !== 'object') {
          setSettingsStats(DEFAULT_SETTINGS_STATS);
          // console.log("Starting Pits BI - Initialise Stats Settings :", "Create settings");
        }
        // console.log("Starting Pits BI - Verify Stats Settings :", settingsWellLoaded ? "Good" : "Not Good");
      }

      // console.log("Starting Pits BI - Verify API CoinGecko Settings");
      {
        if (settingsApiCoinGecko) {
          const settings = { ...settingsApiCoinGecko };
          const keys = Object.keys(DEFAULT_SETTINGS_API_CoinGecko);
          keys.forEach((key) => {
            if (!settings[key]) {
              settings[key] = DEFAULT_SETTINGS_API_CoinGecko[key];
              // console.log("Starting Pits BI - Verify API CoinGecko Settings :", "Add [" + key + "]", DEFAULT_SETTINGS_API_CoinGecko[key]);
              setSettingsApiCoinGecko(settings);
            }
          });
        }
        if (!settingsApiCoinGecko || typeof settingsApiCoinGecko !== 'object') {
          setSettingsApiCoinGecko(DEFAULT_SETTINGS_API_CoinGecko);
          // console.log("Starting Pits BI - Initialise API CoinGecko Settings :", "Create settings");
        }
        // console.log("Starting Pits BI - Verify API CoinGecko Settings :", settingsWellLoaded ? "Good" : "Not Good");
      }

      // console.log("Starting Pits BI - Verify WALLET & TOKEN Settings");
      {
        if (settingsWallet) {
          const settings = { ...settingsWallet };
          const keys = Object.keys(DEFAULT_SETTINGS_WALLET_TOKEN);
          keys.forEach((key) => {
            if (!settings[key]) {
              settings[key] = DEFAULT_SETTINGS_WALLET_TOKEN[key];
              // console.log("Starting Pits BI - Verify WALLET & TOKEN Settings :", "Add [" + key + "]", DEFAULT_SETTINGS_WALLET_TOKEN[key]);
              setSettingsWallet(settings);
            }
          });
          if (holderWallets) if (!settings.selectedWallet) settings.selectedWallet = holderWallets[0].address;
        }
        if (!settingsWallet || typeof settingsWallet !== 'object') {
          setSettingsWallet(DEFAULT_SETTINGS_WALLET_TOKEN);
          // console.log("Starting Pits BI - Initialise WALLET & TOKEN Settings :", "Create settings");
        }
        // console.log("Starting Pits BI - Verify WALLET & TOKEN Settings :", settingsWellLoaded ? "Good" : "Not Good");
      }

      // console.log("Starting Pits BI - Verify BlockchainClient Settings");
      {
        if (blockchainsClient) {
          let data = JSON.parse(JSON.stringify(blockchainsClient)); // { ...blockchainsClient };
          if (upgradeDictionary(data, CHAINS_LIST)) {
            setBlockchainsClient(data);
            // console.log("Starting Pits BI - Verify blockchainsClient Settings :", "Update settings");
          }
        } else {
          setBlockchainsClient({ ...CHAINS_LIST });
          // console.log("Starting Pits BI - Initialise blockchainsClient Settings :", "Create settings");
        }
      }
      // console.log("Starting Pits BI - Verify BlockchainClient Settings :", settingsWellLoaded ? "Good" : "Not Good");

      // console.log("Starting Pits BI - Verify RealTokens Settings");
      if (!realTokens) settingsWellLoaded = false;
      else if (realTokens.list.length === 0) settingsWellLoaded = false;

      // console.log("Starting Pits BI - Verify RealTokens Settings :", settingsWellLoaded ? "Good" : "Not Good");

      // console.log("Starting Pits BI - Verify Dashboard Settings");
      // console.log("Starting Pits BI - Verify Dashboard Settings :", settingsWellLoaded ? "Good" : "Not Good");

      setSettingsLoaded(settingsWellLoaded);
    }, [holderWallets]);

    useEffect(() => {
      let settingsWellLoaded = true;

      // console.log("Reload Pits BI - Verify RealTokens Settings");
      if (!realTokens) settingsWellLoaded = false;
      else if (realTokens.list.length === 0) settingsWellLoaded = false;

      // console.log("Reload Pits BI - Verify RealTokens Settings :", settingsWellLoaded ? "Good" : "Not Good");

      setSettingsLoaded(settingsWellLoaded);
    }, [realTokens]);

    const upgradeDictionary = (data, template) => {
      Object.keys(template).forEach((key) => {
        // Check if data has the key and if the types are the same
        if (key in data) {
          if (typeof data[key] === 'object' && typeof template[key] === 'object' && data[key] !== null && template[key] !== null) {
            // If both are objects, recursively upgrade the structure
            data[key] = upgradeDictionary(data[key], template[key]);
          } else if (Array.isArray(data[key]) && Array.isArray(template[key])) {
            // If both are arrays, update each element
            data[key] = data[key].map((d, index) => {
              return upgradeDictionary(d, template[key][index]);
            });
          } else if (typeof data[key] !== typeof template[key] || Array.isArray(data[key]) !== Array.isArray(template[key])) {
            // If types are different, replace with template
            data[key] = template[key];
          }
          // If types are the same and not an object or array, do nothing
        } else {
          // If key doesn't exist in data, copy from template
          data[key] = template[key];
        }
      });
      return data;
    };

    const copyDictionary = (data, template) => {
      Object.keys(template).forEach((key) => {
        if (key in data) {
          if (typeof template[key] === 'object' && template[key] !== null && !Array.isArray(template[key])) {
            // Recursively copy for nested objects
            data[key] = copyDictionary(data[key], template[key]);
          } else if (Array.isArray(template[key])) {
            // Handle arrays: replace with a copy of the template array
            // It assumes the structure of the first element for all elements
            data[key] = template[key].map((item, index) =>
              Array.isArray(data[key]) && index < data[key].length ? copyDictionary(data[key][index], item) : item
            );
          } else {
            // For different types or if data[key] is not an array, replace with template
            data[key] = template[key];
          }
        } else {
          // If key doesn't exist in data, copy from template
          data[key] = template[key];
        }
      });
      return data;
    };

    function getNextRonday(token) {
      // Crée une nouvelle instance de Date pour le prochain lundi
      let nextRonday = new Date(token.rentStartDate.date);

      const todayDayOfWeek = nextRonday.getDay(); // Le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
      let daysToAdd = 8 - todayDayOfWeek; // Nombre de jours à ajouter pour atteindre le prochain lundi

      // Si aujourd'hui est déjà lundi (1), nous voulons le prochain lundi, donc nous ajoutons 7 jours
      if (todayDayOfWeek === 1) {
        daysToAdd = 7;
      }

      nextRonday.setDate(nextRonday.getDate() + daysToAdd); // Définit le jour pour le 1er lundi de rent
      return nextRonday;
    }

    function getNextRondayNow() {
      // Crée une nouvelle instance de Date pour le prochain lundi
      let nextRonday = new Date(Date.now());

      const todayDayOfWeek = nextRonday.getDay(); // Le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
      let daysToAdd = 8 - todayDayOfWeek; // Nombre de jours à ajouter pour atteindre le prochain lundi

      // Si aujourd'hui est déjà lundi (1), nous voulons le prochain lundi, donc nous ajoutons 7 jours
      if (todayDayOfWeek === 1) {
        daysToAdd = 7;
      }

      nextRonday.setDate(nextRonday.getDate() + daysToAdd); // Définit le jour pour le 1er lundi de rent
      return nextRonday;
    }

    function getLastRondayNow() {
      // Crée une nouvelle instance de Date pour le dernier lundi passé
      let lastRonday = new Date(Date.now());
      // lastRondaylastRonday.setDate(lastRonday.getDate() + 6); // debug de l'entrée date

      const todayDayOfWeek = lastRonday.getDay(); // Le jour de la semaine (0 pour dimanche, 1 pour lundi, etc.)
      let daysToSupp = todayDayOfWeek - 1; // Nombre de jours à ajouter pour atteindre le prochain lundi

      // Si aujourd'hui est déjà lundi (1), nous voulons le prochain lundi, donc nous ajoutons 7 jours
      if (todayDayOfWeek === 1) {
        daysToSupp = 0;
      }
      if (todayDayOfWeek === 0) {
        daysToSupp = 6;
      }

      lastRonday.setDate(lastRonday.getDate() - daysToSupp); // Définit le jour pour le 1er lundi de rent
      return lastRonday;
    }

    //-----------------------------------------
    // settings : return Value for for context
    //-----------------------------------------
    const value = {
      FRONT_END_NAME,
      FRONT_END_VERSION,
      //---------------------------------------
      // Can Loading Web Page when settings are load
      //---------------------------------------
      settingsLoaded,
      //---------------------------------------
      // Template TABLE settings
      //---------------------------------------
      DEFAULT_PROPERTY_TYPE,
      DEFAULT_SETTINGS_DASHBOARD,
      DEFAULT_SETTINGS_STATS,
      DEFAULT_SETTINGS_API_CoinGecko,
      DEFAULT_SETTINGS_WALLET_TOKEN,
      CHAINS_LIST,
      //---------------------------------------
      // settings for the Theme
      //---------------------------------------
      themeState, setThemeState,
      //---------------------------------------
      // settings for the user wallets
      //---------------------------------------
      holderWallets, setHolderWallets,
      settingsWallet, setSettingsWallet,
      settingsMarket, setSettingsMarket,
      offersMarket, setOffersMarket,
      user, setUser,
      //---------------------------------------
      // settings for the realTokens List
      //---------------------------------------
      realTokens, setRealTokens,
      balanceWallets, setBalanceWallets,
      tokensWallets, setTokensWallets,
      coinsWallets, setCoinsWallets,
      wlWallets, setWlWallets,
      rentsWallets, setRentsWallets,
      historyWallets, setHistoryWallets,
      getNextRonday,
      getNextRondayNow,
      getLastRondayNow,
      //---------------------------------------
      // settings for apiCoinGecko
      //---------------------------------------
      settingsApiCoinGecko, setSettingsApiCoinGecko,
      blockchainsClient, setBlockchainsClient,
      //---------------------------------------
      // settings for Trafic page
      //---------------------------------------
      settingsTrafic, setSettingsTrafic,
      //---------------------------------------
      // settings for Dashboard page
      //---------------------------------------
      settingsDashboard, setSettingsDashboard,
      //---------------------------------------
      // settings for Stats page
      //---------------------------------------
      settingsStats,
      //---------------------------------------
      // settings for Map page
      //---------------------------------------
      setSettingsStats,
      settingsMap, setSettingsMap,
    };

    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
  } catch (error) {
    console.error('Erreur dans SettingsProvider:', error);
    return null;
  }
};
