import React, { useContext, useEffect } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { Box, Typography } from '@mui/material';
import MinutesTimer from '../Common/MinutesTimer';
// import SecondsTimer from "./SecondsTimer";
import HoursTimer from '../Common/HoursTimer';
import DaysTimer from '../Common/DaysTimer';

function SettingsRealToken(props) {
  const { settingsWallet, setSettingsWallet } = useContext(SettingsContext);

  useEffect(() => {
    let settings = { ...settingsWallet };
    if (
      settings.timerUpdateTokenHolderWallets > settings.timerHistorizeTokenHolderWallets &&
      settings.timerHistorizeTokenHolderWallets >= 12
    )
      timerHistorizeTokenHolderWalletsChange(settings.timerUpdateTokenHolderWallets);
    if (
      settings.timerUpdateTokenFollowWallets > settings.timerHistorizeTokenFollowWallets &&
      settings.timerHistorizeTokenFollowWallets >= 12
    )
      timerHistorizeTokenFollowWalletsChange(settings.timerUpdateTokenFollowWallets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsWallet]);

  if (!settingsWallet) return <></>;

  const timerUpdateTokenHolderWalletsChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      timerUpdateTokenHolderWallets: newValue,
    }));
  };
  const timerHistorizeTokenHolderWalletsChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      timerHistorizeTokenHolderWallets: newValue,
    }));
  };
  const timerDeleteTokenHolderWalletsChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      timerDeleteTokenHolderWallets: newValue,
    }));
  };
  const timerUpdateTokenFollowWalletsChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      timerUpdateTokenFollowWallets: newValue,
    }));
  };
  const timerHistorizeTokenFollowWalletsChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      timerHistorizeTokenFollowWallets: newValue,
    }));
  };
  const timerDeleteTokenFollowWalletsChange = (newValue) => {
    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      timerDeleteTokenFollowWallets: newValue,
    }));
  };

  return (
    <Box
      sx={{
        m: 1,
        p: 1,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h4' sx={{ my: 1, px: 2, color: 'primary.main' }}>
        Manage Holder Wallets
      </Typography>

      <Box
        sx={{
          mx: 1,
          p: 0,
          border: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DaysTimer
          value={settingsWallet.timerUpdateTokenHolderWallets}
          onChange={(newValue) => timerUpdateTokenHolderWalletsChange(newValue)}
          min={1}
          max={7}
          step={1}
          title='Refresh token balance for Holder Wallets every'
        />
        <DaysTimer
          value={settingsWallet.timerDeleteTokenHolderWallets}
          onChange={(newValue) => timerDeleteTokenHolderWalletsChange(newValue)}
          min={1}
          max={90}
          step={1}
          title='Delete archive realToken for Holder Wallets past'
        />
        <DaysTimer
          value={settingsWallet.timerUpdateTokenFollowWallets}
          onChange={(newValue) => timerUpdateTokenFollowWalletsChange(newValue)}
          min={1}
          max={7}
          step={1}
          title='Refresh token balance on empty chain Holder Wallets every'
        />
        {/* <DaysTimer
					value={settingsWallet.timerDeleteTokenFollowWallets}
					onChange={(newValue) => timerDeleteTokenFollowWalletsChange(newValue)}
					min={1}
					max={90}
					step={1}
					title='Delete archive realToken for follow Wallets past'
				/> */}
        <Typography
          variant='body2'
          sx={{
            my: 1,
            color: 'primary.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.7rem',
            fontStyle: 'italic',
            fontWeight: 'bold',
          }}
        >
          (Archive is done for each wallets only if there is updates)
        </Typography>
      </Box>
    </Box>
  );
}

export default SettingsRealToken;
