import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import TokensTableHead from './TableTokens/TokensTableHead';

import { Box, Typography, Paper } from '@mui/material';
import { green, red } from '@mui/material/colors';

function TableHistory() {
  const { realTokens, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE, settingsDashboard, setSettingsDashboard } =
    useContext(SettingsContext);
  const [ethChain, setEthChain] = useState(true);
  const [xdaiChain, setXdaiChain] = useState(true);
  const [rmmChain, setRmmChain] = useState(true);
  const [rmmv3Chain, setRmmv3Chain] = useState(true);
  const [poolChain, setPoolChain] = useState(true);
  const selectedRentType = 'Year';

  // console.log("TableTokens - tokensBalance", settingsDashboard);

  // useEffect(() => {
  // 	if (!ethChain && !xdaiChain && !rmmChain && !rmmv3Chain && !poolChain) setXdaiChain(true);
  // }, [ethChain, xdaiChain, rmmChain, rmmv3Chain, poolChain]);

  if (!realTokens) return <></>;
  if (!settingsDashboard) return <></>;
  if (!holderWallets) return <></>;
  if (!balanceWallets) return <></>;
  if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];
  if (settingsDashboard.selectedPropertyType > 0)
    tokens = tokens.filter((data) => data.propertyType === settingsDashboard.selectedPropertyType);

  let tokensBalance = [{ label: 'TableTokens', chain: 'total', count: 0, rent: 0, total: 0, yield: 0, tokens: [] }];

  tokensBalance.forEach((tokenBalance) => {
    // tokenBalance.label = tokenBalance.ronday.toLocaleDateString();
    if (holderWallets)
      holderWallets
        .filter((holderWallet) => holderWallet.checked)
        .forEach((holderWallet) => {
          for (const wallet of Object.keys(balanceWallets)) {
            if (holderWallet.address === wallet) {
              for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                  if (chain === 'eth' && ethChain) {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
                        if (realToken) {
                          if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                          if (selectedRentType === 'Week')
                            tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                          if (selectedRentType === 'Month') tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                          if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                          tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                          tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                          tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                          let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                          if (existingTokenIndex >= 0) {
                            // console.log("Update eth", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, token.amount);
                            tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                            tokenBalance.tokens[existingTokenIndex].chain.push({
                              id: 'eth',
                              amount: parseFloat(token.amount),
                              wallet: wallet,
                            });
                          } else {
                            // If the token doesn't exist, push a new entry
                            tokenBalance.tokens.push({
                              id: realToken.id,
                              token: realToken,
                              amount: parseFloat(token.amount),
                              chain: [{ id: 'eth', amount: parseFloat(token.amount), wallet: wallet }],
                            });
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                  if (chain === 'xdai' && xdaiChain) {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
                        if (realToken) {
                          if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                          if (selectedRentType === 'Week')
                            tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                          if (selectedRentType === 'Month') tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                          if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                          tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                          tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                          tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                          let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                          if (existingTokenIndex >= 0) {
                            tokenBalance.tokens[existingTokenIndex].amount += parseFloat(token.amount);
                            tokenBalance.tokens[existingTokenIndex].chain.push({
                              id: 'xdai',
                              amount: parseFloat(token.amount),
                              wallet: wallet,
                            });
                          } else {
                            // If the token doesn't exist, push a new entry
                            tokenBalance.tokens.push({
                              id: realToken.id,
                              token: realToken,
                              amount: parseFloat(token.amount),
                              chain: [{ id: 'xdai', amount: parseFloat(token.amount), wallet: wallet }],
                            });
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                  if (chain === 'rmm' && rmmChain) {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                        if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
                          if (realToken) {
                            if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                            if (selectedRentType === 'Week')
                              tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                            if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                            if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                            tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                            tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                            tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                              tokenBalance.tokens[existingTokenIndex].chain.push({
                                id: 'rmm',
                                amount: parseFloat(rmmBalance),
                                wallet: wallet,
                              });
                            } else {
                              // If the token doesn't exist, push a new entry
                              tokenBalance.tokens.push({
                                id: realToken.id,
                                token: realToken,
                                amount: parseFloat(rmmBalance),
                                chain: [{ id: 'rmm', amount: parseFloat(rmmBalance), wallet: wallet }],
                              });
                            }
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                  if (chain === 'rmmv3' && rmmv3Chain) {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
                        if (realToken) {
                          if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                          if (selectedRentType === 'Week') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                          if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                          if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                          tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                          tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                          tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                          let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                          if (existingTokenIndex >= 0) {
                            tokenBalance.tokens[existingTokenIndex].amount += parseFloat(rmmBalance);
                            tokenBalance.tokens[existingTokenIndex].chain.push({
                              id: 'rmmv3',
                              amount: parseFloat(rmmBalance),
                              wallet: wallet,
                            });
                          } else {
                            // If the token doesn't exist, push a new entry
                            tokenBalance.tokens.push({
                              id: realToken.id,
                              token: realToken,
                              amount: parseFloat(rmmBalance),
                              chain: [{ id: 'rmmv3', amount: parseFloat(rmmBalance), wallet: wallet }],
                            });
                          }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                  if (chain === 'pool' && poolChain) {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                        const totalSupply = parseFloat(token.pair.totalSupply);
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                          const poolBalance = (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                          if (realToken) {
                            const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                            const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                            const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
                            if (selectedRentType === 'Day')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                            if (selectedRentType === 'Week')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                            if (selectedRentType === 'Month')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                            if (selectedRentType === 'Year')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                            tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                            tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                            tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                              tokenBalance.tokens[existingTokenIndex].chain.push({
                                id: 'pool',
                                amount: parseFloat(poolBalance),
                                wallet: wallet,
                              });
                            } else {
                              // If the token doesn't exist, push a new entry
                              tokenBalance.tokens.push({
                                id: realToken.id,
                                token: realToken,
                                amount: parseFloat(poolBalance),
                                chain: [{ id: 'pool', amount: parseFloat(poolBalance), wallet: wallet }],
                              });
                            }
                          }
                        }
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                          const poolBalance = (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                          if (realToken) {
                            const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                            const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                            const bonusToken = (realtNbToken * poolBalance) / holderNbToken;
                            if (selectedRentType === 'Day')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                            if (selectedRentType === 'Week')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                            if (selectedRentType === 'Month')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                            if (selectedRentType === 'Year')
                              tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                            tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                            tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                            tokenBalance.yield = parseFloat(token.rent / tokenBalance.total);
                            let existingTokenIndex = tokenBalance.tokens.findIndex((t) => t.id === realToken.id);
                            if (existingTokenIndex >= 0) {
                              // console.log("Update pool", realToken.fullName, tokenBalance.tokens[existingTokenIndex].amount, poolBalance);
                              tokenBalance.tokens[existingTokenIndex].amount += parseFloat(poolBalance);
                              tokenBalance.tokens[existingTokenIndex].chain.push({
                                id: 'pool',
                                amount: parseFloat(poolBalance),
                                wallet: wallet,
                              });
                            } else {
                              // If the token doesn't exist, push a new entry
                              tokenBalance.tokens.push({
                                id: realToken.id,
                                token: realToken,
                                amount: parseFloat(poolBalance),
                                chain: [{ id: 'pool', amount: parseFloat(poolBalance), wallet: wallet }],
                              });
                            }
                          }
                        }
                      });
                  }
                }
              }
            }
          }
        });
  });

  // console.log("TableTokens - tokensBalance", tokensBalance[0]);

  if (!holderWallets || !holderWallets.length > 0)
    return (
      <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: red[700], borderRadius: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: red[700] }}>
            No wallet configured!
          </Typography>
        </Box>
      </Paper>
    );
  if (!tokensBalance[0].tokens.length > 0)
    return (
      <>
        <TokensTableHead
          tokens={tokensBalance[0].tokens}
          chains={{
            ethChain: ethChain,
            setEthChain: setEthChain,
            xdaiChain: xdaiChain,
            setXdaiChain: setXdaiChain,
            rmmChain: rmmChain,
            setRmmChain: setRmmChain,
            rmmv3Chain: rmmv3Chain,
            setRmmv3Chain: setRmmv3Chain,
            poolChain: poolChain,
            setPoolChain: setPoolChain,
          }}
        />
        <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: 'secondary.main', borderRadius: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
            <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
              The selected wallet(s) are empty.
            </Typography>
          </Box>
        </Paper>
      </>
    );
  return (
    <TokensTableHead
      tokens={tokensBalance[0].tokens}
      chains={{
        ethChain: ethChain,
        setEthChain: setEthChain,
        xdaiChain: xdaiChain,
        setXdaiChain: setXdaiChain,
        rmmChain: rmmChain,
        setRmmChain: setRmmChain,
        rmmv3Chain: rmmv3Chain,
        setRmmv3Chain: setRmmv3Chain,
        poolChain: poolChain,
        setPoolChain: setPoolChain,
      }}
    />
  );
}

export default TableHistory;
