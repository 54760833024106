import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow, Select, MenuItem } from '@mui/material';
import FormatedPrice from '../../Common/Formater/FormatedPrice';
import FormatedPercentage from '../../Common/Formater/FormatedPercentage';
// import { useTheme } from "@mui/system";

function WidgetRents() {
  const { realTokens, getNextRonday, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE, settingsDashboard, setSettingsDashboard } =
    useContext(SettingsContext);

  if (!realTokens) return <></>;
  if (!holderWallets) return <></>;
  if (!holderWallets.filter((holderWallet) => holderWallet.checked)[0]) return <></>;
  if (settingsDashboard.selectedRentType === null) return <></>;
  const selectedRentType = settingsDashboard.selectedRentType;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];

  let tokensBalance = [
    { label: 'Ethereum', chain: 'eth', count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
    { label: 'Gnosis', chain: 'xdai', count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
    { label: 'RMM', chain: 'rmm', count: 0, rent: 0, total: 0, yield: 0, nbToken: 0, tokens: [] },
    { label: 'RMM v3', chain: 'rmmv3', count: 0, rent: 0, total: 0, yield: 0, nbToken: 0, tokens: [] },
    { label: 'Levinswap', chain: 'pool', count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
    { label: 'Total', chain: 'total', count: 0, rent: 0, total: 0, nbToken: 0, yield: 0, tokens: [] },
  ];

  const selectedRentTypeChange = (newValue) => {
    setSettingsDashboard((prevSettings) => ({
      ...prevSettings,
      selectedRentType: newValue,
    }));
  };

  if (balanceWallets) {
    tokensBalance.forEach((tokenBalance) => {
      holderWallets
        .filter((holderWallet) => holderWallet.checked)
        .forEach((holderWallet) => {
          for (const wallet of Object.keys(balanceWallets)) {
            if (holderWallet.address === wallet) {
              for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                if (tokenBalance.chain === 'eth' || tokenBalance.chain === 'total') {
                  if (chain === 'eth') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.ethereumContract === token.token.address)[0];
                        if (realToken)
                          if (
                            Date.now() > getNextRonday(realToken).getTime() &&
                            (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
                          ) {
                            if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                            if (selectedRentType === 'Week')
                              tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                            if (selectedRentType === 'Month')
                              tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                            if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                            tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                            tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                            // tokenBalance.nbToken += 1;
                            tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
                          }
                      });
                  }
                }

                if (tokenBalance.chain === 'xdai' || tokenBalance.chain === 'total') {
                  if (chain === 'xdai') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.address)[0];
                        if (realToken)
                          if (
                            Date.now() > getNextRonday(realToken).getTime() &&
                            (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
                          ) {
                            // if (Date.now()-1000*60*60*24*0 > getNextRonday(realToken).getTime())
                            // 	console.log("WidgetRents", getNextRonday(realToken),realToken.shortName,token.amount);
                            if (selectedRentType === 'Day') tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken);
                            if (selectedRentType === 'Week')
                              tokenBalance.count += parseFloat(token.amount * realToken.netRentDayPerToken * 7);
                            if (selectedRentType === 'Month')
                              tokenBalance.count += parseFloat(token.amount * realToken.netRentMonthPerToken);
                            if (selectedRentType === 'Year') tokenBalance.count += parseFloat(token.amount * realToken.netRentYearPerToken);
                            tokenBalance.rent += parseFloat(token.amount * realToken.netRentYearPerToken);
                            tokenBalance.total += parseFloat(token.amount * realToken.tokenPrice);
                            tokenBalance.nbToken += 1;
                            tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
                          }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmm' || tokenBalance.chain === 'total') {
                  if (chain === 'rmm') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.currentATokenBalance) / 10 ** token.reserve.decimals;
                        if (token.reserve.underlyingAsset !== '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d') {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.reserve.underlyingAsset)[0];
                          if (realToken)
                            if (
                              Date.now() > getNextRonday(realToken).getTime() &&
                              (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
                            ) {
                              if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                              if (selectedRentType === 'Week')
                                tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                              if (selectedRentType === 'Month')
                                tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                              if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                              tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                              tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                              // tokenBalance.nbToken += 1;
                              tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
                            }
                        }
                      });
                  }
                }

                if (tokenBalance.chain === 'rmmv3' || tokenBalance.chain === 'total') {
                  if (chain === 'rmmv3') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        const rmmBalance = parseInt(token.amount) / 10 ** token.token.decimals;
                        const realToken = tokens.filter((t) => t.gnosisContract === token.token.id.toLowerCase())[0];
                        if (realToken)
                          if (
                            Date.now() > getNextRonday(realToken).getTime() &&
                            (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
                          ) {
                            if (selectedRentType === 'Day') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken);
                            if (selectedRentType === 'Week')
                              tokenBalance.count += parseFloat(rmmBalance * realToken.netRentDayPerToken * 7);
                            if (selectedRentType === 'Month') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentMonthPerToken);
                            if (selectedRentType === 'Year') tokenBalance.count += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                            tokenBalance.rent += parseFloat(rmmBalance * realToken.netRentYearPerToken);
                            tokenBalance.total += parseFloat(rmmBalance * realToken.tokenPrice);
                            // tokenBalance.nbToken += 1;
                            tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
                          }
                      });
                  }
                }

                if (tokenBalance.chain === 'pool' || tokenBalance.chain === 'total') {
                  if (chain === 'pool') {
                    const tokensList = balanceWallets[wallet].tokens[chain].tokens;
                    if (tokensList)
                      tokensList.forEach((token) => {
                        // console.log("chain", tokenBalance.chain, "walletBalance:", walletBalance.wallet, "token", token);
                        const liquidityTokenBalance = parseFloat(token.liquidityTokenBalance);
                        const totalSupply = parseFloat(token.pair.totalSupply);
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token0.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token0.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token0.liquidity) / totalSupply;
                              const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                              const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                              const bonusToken = realtNbToken * (poolBalance / holderNbToken);
                              if (
                                Date.now() > getNextRonday(realToken).getTime() &&
                                (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
                              ) {
                                if (selectedRentType === 'Day')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                                if (selectedRentType === 'Week')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                                if (selectedRentType === 'Month')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                                if (selectedRentType === 'Year')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                                // tokenBalance.nbToken += 1;
                                tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
                              }
                            }
                        }
                        if (tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0]) {
                          const realToken = tokens.filter((t) => t.gnosisContract === token.pair.token1.id)[0];
                          if (realToken)
                            if (realToken.pool.coinId) {
                              const poolBalance =
                                token.pair.token1.liquidity > 1000
                                  ? (liquidityTokenBalance * token.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * token.pair.token1.liquidity) / totalSupply;
                              const realtNbToken = realToken.pool.nbTokenRealt * realToken.pool.realtRatio;
                              const holderNbToken = realToken.pool.nbTokenRealt * realToken.pool.holderRatio;
                              const bonusToken = realtNbToken * (poolBalance / holderNbToken);
                              if (
                                Date.now() > getNextRonday(realToken).getTime() &&
                                (realToken.rentedUnits > 0 || realToken.netRentYearPerToken)
                              ) {
                                if (selectedRentType === 'Day')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken);
                                if (selectedRentType === 'Week')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentDayPerToken * 7);
                                if (selectedRentType === 'Month')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentMonthPerToken);
                                if (selectedRentType === 'Year')
                                  tokenBalance.count += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                tokenBalance.rent += parseFloat((poolBalance + bonusToken) * realToken.netRentYearPerToken);
                                tokenBalance.total += parseFloat(poolBalance * realToken.tokenPrice * 2);
                                // tokenBalance.nbToken += 1;
                                tokenBalance.yield = parseFloat(tokenBalance.rent / tokenBalance.total);
                              }
                            }
                        }
                      });
                  }
                }
              }
            }
          }
        });
    });
  }
  // console.log("WidgetRents - tokensBalance", tokensBalance[5].nbToken, tokensBalance[5].count);//, tokensBalance[5]);

  if (!tokensBalance[5].count > 0) return <></>;
  return (
    <Paper elevation={3} sx={{ m: 1, minWidth: 150, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Rents - Yields
        </Typography>
        <Select
          sx={{ ml: 0, minWidth: 50, zIndex: 15 }}
          value={selectedRentType}
          onChange={(e) => selectedRentTypeChange(e.target.value)}
          size='small'
        >
          {settingsDashboard.rentType.map((rent) => (
            <MenuItem key={rent} value={rent}>
              {rent}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 200,
              background: 'background.paper',
              '& thead th': { borderWidth: 0 },
              '& tbody tr:last-child td': { borderBottom: 0 },
            }}
            aria-label='sticky table'
          >
            <TableBody sx={{ mt: 0 }}>
              {tokensBalance
                .filter((token) => token.count > 0)
                .map((token) => (
                  <TableRow key={token.label}>
                    <TableCell align='left' sx={{ px: 2, py: 0.5 }}>
                      <Typography
                        variant='body2'
                        component='span'
                        style={{ fontWeight: token.chain === 'total' ? 'bold' : 'normal' }}
                        sx={{ color: token.chain === 'total' ? 'primary.main' : 'inherit' }}
                      >
                        {token.label}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' sx={{ pl: 2, py: 0.5 }}>
                      <FormatedPrice
                        value={token.count}
                        variant={'body2'}
                        color={token.chain === 'total' ? 'primary.main' : 'inherit'}
                        fontWeight={token.chain === 'total' ? 'bold' : 'normal'}
                      />
                    </TableCell>
                    <TableCell align='right' sx={{ pr: 2, py: 0.5 }}>
                      <FormatedPercentage
                        value={token.rent / token.total}
                        variant={'body2'}
                        color={token.chain === 'total' ? 'primary.main' : 'inherit'}
                        fontWeight={token.chain === 'total' ? 'bold' : 'normal'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default WidgetRents;
