import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Radio, Checkbox, Typography } from '@mui/material';

const RadioSettingShort = ({ value, onChange, title }) => {
  const handleChange = (event) => {
    onChange(event);
  };
  const [localSelect, setLocalSelect] = useState(false);

  const handle_setLocalSelect = (value) => {
    setLocalSelect(!localSelect);
    handleChange(!localSelect);
  };

  useEffect(() => {
    let val = value;
    setLocalSelect(val);
  }, [value]);

  return (
    <Box sx={{ mx: 0, p: 0 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
        <Checkbox
          sx={{
            width: 24,
            height: 24,
          }}
          checked={localSelect}
          color='secondary'
          onChange={handle_setLocalSelect}
        />
        <Typography variant='body2' sx={{ ml: 1 }}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

RadioSettingShort.propTypes = {
  value: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default RadioSettingShort;
