import { useTheme } from '@mui/system';
import React, { useContext, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { MyThemeContext } from '../../../context/theme-context';

import { Avatar, Box, Paper, TableCell, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { deepOrange, green, red } from '@mui/material/colors';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FormatedNumber from '../../Common/Formater/FormatedNumber';
import FormatedPercentage from '../../Common/Formater/FormatedPercentage';
import FormatedPrice from '../../Common/Formater/FormatedPrice';

import FormatedNumberDays from '../../Common/Formater/FormatedNumberDays';
import TokenInfos from '../../Common/TokenInfos';
import BuyWeb3Offer from '../BuyOffers/BuyWeb3Offer';

function PurchasesOffersBody({ offer, index }) {
  const { settingsMarket, settingsWallet } = useContext(SettingsContext);
  const { DEFAULT_PROPERTY_TYPE } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [timerId, setTimerId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const theme = useTheme();

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  if (!offer)
    return (
      <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 3, borderColor: 'secondary.main', borderRadius: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
          <Typography variant='h5' sx={{ mx: 10, my: 1, color: 'secondary.main' }}>
            Waiting offer synchronisation ...
          </Typography>
        </Box>
      </Paper>
    );

  const handleOfferClick = (offer) => {
    // if (offer.sc === "yam") window.open("https://yam.realtoken.network/offer/" + offer.id_offer, "_blank");
    if (offer.sc === 'yam') window.open('https://yambyofferid.netlify.app?offerId=' + offer.id_offer, '_blank');
    if (offer.sc === 'swapcat')
      window.open(
        'https://ipfs.io/ipfs/QmZ8wkYtFgGXpHpPGGjQAynS5m8jc4df9KwD4v1pV27yBK/offer.htm?o=' +
          offer.id_offer +
          '&c=100&BUY.' +
          offer.token.symbol +
          '.tokens.on.xdai.blockchain.DEX.OTC',
        '_blank'
      );
  };

  const cursorIcon =
    theme.palette.mode === 'dark' ? 'url(/images/AddShoppingCartIconDark.png),auto' : 'url(/images/AddShoppingCartIcon.png),auto';

  const logo_pitsbi_sizeW = 20;
  const logo_pitsbi_sizeH = 25;

  return (
    <>
      {openInfo && <TokenInfos open={openInfo} setOpen={setOpenInfo} token={offer.token} />}
      {open && <BuyWeb3Offer open={open} setOpen={setOpen} offer={offer} index={index} />}
      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell
          align='center'
          sx={{ pl: 0, pr: 0, py: 1, cursor: 'pointer' }}
          component='th'
          scope='row'
          onClick={() => window.open(offer.token.marketplaceLink, '_blank')}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <OpenInNewIcon color={'primary'} />
          </Box>
        </TableCell>

        <TableCell
          align='center'
          sx={{ pl: 0, pr: 0, py: 1, cursor: 'pointer' }}
          component='th'
          scope='row'
          onClick={() => setOpenInfo(true)}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <AddchartOutlinedIcon color={'primary'} />
          </Box>
        </TableCell>

        {/* <TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, cursor: "pointer" }} onClick={() => handleOfferClick(offer)}> */}
        <TableCell align='left' sx={{ pl: 0, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Tooltip title={'offer n°' + offer.id_offer} placement='top'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                component='img'
                alt='SmartContract'
                src={offer.sc === 'yam' ? '/images/yam.png' : '/images/swapcat.png'}
                sx={{ m: 0, width: 24, height: 24 }}
              />
            </Box>
          </Tooltip>
        </TableCell>

        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Tooltip title={offer.fullName} placement='top'>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
              <Typography variant='body2' sx={{ color: offer.whiteList ? green[400] : red[700] }}>
                {offer.shortName}
              </Typography>
              {settingsWallet?.selectedWallet === offer.holder_address && <WorkspacePremiumIcon sx={{ ml: 1 }} color={'primary'} />}
            </Box>
          </Tooltip>
        </TableCell>

        <TableCell align='left' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Typography variant='body2' sx={{ color: offer.whiteList ? green[400] : red[700] }}>
            {offer.city}
          </Typography>
        </TableCell>

        {settingsMarket.PurchaseOffers.showPosition && (
          <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
            {offer.position === 0 || offer.position >= 0.0001 ? (
              <FormatedNumber value={offer.position} color={'primary.main'} digit={4} />
            ) : (
              <FormatedNumber value={offer.position} color={'primary.main'} digit={10} />
            )}
          </TableCell>
        )}

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPrice value={offer.tokenPrice} color={'primary.main'} />
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPercentage value={offer.annualPercentageYield / 100} color={'primary.main'} />
        </TableCell>

        <TableCell align='center' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
            <Tooltip title={offer.token_to_pay_name} placement='top'>
              <Avatar alt='coin' src={offer.token_to_pay_img} sx={{ m: 0, width: 20, height: 20 }} />
            </Tooltip>
          </Box>
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          {offer.token_amount === 0 || offer.token_amount >= 0.01 ? (
            <FormatedNumber value={offer.token_amount} color={'secondary.main'} />
          ) : (
            <FormatedNumber value={offer.token_amount} color={'secondary.main'} digit={10} />
          )}
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
            <>
              {offer.isRealT_price && (
                <Tooltip title={`This offer is aligned with the realT repurchase value`} enterDelay={1000} placement='top'>
                  <Avatar
                    alt='Logo_realT_price'
                    src='/images/yam.png'
                    sx={{ mr: 0.5, p: 0, width: logo_pitsbi_sizeW * 0.8, height: logo_pitsbi_sizeH * 0.8, borderRadius: 0 }}
                  />
                </Tooltip>
              )}
              <FormatedPrice value={offer.token_value_usd} color={'secondary.main'} />
            </>
          </Box>
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPercentage value={offer.token_newYield} color={'primary.main'} />
        </TableCell>

        <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
          <FormatedPercentage
            value={offer.token_ratio}
            color={offer.token_ratio < 1 ? (offer.token_ratio < 0.8 ? red[700] : deepOrange[200]) : green[500]}
          />
        </TableCell>

        {settingsMarket.PurchaseOffers.showRented && (
          <Tooltip title={`Nb Unit(s) ${offer.rentedUnits}/${offer.totalUnits}`} placement='top'>
            <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
              <FormatedPercentage value={offer.token_rented} color={offer.token_rented < 1 ? red[500] : green[500]} />
            </TableCell>
          </Tooltip>
        )}

        {settingsMarket.PurchaseOffers.showLastPricingDays && (
          <>
            <TableCell align='right' sx={{ pl: 1, pr: 0, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
              <FormatedNumberDays
                value={offer.last_pricing_days}
                color={offer.last_pricing_days > 365 * 2 ? red[500] : offer.last_pricing_days > 365 ? deepOrange[200] : green[500]}
              />
            </TableCell>
            <TableCell
              align='right'
              sx={{ pl: 1, pr: settingsMarket.PurchaseOffers.showRentStart ? 0 : 1, py: 1, cursor: cursorIcon }}
              onClick={() => setOpen(true)}
            >
              <FormatedNumber
                value={offer.nb_pricing}
                // color={offer.last_pricing_days > 365 * 2 ? red[500] : offer.last_pricing_days > 365 ? deepOrange[200] : green[500]}
              />
            </TableCell>
          </>
        )}

        {settingsMarket.PurchaseOffers.showRentStart && (
          <TableCell align='right' sx={{ pl: 1, pr: 1, py: 1, cursor: cursorIcon }} onClick={() => setOpen(true)}>
            <Typography variant='body2'>{offer.rent_start}</Typography>
          </TableCell>
        )}
      </TableRow>
    </>
  );
}

export default PurchasesOffersBody;
