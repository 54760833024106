import { SettingsContext } from '../context/settings-context';
import React, { useContext, useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import { MyThemeContext } from '../context/theme-context';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Typography, IconButton, Tabs, Tab, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TokenIcon from '@mui/icons-material/Token';

import DrawerComponent from '../components/Stats/DrawerComponent';
import Header from '../components/Header/Header';
import RealTokens from '../components/Stats/RealTokens';
import Wallets from '../components/Stats/Wallets';
// import Updates from "../components/Stats/Updates";
import LoadingWebSite from '../components/Common/LoadingWebSite';

const tabs = [
  { id: 0, name: 'RealTokens', component: <RealTokens />, icon: <TokenIcon sx={{ width: 45, height: 45 }} /> },
  // { id:1,name: "Updates",  component: <Updates />, icon: <TokenIcon sx={{ width: 45, height: 45 }} /> },
  { id: 2, name: 'RMM', component: <Wallets />, icon: <AccountBalanceWalletIcon sx={{ width: 45, height: 45 }} /> },
];

const TITLE = "Pit's BI - Stats";

function Stats() {
  const { settingsLoaded } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = TITLE;
  }, []);

  useEffect(() => {
    // Lire le tabValue depuis l'URL et le définir
    const params = new URLSearchParams(location.search);
    const tabFromUrl = params.get('tab');
    if (tabFromUrl !== null) {
      setTabValue(parseInt(tabFromUrl, 10));
    }
  }, [location.search]);

  const handleTabClick = (index) => {
    setTabValue(index);
    navigate(`/stats?tab=${index}`);
  };

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));
  return (
    <Box>
      <Header />
      {!settingsLoaded && <LoadingWebSite />}
      {settingsLoaded && (
        <Box>
          <Box sx={{ marginBottom: isMatch ? 0 : 0 }}>
            <DrawerComponent
              key='StatsDrawer'
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              handleTabClick={handleTabClick}
              tabs={tabs}
            />
          </Box>
          <Box sx={{ height: '64px' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            {isMatch && (
              <IconButton color='inherit' onClick={() => setOpenDrawer(true)}>
                <MenuIcon />
                <Typography variant='h6' color='inherit' sx={{ ml: 1 }}>
                  {tabs[tabValue].name}
                </Typography>
              </IconButton>
            )}
            {!isMatch && (
              <Tabs
                key='StatsTabs'
                value={tabValue}
                variant='fullWidth'
                textColor='inherit'
                indicatorColor='secondary'
                sx={{ width: 1, ml: 'auto' }}
              >
                {tabs.map((tab, index) => (
                  <Tab key={'StatsTab.' + tab.id} tabIndex={index} label={tab.name} value={index} onClick={() => handleTabClick(index)} />
                ))}
              </Tabs>
            )}
          </Box>
          <Box justifyContent={'center'}>{tabs.filter((tab, index) => index === tabValue).map((tab, index) => tab.component)}</Box>
        </Box>
      )}
    </Box>
  );
}

export default Stats;
