import React, { useContext, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { SnackbarContext } from '../../../context/customsnackbar-context';
import { useTheme } from '@mui/system';

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function SettingsBlockchain() {
  const { blockchainsClient, setBlockchainsClient, settingsWallet, setSettingsWallet, CHAINS_LIST } = useContext(SettingsContext);
  const { openSnackbar } = useContext(SnackbarContext);
  const [selectedBlockchain, setSelectedBlockchain] = useState(blockchainsClient[settingsWallet.selectedBlockchain]);
  const settings = { ...settingsWallet };

  const isActiveResetButton = !(JSON.stringify(blockchainsClient) === JSON.stringify(CHAINS_LIST));
  // console.log("isActiveResetButton", isActiveResetButton);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [sortBy, setSortBy] = useState({ field: 'index', order: 'desc' });
  const theme = useTheme();

  const handleSort = (field) => {
    setSortBy((prevSortBy) => ({
      field,
      order: prevSortBy.field === field && prevSortBy.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  if (!blockchainsClient) return <></>;
  if (!settingsWallet.selectedBlockchain) return <></>;
  if (!selectedBlockchain) {
    setSelectedBlockchain(blockchainsClient['Gnosis']);
    settings.selectedBlockchain('Gnosis');
    setSettingsWallet(settings);
  }

  const sortedCoinList = [...Object.values(selectedBlockchain.coinList)].sort((a, b) => {
    const order = sortBy.order === 'asc' ? 1 : -1;
    return order * (a[sortBy.field] > b[sortBy.field] ? 1 : -1);
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    setSettingsWallet((prevSettings) => ({
      ...prevSettings,
      rowsPerPage: event.target.value,
    }));
  };

  const handleResetSort = () => {
    // Logique de réinitialisation du tri
    setSortBy({ field: 'index', order: 'desc' });
  };

  const resetBlockchainClient = () => {
    if (isActiveResetButton) {
      setBlockchainsClient(CHAINS_LIST);
      setSelectedBlockchain(CHAINS_LIST[settingsWallet.selectedBlockchain]);
    }
    openSnackbar('You have reset the Blockchain settings.', 'success');
  };

  const toggleShow = (coin) => {
    let newBlockchains = { ...blockchainsClient };
    let newSelectedBlockchain = { ...selectedBlockchain, coinList: { ...selectedBlockchain.coinList } };

    newSelectedBlockchain.coinList[coin.name].show = !newSelectedBlockchain.coinList[coin.name].show;
    if (!newSelectedBlockchain.coinList[coin.name].show) {
      newSelectedBlockchain.coinList[coin.name].history = false;
      newSelectedBlockchain.coinList[coin.name].usd = false;
    }

    newBlockchains[newSelectedBlockchain.name] = newSelectedBlockchain;
    setSelectedBlockchain(newSelectedBlockchain);
    setBlockchainsClient(newBlockchains);
  };
  const toggleHistory = (coin) => {
    let newBlockchains = { ...blockchainsClient };
    let newSelectedBlockchain = { ...selectedBlockchain, coinList: { ...selectedBlockchain.coinList } };

    newSelectedBlockchain.coinList[coin.name].history = !newSelectedBlockchain.coinList[coin.name].history;
    if (newSelectedBlockchain.coinList[coin.name].history) {
      newSelectedBlockchain.coinList[coin.name].usd = false;
    }

    newBlockchains[newSelectedBlockchain.name] = newSelectedBlockchain;
    setSelectedBlockchain(newSelectedBlockchain);
    setBlockchainsClient(newBlockchains);
  };
  const toggleUsd = (coin) => {
    let newBlockchains = { ...blockchainsClient };
    let newSelectedBlockchain = { ...selectedBlockchain, coinList: { ...selectedBlockchain.coinList } };

    newSelectedBlockchain.coinList[coin.name].usd = !newSelectedBlockchain.coinList[coin.name].usd;
    if (newSelectedBlockchain.coinList[coin.name].usd) {
      newSelectedBlockchain.coinList[coin.name].history = false;
    }

    newBlockchains[newSelectedBlockchain.name] = newSelectedBlockchain;
    setSelectedBlockchain(newSelectedBlockchain);
    setBlockchainsClient(newBlockchains);
    console.log('newSelectedBlockchain', newSelectedBlockchain);
    console.log('newBlockchains', newBlockchains);
  };

  // console.log(theme.palette.primary);

  return (
    <Box
      sx={{
        mx: 1,
        mt: 1,
        p: 1,
        border: 1,
        borderColor: 'primary.main',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h4' sx={{ my: 1, color: 'primary.main', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        Blockchains Configuration
      </Typography>

      <Box sx={{ mt: 0, mx: 'auto', width: 1, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <Select
          sx={{ width: 200 }}
          size='small'
          id='selectedBlockchain'
          value={selectedBlockchain.name}
          variant='outlined'
          onChange={(e) => {
            setSelectedBlockchain(blockchainsClient[e.target.value]);
          }}
          displayEmpty
        >
          {blockchainsClient &&
            Object.values(blockchainsClient).map((chain) => (
              <MenuItem sx={{}} value={chain.name} key={chain.name}>
                <Box sx={{ px: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ListItemIcon>
                    <Avatar alt={chain.name} src={chain.image} sx={{ width: 25, height: 25 }} />
                  </ListItemIcon>
                  <Typography sx={{}} variant='body2'>
                    {chain.name}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
        </Select>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20]}
          component='div'
          count={sortedCoinList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Button variant='contained' size='small' onClick={() => handleResetSort()} sx={{ borderRadius: 2 }}>
          Reset Sort
        </Button>
      </Box>

      <Paper sx={{ mt: 0, width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ mt: 2 }}>
          <Table sx={{ background: 'background.paper' }} aria-label='sticky table'>
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light,
                  '&:last-child th': { borderBottom: 0 },
                }}
              >
                <TableCell align='center' onClick={() => handleSort('index')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Nb
                    {sortBy.field === 'index' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center' onClick={() => handleSort('name')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Name
                    {sortBy.field === 'name' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center' onClick={() => handleSort('address')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Address
                    {sortBy.field === 'address' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center'>adu</TableCell>
                <TableCell align='center' onClick={() => handleSort('decimal')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Decimal
                    {sortBy.field === 'decimal' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center' onClick={() => handleSort('api')}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    API
                    {sortBy.field === 'api' &&
                      (sortBy.order === 'asc' ? (
                        <ArrowUpwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ) : (
                        <ArrowDownwardIcon sx={{ ml: 0.5, width: 15, height: 22 }} />
                      ))}
                  </Box>
                </TableCell>
                <TableCell align='center'>Show</TableCell>
                <TableCell align='center'>History</TableCell>
                <TableCell align='center'>USD</TableCell>
              </TableRow>
            </TableHead>

            <TableBody sx={{ p: 0 }}>
              {sortedCoinList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((coin, index) => (
                <TableRow key={coin.name} sx={{ height: 40, p: 0, background: index % 2 === 0 ? 'background.paper' : 'action.hover' }}>
                  <TableCell align='center'>{coin.id}</TableCell>
                  <TableCell align='center'>{coin.name}</TableCell>
                  <TableCell align='left'>
                    {coin.address ? (coin.address.substring(0, 2) === '0x' ? coin.address.toLowerCase() : coin.address) : ''}
                  </TableCell>
                  <TableCell align='left'></TableCell>
                  <TableCell align='center'>{coin.decimal}</TableCell>
                  <TableCell align='left'>{coin.api}</TableCell>
                  <TableCell align='center'>
                    <Checkbox sx={{ width: 18, height: 16 }} checked={coin.show} onClick={() => toggleShow(coin)} />
                  </TableCell>
                  <TableCell align='center'>
                    <Checkbox sx={{ width: 18, height: 16 }} checked={coin.history} onClick={() => toggleHistory(coin)} />
                  </TableCell>
                  <TableCell align='center'>
                    <Checkbox sx={{ width: 18, height: 16 }} checked={coin.usd} onClick={() => toggleUsd(coin)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {/* Init Blockchains List */}
      <Box
        sx={{
          m: 2,
          p: 1,
          border: 0,
          borderColor: 'primary.main',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <>
          {isActiveResetButton && (
            <Button variant='contained' onClick={() => resetBlockchainClient()}>
              Reset Blockchains Configurations
            </Button>
          )}
          {!isActiveResetButton && (
            <>
              <Button variant='contained' disabled={true} onClick={() => resetBlockchainClient()}>
                Reset Blockchains Configurations
              </Button>
              <Typography
                variant='body2'
                sx={{
                  my: 1,
                  color: 'primary.main',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '0.7rem',
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                }}
              >
                (Please, reload the web site to activate the Button)
              </Typography>
            </>
          )}
        </>
      </Box>
    </Box>
  );
}

export default SettingsBlockchain;
