import React, { useContext, useEffect, useState, useCallback, memo } from 'react';
import { SettingsContext } from '../context/settings-context';
import { MyThemeContext } from '../context/theme-context';
import { RealTokensContext } from '../context/realTokens-context';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Typography, IconButton, Tabs, Tab, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import TokenIcon from '@mui/icons-material/Token';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import PurchasesOffers from '../components/Market/PurchasesOffers';
import SalesOffers from '../components/Market/SalesOffers';
import DrawerComponent from '../components/Market/DrawerComponent';
import Header from '../components/Header/Header';
import LoadingWebSite from '../components/Common/LoadingWebSite';

// 1. Constantes en dehors du composant
const TITLE = "Pit's BI - Market";
const TIMEOUT_REFRESH = 15 * 1000;
const TABS = [
  {
    id: 0,
    name: 'Purchases Offers',
    component: <PurchasesOffers key='PurchasesOffers' />,
    icon: <TokenIcon sx={{ width: 45, height: 45 }} />,
  },
  {
    id: 1,
    name: 'Sales Offers',
    component: <SalesOffers key='SalesOffers' />,
    icon: <AccountBalanceWalletIcon sx={{ width: 45, height: 45 }} />,
  },
];

function Market() {
  // 2. Regrouper les états liés
  const [uiState, setUiState] = useState({
    openDrawer: false,
    tabValue: 1,
  });

  const [updateState, setUpdateState] = useState({
    counter: 1,
    num: 0,
  });

  // 3. Hooks contextuels regroupés
  const { settingsLoaded } = useContext(SettingsContext);
  const { themeContext } = useContext(MyThemeContext);
  const { setToUpdateTask } = useContext(RealTokensContext);

  const location = useLocation();
  const navigate = useNavigate();
  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  // 4. Gestion du titre
  useEffect(() => {
    document.title = TITLE;
  }, []);

  // 5. Gestion des tabs améliorée
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabFromUrl = params.get('tab');

    setUiState((prev) => ({
      ...prev,
      tabValue: tabFromUrl !== null ? parseInt(tabFromUrl, 10) : 1,
    }));

    if (tabFromUrl === null) {
      navigate(`/market?tab=1`);
    }
  }, [location.search, navigate]);

  // 6. Gestion des mises à jour optimisée
  useEffect(() => {
    const addTask = (taskName) => {
      setToUpdateTask((prevTasks) => {
        if (prevTasks.some((task) => task.name === taskName)) {
          return prevTasks;
        }
        return [
          ...prevTasks,
          {
            name: taskName,
            timeStart: new Date().getTime(),
            timeStop: 0,
          },
        ];
      });
    };

    addTask('update_Market');

    const timer = setTimeout(() => {
      setUpdateState((prev) => ({
        counter: prev.counter + 1,
        num: prev.counter + 1,
      }));
    }, TIMEOUT_REFRESH);

    return () => clearTimeout(timer);
  }, [updateState.num, setToUpdateTask]);

  // 7. Handlers optimisés
  const handleTabClick = useCallback(
    (index) => {
      setUiState((prev) => ({ ...prev, tabValue: index }));
      navigate(`/market?tab=${index}`);
    },
    [navigate]
  );

  const handleDrawerToggle = useCallback(() => {
    setUiState((prev) => ({ ...prev, openDrawer: !prev.openDrawer }));
  }, []);

  // 8. Rendu conditionnel optimisé
  const renderTabs = () => {
    if (isMatch) {
      return (
        <IconButton color='inherit' onClick={handleDrawerToggle}>
          <MenuIcon />
          <Typography variant='h6' color='inherit' sx={{ ml: 1 }}>
            {TABS[uiState.tabValue].name}
          </Typography>
        </IconButton>
      );
    }

    return (
      <Tabs
        key='MarketTabs'
        value={uiState.tabValue}
        variant='fullWidth'
        textColor='inherit'
        indicatorColor='secondary'
        sx={{ width: 1, ml: 'auto' }}
      >
        {TABS.map((tab, index) => (
          <Tab key={`MarketTabs_${index}`} label={tab.name} value={index} onClick={() => handleTabClick(index)} />
        ))}
      </Tabs>
    );
  };

  const renderContent = () => {
    if (!settingsLoaded) {
      return <LoadingWebSite />;
    }

    return (
      <Box>
        <Box sx={{ width: 1, height: '64px' }} display='flex' alignItems='center' justifyContent='center'>
          {renderTabs()}
        </Box>
        <Box justifyContent='center'>{TABS[uiState.tabValue].component}</Box>
      </Box>
    );
  };

  return (
    <Box>
      <Header />
      {isMatch && (
        <Box sx={{ marginBottom: 0 }}>
          <DrawerComponent
            key='MarketDrawer'
            openDrawer={uiState.openDrawer}
            setOpenDrawer={(open) => setUiState((prev) => ({ ...prev, openDrawer: open }))}
            handleTabClick={handleTabClick}
            tabs={TABS}
          />
        </Box>
      )}
      {renderContent()}
    </Box>
  );
}

export default memo(Market);
