import React, { useContext, useState, useEffect } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { List, ListItemText, Collapse, Typography, Box, ListItemButton, Avatar, IconButton, Switch, FormControlLabel } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function HorizontalCollapsibleTokenList() {
  const { realTokens, balanceWallets, holderWallets } = useContext(SettingsContext);
  const [tokens, setTokens] = useState(null);
  const [listData, setListData] = useState(null);
  const [openMonth, setOpenMonth] = useState({});
  const [showPosition, setShowPosition] = useState(false);

  useEffect(() => {
    if (realTokens) {
      const realtokensList = JSON.parse(JSON.stringify(realTokens.list));
      if (realtokensList) {
        let tokens = realtokensList.filter(
          (data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'
        );

        // Logic to add isNew key
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        if (balanceWallets) {
          tokens.forEach((token) => {
            token.position = 0;
            holderWallets
              .filter((holderWallet) => holderWallet.checked)
              .forEach((holderWallet) => {
                for (const wallet of Object.keys(balanceWallets)) {
                  if (holderWallet.address === wallet) {
                    for (const chain of Object.keys(balanceWallets[wallet].tokens)) {
                      if (chain === 'eth') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseFloat(object.amount);
                            token.position += tokenAmount;
                          });
                        }
                      }

                      if (chain === 'xdai') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseFloat(object.amount);
                            token.position += tokenAmount;
                          });
                        }
                      }

                      if (chain === 'rmm') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseInt(object.currentATokenBalance) / 10 ** object.reserve.decimals;
                            token.position += tokenAmount;
                          });
                        }
                      }

                      if (chain === 'rmmv3') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter((object) => object.token.id === token.gnosisContract);
                          tokensList.forEach((object) => {
                            const tokenAmount = parseInt(object.amount) / 10 ** object.token.decimals;
                            token.position += tokenAmount;
                          });
                        }
                      }

                      if (chain === 'pool') {
                        const tokens = balanceWallets[wallet]?.tokens[chain]?.tokens;
                        if (tokens) {
                          const tokensList = tokens.filter(
                            (object) => object.pair.token0.id === token.gnosisContract || object.pair.token1.id === token.gnosisContract
                          );

                          tokensList.forEach((object) => {
                            const liquidityTokenBalance = parseFloat(object.liquidityTokenBalance);
                            const totalSupply = parseFloat(object.pair.totalSupply);

                            if (token.gnosisContract === object.pair.token0.id) {
                              const tokenAmount =
                                object.pair.token0.liquidity > 1000
                                  ? (liquidityTokenBalance * object.pair.token0.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * object.pair.token0.liquidity) / totalSupply;
                              token.position += parseFloat(tokenAmount);
                            }

                            if (token.gnosisContract === object.pair.token1.id) {
                              const tokenAmount =
                                object.pair.token1.liquidity > 1000
                                  ? (liquidityTokenBalance * object.pair.token1.liquidity) / 10 ** 18 / totalSupply
                                  : (liquidityTokenBalance * object.pair.token1.liquidity) / totalSupply;
                              token.position += parseFloat(tokenAmount);
                            }
                          });
                        }
                      }
                    }
                  }
                }
              });
          });
        }

        tokens = tokens.map((token) => {
          if (!token.initialLaunchDate) {
            console.log(token.shortName);
            token.initialLaunchDate = token.rentStartDate;
          }
          const initialDate = new Date(token.initialLaunchDate.date);
          return { ...token, isNew: initialDate > thirtyDaysAgo };
        });

        setTokens(tokens);
      }
    }
  }, [realTokens]);

  useEffect(() => {
    if (tokens) {
      const filteredTokens = (tokens || []).filter((token) => {
        const launchDate = new Date(token.initialLaunchDate.date);
        let sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
        sixMonthsAgo = new Date(sixMonthsAgo.getFullYear(), sixMonthsAgo.getMonth(), 1);
        return launchDate >= sixMonthsAgo;
      });

      const groupedByMonth = filteredTokens.reduce((acc, token) => {
        const monthYear = `${token.initialLaunchDate.date.slice(0, 7)}`; // "YYYY-MM"
        if (!acc[monthYear]) {
          acc[monthYear] = { count: 0, label: monthYear, tokens: [], totalInvestment: 0, position: 0 };
        }
        acc[monthYear].count += 1;
        acc[monthYear].totalInvestment += token.totalInvestment || 0;
        acc[monthYear].position += token.position || 0;
        acc[monthYear].tokens.push(token);
        return acc;
      }, {});
      // console.table(groupedByMonth);

      let listData = Object.values(groupedByMonth).map((item) => {
        // Trier les tokens par la propriété rent_start dans chaque item
        item.tokens.sort((a, b) => {
          const dateComparison = a.initialLaunchDate.date.localeCompare(b.initialLaunchDate.date);
          if (dateComparison === 0) {
            // If the dates are the same, sort by seriesNumber
            return a.seriesNumber - b.seriesNumber;
          }
          return dateComparison;
        });
        // console.log(item.label,item.tokens)
        return item;
      });
      listData = listData.sort((a, b) => a.label.localeCompare(b.label));

      setListData(listData);
    }
  }, [tokens]);

  if (!realTokens) return null;
  if (!tokens) return null;
  if (!listData) return null;

  const handleClick = (month) => {
    setOpenMonth((prevState) => ({
      [month]: !prevState[month],
    }));
  };

  const handleTokenClick = (marketplaceLink) => {
    window.open(marketplaceLink, '_blank');
  };

  // console.log("tokens", tokens[0]);

  const textFontSize = '0.8rem';
  return (
    <Box
      sx={{ mt: 1, display: 'flex', flexDirection: 'column', overflowX: 'auto', border: 1, borderRadius: 4, borderColor: 'primary.main' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
        <FormControlLabel
          control={<Switch checked={showPosition} onChange={() => setShowPosition(!showPosition)} />}
          label={
            showPosition ? 'Hide the number of realTokens purchased each month' : 'Display the number of realTokens purchased each month'
          }
          sx={{ my: 0.5 }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
        <List component='nav' sx={{ display: 'flex' }}>
          {listData.map((monthData) => (
            <React.Fragment key={monthData.label}>
              <ListItemButton
                onClick={() => handleClick(monthData.label)}
                sx={{
                  flexDirection: 'column',
                  alignItems: 'center',
                  // bgcolor: monthData.label === openMonth[monthData.label] ? "primary.main" : "inherit",
                  // color: monthData.label === openMonth[monthData.label] ? "primary.contrastText" : "inherit",
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                }}
              >
                <ListItemText
                  primary={monthData.label}
                  secondary={
                    <>
                      {`${monthData.count} Properties`}
                      <br />
                      {`${(monthData.totalInvestment / 1000000).toFixed(3)} M$`}
                      {showPosition && (
                        <>
                          <br />
                          {`${monthData.position.toFixed(1)} token(s)`}
                        </>
                      )}
                    </>
                  }
                />
                {openMonth[monthData.label] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openMonth[monthData.label]} timeout='auto' unmountOnExit>
                <List component='div' disablePadding sx={{ display: 'flex', flexDirection: 'column' }}>
                  {monthData.tokens.map((token, idx) => (
                    <ListItemButton key={idx} sx={{ px: 1 }} onClick={() => handleTokenClick(token.marketplaceLink)}>
                      {/* <Typography variant='body2' sx={{ ml: 1, color: "primary.main", fontSize: textFontSize }}>
												{idx + 1}
											</Typography> */}
                      <Typography variant='body2' sx={{ ml: 1, color: 'primary.main', fontSize: textFontSize }}>
                        {token.initialLaunchDate.date.slice(0, 10)}
                      </Typography>
                      {showPosition && (
                        <Typography variant='body2' sx={{ ml: 1, color: 'primary.main', fontSize: textFontSize }}>
                          {token.position ? token.position.toFixed(1) : (0).toFixed(1)}
                        </Typography>
                      )}
                      <Typography variant='body2' sx={{ ml: 1, color: 'primary.main', fontSize: textFontSize }}>
                        {token.tokenPrice}$
                      </Typography>
                      <Typography variant='body2' sx={{ ml: 1, color: 'primary.main', fontSize: textFontSize }}>
                        {token.annualPercentageYield.toFixed(2)}%
                      </Typography>
                      <Typography variant='body2' sx={{ ml: 1, color: 'primary.main', fontSize: textFontSize }}>
                        {(token.totalInvestment / 1000).toFixed(0)}k$
                      </Typography>
                      {/* totalInvestment */}
                      <Typography variant='body2' sx={{ ml: 1, color: 'primary.main', fontSize: textFontSize }}>
                        {token.fullName}
                      </Typography>
                      {/* {token.isNew && (
												<IconButton sx={{ ml: 1 }}>
													<Avatar
														alt='Logo'
														src='/images/new_gpt.png'
														sx={{ ml: 0, width: 25, height: 25, imgProps: { style: { filter: "brightness(0) invert(0)" } } }}
													/>
												</IconButton>
											)} */}
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default HorizontalCollapsibleTokenList;
