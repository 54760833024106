import React, { useContext } from 'react';
import { SettingsContext } from '../../../context/settings-context';

import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from '@mui/material';
import { green, red } from '@mui/material/colors';
import FormatedPercentage from '../../Common/Formater/FormatedPercentage';
import FormatedNumber from '../../Common/Formater/FormatedNumber';

function TableProperties({ defaultPropertyType = 0 }) {
  const { realTokens } = useContext(SettingsContext);

  if (!realTokens) return <></>;
  let tokens = [
    ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
  ];
  if (defaultPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === defaultPropertyType);

  const totalRentedUnits = parseInt(tokens.reduce((acc, data) => acc + (data.rentedUnits || 0), 0));
  const totalTotalUnits = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits || 0), 0));
  const totalTotalProperties = parseInt(tokens.reduce((acc, data) => acc + (data.totalUnits > 0 ? 1 : 0 || 0), 0));

  // console.log("totalGrossRentYear", totalGrossRentYear);

  return (
    <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Properties
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 300,
              background: 'background.paper',
              '& thead th': { borderWidth: 0 },
              '& tbody tr:last-child td': { borderBottom: 0 },
            }}
            aria-label='sticky table'
          >
            <TableBody sx={{ mt: 0 }}>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span'>
                    Total Properties
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedNumber value={totalTotalProperties} variant={'body1'} digit={0} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span'>
                    Total Units
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedNumber value={totalTotalUnits} variant={'body1'} digit={0} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span'>
                    Rented Units
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedNumber value={totalRentedUnits} variant={'body1'} digit={0} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span' style={{ fontWeight: 'bold' }} sx={{ color: 'primary.main' }}>
                    RealT Rented Ratio
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedPercentage
                    value={totalRentedUnits / totalTotalUnits}
                    variant={'body1'}
                    color={
                      totalRentedUnits / totalTotalUnits > 0.9
                        ? green[500]
                        : totalRentedUnits / totalTotalUnits > 0.8
                          ? 'primary.main'
                          : red[700]
                    }
                    fontWeight={'bold'}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default TableProperties;
