// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // Intégration avec React
  .init({
    resources: {
      en: {
        translation: {
          // Vos traductions en anglais ici
          welcome: 'Welcome to our website',
          // Ajoutez d'autres traductions ici
        },
      },
      fr: {
        translation: {
          // Vos traductions en français ici (si vous en avez besoin)
        },
      },
    },
    lng: 'en', // Définir l'anglais comme langue par défaut
    fallbackLng: 'en', // Utiliser l'anglais si la traduction n'existe pas
    interpolation: {
      escapeValue: false, // React se charge déjà de la protection contre XSS
    },
    detection: {
      // Configuration pour détecter et utiliser la langue définie par cookie ou localStorage
      order: ['cookie', 'localStorage'],
      caches: ['cookie', 'localStorage'],
    },
  });

export default i18n;
