import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../context/settings-context';

import TokensTableHead from './TokensTableHead';

import { Box, Typography, Paper } from '@mui/material';
import { green, red } from '@mui/material/colors';

function TableTokens() {
  const { realTokens, holderWallets, balanceWallets, DEFAULT_PROPERTY_TYPE, settingsDashboard, setSettingsDashboard } =
    useContext(SettingsContext);
  const [tokens, setTokens] = useState(null);
  const [compagnies, setCompagnies] = useState(null);
  const [ethChain, setEthChain] = useState(true);
  const [xdaiChain, setXdaiChain] = useState(true);
  const [rmmChain, setRmmChain] = useState(true);
  const [rmmv3Chain, setRmmv3Chain] = useState(true);
  const [poolChain, setPoolChain] = useState(true);
  const [update7, setUpdate7] = useState(false);
  const [update30, setUpdate30] = useState(false);
  const selectedRentType = 'Year';

  useEffect(() => {
    const API_PITSBI_URL = 'https://api.pitsbi.io/api/';

    const getRealTokensCompagnies = async () => {
      try {
        const response = await fetch(API_PITSBI_URL + 'realTokensCompagnies');
        if (response.ok) {
          const body = await response.json();
          setCompagnies(body); // Assuming body is an array of companies
        } else {
          console.error('Failed get_realTokensCompagnies:', response.status, response.statusText);
        }
      } catch (error) {
        console.log('get_realTokensCompagnies', '- fetch error :', error);
      }
    };

    getRealTokensCompagnies();
  }, []);

  useEffect(() => {
    if (realTokens) {
      const realtokensList = JSON.parse(JSON.stringify(realTokens.list));
      if (realtokensList) {
        let tokens = [
          ...realtokensList.filter(
            (data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'
          ),
        ];

        if (settingsDashboard.selectedPropertyType > 0)
          tokens = tokens.filter((data) => data.propertyType === settingsDashboard.selectedPropertyType);
        // if (selectedFullName) tokens = tokens.filter((data) => data.fullName.toLowerCase().includes(selectedFullName.toLowerCase()));

        // Add "verified" field based on compagnies.realtokens_id
        if (compagnies) {
          tokens = tokens.map((token) => ({
            ...token,
            verified: compagnies.some((company) => company.realtokens_id === token.id),
          }));
        }

        setTokens(tokens);
        // console.log("tokens:", tokens);
      }
    }
  }, [realTokens, settingsDashboard.selectedPropertyType, compagnies]);

  // if (!realTokens) return <></>;
  if (!tokens) return <></>;
  if (!compagnies) return <></>;
  // let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 && data.productType!=="equity_token")];
  // let tokens = [...realTokens.list.filter((data) => !data.shortName.startsWith("OLD") && data.totalTokens > 0 )];
  // if (settingsDashboard.selectedPropertyType > 0) tokens = tokens.filter((data) => data.propertyType === settingsDashboard.selectedPropertyType);

  // console.log("TableTokens",tokens);

  return (
    <TokensTableHead
      tokens={tokens}
      chains={{
        ethChain: ethChain,
        setEthChain: setEthChain,
        xdaiChain: xdaiChain,
        setXdaiChain: setXdaiChain,
        rmmChain: rmmChain,
        setRmmChain: setRmmChain,
        rmmv3Chain: rmmv3Chain,
        setRmmv3Chain: setRmmv3Chain,
        poolChain: poolChain,
        setPoolChain: setPoolChain,
        update7: update7,
        setUpdate7: setUpdate7,
        update30: update30,
        setUpdate30: setUpdate30,
      }}
    />
  );
}

export default TableTokens;
