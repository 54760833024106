import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../context/settings-context';
import { Box, Typography, Paper, TableCell, TableContainer, Table, TableBody, TableRow } from '@mui/material';
import FormatedPrice from '../../Common/Formater/FormatedPrice';
import FormatedPercentage from '../../Common/Formater/FormatedPercentage';
// import { useTheme } from "@mui/system";

function TableInvestment({ defaultPropertyType = 0 }) {
  const { realTokens } = useContext(SettingsContext);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [grossRentYear, setGrossRentYear] = useState(0);
  const [netRentYear, setNetRentYear] = useState(0);

  useEffect(() => {
    if (!realTokens) return <></>;

    let tokens = [
      ...realTokens.list.filter((data) => !data.shortName.startsWith('OLD') && data.totalTokens > 0 && data.productType !== 'equity_token'),
    ];
    if (defaultPropertyType > 0) tokens = tokens.filter((token) => token.propertyType === defaultPropertyType);

    const totaltotalInvestment = parseInt(tokens.reduce((acc, data) => acc + (data.historic.init_price * data.totalTokens || 0), 0));
    const totalGrossRentYear = parseInt(tokens.reduce((acc, data) => acc + (data.grossRentYear || 0), 0));
    const totalNetRentYear = parseInt(tokens.reduce((acc, data) => acc + (data.netRentYear || 0), 0));

    setTotalInvestment(totaltotalInvestment);
    setGrossRentYear(totalGrossRentYear);
    setNetRentYear(totalNetRentYear);
  }, [realTokens, defaultPropertyType]);

  // console.log("totalGrossRentYear", totalGrossRentYear);

  return (
    <Paper elevation={3} sx={{ m: 1, minWidth: 250, border: 1, borderColor: 'primary.main', borderRadius: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', m: 0 }}>
        <Typography variant='h5' sx={{ m: 1, ml: 0, color: 'primary.main' }}>
          Investment
        </Typography>
      </Box>
      <Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 300,
              background: 'background.paper',
              '& thead th': { borderWidth: 0 },
              '& tbody tr:last-child td': { borderBottom: 0 },
            }}
            aria-label='sticky table'
          >
            <TableBody sx={{ mt: 0 }}>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span'>
                    Investments
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedPrice value={totalInvestment} variant={'body1'} digit={0} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span'>
                    Gross Rent/Year
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedPrice value={grossRentYear} variant={'body1'} digit={0} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span'>
                    Net Rent/Year
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedPrice value={netRentYear} variant={'body1'} digit={0} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='left' sx={{ px: 2, py: 1.5 }}>
                  <Typography variant='body1' component='span' style={{ fontWeight: 'bold' }} sx={{ color: 'primary.main' }}>
                    RealT Yield
                  </Typography>
                </TableCell>
                <TableCell align='right' sx={{ px: 2, py: 1.5 }}>
                  <FormatedPercentage value={netRentYear / totalInvestment} variant={'body1'} color={'primary.main'} fontWeight={'bold'} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}

export default TableInvestment;
