import React, { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../context/settings-context';
// import { SnackbarContext } from "../context/customsnackbar-context";
// import { useNavigate, useLocation } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import Header from '../components/Header/Header';
import HolderDashboard from '../components/Dashboard/HolderDashboard';
import LoadingWebSite from '../components/Common/LoadingWebSite';

const TITLE = "Pit's BI - Dashboard";

function Dashboard() {
  const { settingsLoaded } = useContext(SettingsContext);
  // const { openSnackbar } = useContext(SnackbarContext);
  const [walletValue, setWalletValue] = useState(null);
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    document.title = TITLE;
  }, []);

  useEffect(() => {
    // Lire le tabValue depuis l'URL et le définir
    const params = new URLSearchParams(location.search);
    const walletFromUrl = params.get('wallet');
    if (walletFromUrl !== null) {
      setWalletValue(walletFromUrl);
    }
  }, [location.search]);

  // const { openSnackbar } = useContext(SnackbarContext);
  return (
    <Box>
      <Header />

      {/* <button onClick={() => openSnackbar("Message de test", "success")}>Ouvrir Snackbar</button> */}

      {!settingsLoaded && <LoadingWebSite />}
      {settingsLoaded && (
        <Box>
          <Box sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {!walletValue && <HolderDashboard />}
            {walletValue && <Box>Follow walletValue = {walletValue}</Box>}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Dashboard;
