import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Checkbox, TextField } from '@mui/material';

const RadioSettingRentStartMarket = ({ levelSettings, nameSettings, settings, setSettings }) => {
  const showSetting = `show${nameSettings}`;
  const autoSetting = `auto${nameSettings}`;
  const dateSupSetting = `dateSup${nameSettings}`;
  const dateInfSetting = `dateInf${nameSettings}`;
  const isSetting = `is${nameSettings}`;
  const isnotSetting = `isnot${nameSettings}`;
  const isSettingMenu = `is${nameSettings}Menu`;

  // Initialisation des valeurs locales si elles n'existent pas
  useEffect(() => {
    // Vérifie si `levelSettings` existe
    if (!settings[levelSettings]) {
      console.log('useEffect: Initialisation de levelSettings');
      setSettings((prev) => ({
        ...prev,
        [levelSettings]: {
          [showSetting]: true,
          [isSetting]: true,
          [isnotSetting]: false,
          [isSetting]: false,
          [isSettingMenu]: false,
        },
      }));
      return;
    }

    // Met à jour les paramètres manquants
    const updatedSettings = { ...settings[levelSettings] };
    let needsUpdate = false;

    if (!settings[levelSettings].hasOwnProperty(showSetting)) {
      updatedSettings[showSetting] = true;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(isSetting)) {
      updatedSettings[isSetting] = false;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(isnotSetting)) {
      updatedSettings[isnotSetting] = false;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(isSettingMenu)) {
      updatedSettings[isSettingMenu] = true;
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(dateInfSetting)) {
      updatedSettings[dateInfSetting] = new Date();
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(dateSupSetting)) {
      updatedSettings[dateSupSetting] = new Date();
      needsUpdate = true;
    }

    if (!settings[levelSettings].hasOwnProperty(autoSetting)) {
      updatedSettings[autoSetting] = true;
      needsUpdate = true;
    }

    // Met à jour les paramètres si nécessaire
    if (needsUpdate) {
      console.log('Mise à jour des paramètres manquants :', nameSettings);
      setSettings((prev) => ({
        ...prev,
        [levelSettings]: updatedSettings,
      }));
    } else {
      // Synchronise les valeurs locales
    }
  }, [settings, levelSettings, dateInfSetting, dateSupSetting]);

  if (!settings[levelSettings]) return;

  return (
    <>
      {/* {settings[levelSettings][isSettingMenu] && ( */}

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'right', width: 1, border: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'top', justifyContent: 'start', width: 149, border: 0 }}>
          <Box sx={{ mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', width: 1, border: 0 }}>
            <FilterAltIcon
              sx={{ ml: 0, fontSize: 30 }}
              color={
                settings[levelSettings][isSetting] || settings[levelSettings][isnotSetting]
                  ? settings[levelSettings][autoSetting]
                    ? 'secondary'
                    : 'warning'
                  : 'disabled'
              }
              onClick={() => {
                setSettings((prevSettings) => {
                  const updatedSettings = { ...prevSettings };
                  if (settings[levelSettings][isSetting] || settings[levelSettings][isnotSetting])
                    updatedSettings[levelSettings] = {
                      ...updatedSettings[levelSettings],
                      [isSetting]: false,
                      [isnotSetting]: false,
                    };
                  else
                    updatedSettings[levelSettings] = {
                      ...updatedSettings[levelSettings],
                      [isSetting]: true,
                      [isnotSetting]: false,
                    };
                  return updatedSettings;
                });
              }}
            />
            <Box sx={{ width: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                sx={{ m: 0, p: 0.25, width: 0.8, fontWeight: 'bold', textTransform: 'none' }}
                variant={settings[levelSettings][autoSetting] ? 'contained' : 'outlined'}
                color={settings[levelSettings][autoSetting] ? 'success' : 'secondary'}
                onClick={(e) => {
                  if (!settings[levelSettings][autoSetting])
                    setSettings((prevSettings) => {
                      const updatedSettings = { ...prevSettings };
                      updatedSettings[levelSettings] = {
                        ...updatedSettings[levelSettings],
                        [autoSetting]: true,
                        [dateInfSetting]: new Date(),
                        [dateSupSetting]: new Date(),
                      };
                      return updatedSettings;
                    });
                  else
                    setSettings((prevSettings) => {
                      const updatedSettings = { ...prevSettings };
                      updatedSettings[levelSettings] = {
                        ...updatedSettings[levelSettings],
                        [autoSetting]: false,
                      };
                      return updatedSettings;
                    });
                }}
              >
                {settings[levelSettings][autoSetting] ? 'Auto' : 'Manu'}
              </Button>
            </Box>
            <TuneOutlinedIcon
              sx={{ ml: 'auto', fontSize: 30 }}
              color={settings[levelSettings][isSettingMenu] ? 'secondary' : 'disabled'}
              onClick={() => {
                setSettings((prevSettings) => {
                  const updatedSettings = { ...prevSettings };
                  updatedSettings[levelSettings] = {
                    ...updatedSettings[levelSettings],
                    [isSettingMenu]: !updatedSettings[levelSettings][isSettingMenu],
                  };
                  return updatedSettings;
                });
              }}
            />
          </Box>
          <Box sx={{ width: 1 }}>
            {settings[levelSettings][isSettingMenu] && (
              <>
                {/* Champ de saisie pour la valeur isSetting */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
                  <Checkbox
                    sx={{ width: 24, height: 24 }}
                    color='secondary'
                    checked={settings[levelSettings][isSetting] ? settings[levelSettings][isSetting] : false}
                    onChange={() => {
                      setSettings((prevSettings) => {
                        const updatedSettings = { ...prevSettings };
                        updatedSettings[levelSettings] = {
                          ...updatedSettings[levelSettings],
                          [isSetting]: !updatedSettings[levelSettings][isSetting],
                        };
                        return updatedSettings;
                      });
                    }}
                  />
                  <ArrowBackIosIcon sx={{ ml: 1, fontSize: 20 }} />
                  <TextField
                    type='date'
                    disabled={settings[levelSettings][autoSetting] ? true : false}
                    value={
                      settings[levelSettings][dateInfSetting]
                        ? new Date(settings[levelSettings][dateInfSetting]).toISOString().slice(0, 10)
                        : ''
                    }
                    onChange={(e) => {
                      setSettings((prevSettings) => {
                        const updatedSettings = { ...prevSettings };
                        console.log('dateInfSetting', e.target.value + 'T06:00:00.0000');
                        updatedSettings[levelSettings] = {
                          ...updatedSettings[levelSettings],
                          [dateInfSetting]: e.target.value + 'T06:00:00.0000',
                        };
                        return updatedSettings;
                      });
                    }}
                    variant='standard'
                    size='small'
                    sx={{ mx: 0, width: '95px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      sx: { fontSize: 12 },
                      onKeyDown: (e) => {
                        e.preventDefault();
                      },
                    }}
                  />
                </Box>

                {/* Champ de saisie pour la valeur isnotSetting */}
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }}>
                  <Checkbox
                    sx={{
                      width: 24,
                      height: 24,
                    }}
                    color='secondary'
                    checked={settings[levelSettings][isnotSetting] ? settings[levelSettings][isnotSetting] : false}
                    onChange={() => {
                      setSettings((prevSettings) => {
                        const updatedSettings = { ...prevSettings };
                        updatedSettings[levelSettings] = {
                          ...updatedSettings[levelSettings],
                          [isnotSetting]: !updatedSettings[levelSettings][isnotSetting],
                        };
                        return updatedSettings;
                      });
                    }}
                  />
                  <ArrowForwardIosIcon sx={{ ml: 1, fontSize: 20 }} />
                  <TextField
                    type='date'
                    disabled={settings[levelSettings][autoSetting] ? true : false}
                    value={
                      settings[levelSettings][dateSupSetting]
                        ? new Date(settings[levelSettings][dateSupSetting]).toISOString().slice(0, 10)
                        : ''
                    }
                    onChange={(e) => {
                      setSettings((prevSettings) => {
                        const updatedSettings = { ...prevSettings };
                        console.log('dateSupSetting', e.target.value + 'T06:00:00.0000');
                        updatedSettings[levelSettings] = {
                          ...updatedSettings[levelSettings],
                          [dateSupSetting]: e.target.value + 'T06:00:00.0000',
                        };
                        return updatedSettings;
                      });
                    }}
                    variant='standard'
                    size='small'
                    sx={{ mx: 0, width: '95px' }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      sx: { fontSize: 12 },
                      onKeyDown: (e) => {
                        e.preventDefault();
                      },
                    }}
                  />
                </Box>

                <Box sx={{ mt: 1, width: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    sx={{ m: 0, p: 0.35, width: 0.8, fontWeight: 'bold', textTransform: 'none' }}
                    variant='outlined'
                    color={'secondary'}
                    onClick={(e) => {
                      setSettings((prevSettings) => {
                        const updatedSettings = { ...prevSettings };
                        updatedSettings[levelSettings] = {
                          ...updatedSettings[levelSettings],
                          [dateInfSetting]: new Date(),
                          [dateSupSetting]: new Date(),
                        };
                        return updatedSettings;
                      });
                    }}
                  >
                    Reset Date
                  </Button>
                </Box>

                {/* <Typography variant='body2' sx={{ ml: 1 }}>
                  {new Date().toISOString().slice(0, 10)}
                </Typography> */}
              </>
            )}
          </Box>
        </Box>
      </Box>
      {/* )} */}
    </>
  );
};

RadioSettingRentStartMarket.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  levelSettings: PropTypes.string.isRequired, // Niveau intermédiaire dynamique, ex: "PurchaseOffers"
  nameSettings: PropTypes.string.isRequired, // Nom des paramètres imbriqués, ex: "Position"
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default RadioSettingRentStartMarket;
