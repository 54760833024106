import React from 'react';
import { Typography } from '@mui/material';

const FormatedNumber = ({ value, color = 'inherit', variant = 'body2', fontWeight = 'normal', digit = 2 }) => {
  // const userLocale = navigator.language; // const userLocales = navigator.languages;
  // console.log("userLocale", userLocale); // console.log(userLocales[0]);

  const formatedNumber =
    value !== null
      ? !isNaN(value)
        ? value.toLocaleString(undefined, { minimumFractionDigits: digit, maximumFractionDigits: digit })
        : 'NaN'
      : 'N/A';

  return (
    <Typography variant={variant} sx={{ color: color }} style={{ fontWeight: fontWeight }}>
      {value > 0 ? formatedNumber : '-'}
    </Typography>
  );
};

export default FormatedNumber;
