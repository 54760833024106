import React, { useContext, useEffect, useRef, useState } from 'react';
import { MyThemeContext } from '../../context/theme-context';

import { green, red, grey } from '@mui/material/colors';

import { TableCell, Avatar, TableRow, Box, Typography, useMediaQuery } from '@mui/material';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';

import FormatedNumberDigit from '../Common/Formater/FormatedNumberDigit';

function CountryRow({ country, order, handleSelectCountry }) {
  const { themeContext } = useContext(MyThemeContext);

  const isMatch = useMediaQuery(themeContext.breakpoints.down('lg'));

  if (!country) return <></>;
  if (!country.code) return <></>;

  // console.log('country',country)
  const imgSrc = 'https://flagcdn.com/w20/' + country.code.toLowerCase() + '.png';

  return (
    <>
      <TableRow key={country.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <FormatedNumberDigit value={order} color={'primary.main'} digit={0} />
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Avatar
            alt='Logo'
            src={imgSrc}
            sx={{ my: 0.37, ml: 1, width: 38, height: 20, border: 2, borderColor: grey[500], borderRadius: 0 }}
          />
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Typography variant='body2'>{country.name}</Typography>
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Typography variant='body2'>{country.code}</Typography>
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Box sx={{ m: 0, ml: 'auto', p: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='body2'>{country.nb_cities}</Typography>
          </Box>
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Typography variant='body2'>
            <strong>{country.nb_clients}</strong>{' '}
            {country.pitsbi + country.pitswap - country.nb_clients > 0 ? `(${country.pitsbi + country.pitswap - country.nb_clients})` : ''}
          </Typography>
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Typography variant='body2'>
            <strong>{country.pitsbi}</strong> ({((100 * country.pitsbi) / (country.pitsbi + country.pitswap)).toFixed(2)}
            {'%)'}
          </Typography>
        </TableCell>
        <TableCell align='center' sx={{ pl: 0.5, pr: 0.5, py: 0.5 }}>
          <Typography variant='body2'>
            <strong>{country.pitswap}</strong> ({((100 * country.pitswap) / (country.pitsbi + country.pitswap)).toFixed(2)}
            {'%)'}
          </Typography>
        </TableCell>
        <TableCell
          align='center'
          sx={{ pl: 0.5, pr: 0.5, py: 0.5, '&:hover': { cursor: 'pointer' } }}
          onClick={() => handleSelectCountry(country)}
        >
          <Box sx={{ m: 0, ml: 'auto', p: 0, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <AddCircleTwoToneIcon sx={{ m: 0, p: 0 }} style={{ width: 20, height: 20 }} />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CountryRow;
